import React, { useEffect,  useRef, useState, useMemo } from 'react';
import './dynamic-input.scss';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import HardDelete from '../hard-delete/hard-delete';
import AuditTableInput from '../audit-table/audit-table'
import SecureComponent from '../security/secure.component';
import SecurityService from '../security/security.service';
import IrtizDynamicInputAdd from './dynamic-input-add';
import TemplateCode from '../template-code/template-code';
import { useDispatch, useSelector } from 'react-redux';
import { gridColumnsList } from '../../store/slices/list-tempate';
import moment from 'moment';
import accessoryBOMService from './dynamic-input.service';

import  imgSrc from './spinner.gif';
// indexedDB.js
import { openDB } from 'idb';

// Open (or create) the database
// const dbPromise = openDB('my-database', 1, {
//   upgrade(db) {
//     // Create a store for process descriptions
//     db.createObjectStore('Brand', { keyPath: 'BrandID' });
//   },
// });

// // Retrieve all data from IndexedDB
//  const getFromDB = async (id) => {
//   const db = await dbPromise;
//   return db.getAll(id);
// };

// // Save an array of objects to IndexedDB


// const saveToDB = async (data) => {
//   const db = await dbPromise;
//   console.log(data)
//   return db.put('Brand', {data });
// };

// const dbPromise = openDB('my-database', 5, {
//   upgrade(db) {
//     // Create a store for process descriptions
//     if (!db.objectStoreNames.contains('Brand')) {
//       db.createObjectStore('Brand', { autoIncrement: true });
//     }
//     if (!db.objectStoreNames.contains('InvoicePurchaseDetail')) {
//       db.createObjectStore('InvoicePurchaseDetail', { autoIncrement: true });
//     }
//     if (!db.objectStoreNames.contains('InvoiceSaleDetail')) {
//       db.createObjectStore('InvoiceSaleDetail', { autoIncrement: true });
//     }
//     if (!db.objectStoreNames.contains('InvoicePurchaseSummary')) {
//       db.createObjectStore('InvoicePurchaseSummary', { autoIncrement: true });
//     }
//     if (!db.objectStoreNames.contains('InvoiceSaleSummary')) {
//       db.createObjectStore('InvoiceSaleSummary', { autoIncrement: true });
//     }
   
//   },
// });

// // Retrieve all data from IndexedDB
// const getFromDB = async (storeName) => {
//   const db = await dbPromise;
//   const tx = db.transaction(storeName, 'readonly');
//   const store = tx.objectStore(storeName);
//   return store.getAll();
// };

// const saveToDB = async (id, data, batchSize = 100) => {
//   const db = await dbPromise;
  
//   for (let i = 0; i < data.length; i += batchSize) {
//     const batch = data.slice(i, i + batchSize);
//     const tx = db.transaction(id, 'readwrite');
//     const store = tx.objectStore(id);

//     for (const item of batch) {
//       await store.put(item);
//     }

//     await tx.done;
//   }

//   console.log('Data saved successfully in batches!');
// };

// Save an array of objects to IndexedDB
// const saveToDB = async (id , data) => {
//   const db = await dbPromise;
//   const tx = db.transaction(id, 'readwrite');
//   const store = tx.objectStore(id);
//   console.log(id)
//   console.log(data)
//   // Iterate over the array and save each item individually
//   // for (const item of data) {
//   //   console.log(item)
//   //   await store.put(item);
//   // }
//   data.forEach(async (item) => {
//     console.log(item);
//     await store.put(item);
//   });

//   // await store.put({ id: id, data: JSON.stringify(data) });

//   await tx.done; // Ensure the transaction is complete before returning
//   console.log('Data saved successfully!');
// };
// const saveToDB = async (data) => {
//   const db = await dbPromise;
//   const tx = db.transaction('processDesc', 'readwrite');
//   const store = tx.objectStore('processDesc');
//   console.log(db)
//   console.log(tx)
//   console.log(store)
//   // Clear existing data
//   await store.clear()
//   store.put([
//     {BrandID:1,BrandName:'IRTIZA'},
//     {BrandID:2,BrandName:'IRTIZA JASS'}
//   ])
  
  // Put each item in the store
  // for (const item of data) {
  //   await store.put(item);
  // }

//   await tx.done;
// };

// Clear data from IndexedDB
//  const clearDB = async () => {
//   const db = await dbPromise;
//   return db.clear('processDesc');
// };


let selectedRows = []
let dataListData =[]

const STEPS = 101; // Number of records to load in each step
let CHUNK_SIZE = 0; // Number of records to load in each step
const INTERVAL = 2000; 
export default function AccessoryBOMList(
  {
  Id='Dynamic',
  ListTitle = '',
  ColumnsList = [],
  GridDataSource = [],
  DefaultSortedColumnName='',
  GridDialog = [],
  ListActionComplete,
  
  // OPTIONAL
  ListDelete=[],
  DeleteType='Hard',
  AllowTemplate=true,
  ToolbarOption= 'All',
  ToolbarClicking,
  AllowGrouping=true,
  AllowExcelExport=true,
  AllowPdfExport=true,
  ShowColumnChooser=true,
  AllowPaging=true,
  AllowDataBound=false,
  ListSecurity='',
  AddSecurity='',  
  EditSecurity='',
  DeleteSecurity='',
  DefaultSortedColumnSort='Descending',
  FilterColumns=[],
  GroupColumns=[],
  GridEditSettingHeaderTemplate='<p></p>',
  GridEditSettingMode='Dialog',
  GridSumColumns=[],
  AllowGridSum=false,
  IsDialog=false


 }) {
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
  function addUnderscore(input) {
    return input.replace(/([a-z])([A-Z])/g, '$1_$2');
}
  // InvoiceEvaluationID	
  // InvoiceNo	
  // MainInvoiceID	
  // CurrencyName	
  // CurrencyID	
  // ExchangeRate	
  // HawalaCommissionPercent	
  // CurrencyNameHawala	
  // CurrencyIDHawala	
  // AccountTitleHawalaDr	
  // AccountIDHawalaDr	
  // AccountTitleHawalaCr	
  // AccountIDHawalaCr
//   ColumnsList.forEach(column => {
//     if (column.field.endsWith('Date')) {
//         column.other.push({ 'format': formatOptions });
//     }
// });
//
// if(ColumnsList.length > 0){
  
  
//   ColumnsList = [...ColumnsList];
  
//   let dateIndex = ColumnsList.findIndex(res => res.field.endsWith('Date'));
//   if (dateIndex !== -1) {
//     
//     
//     ColumnsList[dateIndex].other = [ { 'format': formatOptions }]
//     }
//     }
//   
//   var ColumnProps =  ColumnsList

// let Columns = [...ColumnsList];


//       // 
//       var ColumnProps =  ColumnsList
      // if (column.field.endsWith('Date')) {
      //     column.other.push({ 'format': formatOptions });
      // }
    

  
  // InputL ist Security Name
//   let InputListSecurity = props?.location?.pathname.split('/')[3] == 'Summary'  ? 'AccessoryBOMList' : 'AccessoryBOMDetailList';
// let defaultSortColumnName = props?.location?.pathname.split('/')[3] == 'Summary'  ? 'AccessoryBOMID' : 'AccessoryBOMDetailID'
// let defaultSortColumnType =  'Descending'
// let InputNameForPdfExcelExport = props?.location?.pathname.split('/')[3] == 'Summary'  ? 'Accessory BOM - LIST' : 'Accessory BOM DETAIL - LIST'
// let exportFileName = props?.location?.pathname.split('/')[3] == 'Summary'  ? 'accessory_bom' : 'accessory_bom_detail'
let InputListSecurity = ListSecurity == '' ? `${Id}List`:ListSecurity;

let defaultSortColumnName = DefaultSortedColumnName
let defaultSortColumnType =  DefaultSortedColumnSort
let InputNameForPdfExcelExport = ListTitle
let exportFileName = addUnderscore(Id)

 //  DYNAMIC CODE 
 const dispatch = useDispatch()
 const reduxData = useSelector(state =>{return state.listTemplate})

 let irtizaDynamicGrid: Grid;
 var filterColumnName = []
 var recordColumnName = []
 var fiterCheckedValue = []
  const [dataSourceState, setDataSourceState] = useState([]);
  const [loadingdataSourceState, setLoadingDataSourceState] = useState(false);

  const [filterState, setFilterState] = useState([]);
  
  const [groupState, setGroupState] = useState([]);
  
  const [deletingProps, setDeletingProps] = useState();
  const [showDialogs, setShowDialogs] = useState(false);

  const [selectedRow, setSelectedRow] = useState([]);

  const [columnData, setColumnData] = useState([]);

  // var ColumnProps =   []
  useEffect(() => {
    // irtizaDynamicGrid?.dataBound(irtizaDynamicGrid?.autoFitColumns()) ;
    const transformedFilters = FilterColumns.map(col => ({
      field: col.field,
      operator: col.operator ? col.operator : 'equal',
      value: col.value.toString() // Ensure the value is a string
  }));
  
  setFilterState(transformedFilters);

    const updatedColumns = ColumnsList.map(column => {
      if (column.field.endsWith('Date')) {
          return {
              ...column,
              other: [{...column.other[0],  'format': formatOptions }]
          };
      }else if(column.field.startsWith('Is') && column.field != 'IsDeleted' && column.field != 'IsApproved') {
        return {
          ...column,
          other: [{...column.other[0],  template:(props) => {
            return (
            <p style={{margin: '0px'}}>{props.IsApprovedDetail == true ? 'Yes' : 'No'}</p>
            )
            }   }]
      };
      }
      return column;
  });
// var ColumnProps = updatedColumns

setColumnData(updatedColumns)

   if(IsDialog == false){   dispatch(gridColumnsList(updatedColumns))}
    setGroupState(GroupColumns)
    getListService();
    setTimeout(() => {
      
    }, 1000);

    const sidebar = document.querySelector('.content-wrapper');

    sidebar.style.opacity = '0';

    // After 2 seconds, change the opacity to 1
    setTimeout(() => {
        sidebar.style.opacity = '1';
        sidebar.style.transition = 'opacity 0.3s linear';
    }, 600);
    
  }, []);
// DYNAMIC TEMPLATE CODE
const [isToggled, setIsToggled] = useState(false);
const [ToggledValue, setToggledValue] = useState( 'Hide Template');
useEffect(() => {
  setTimeout(() => {
    setToggledValue(isToggled == true? 'Hide Template' : 'Show Template')
  }, 100);
}, [isToggled]);

    // let dialogInstance: DialogComponent;
    // const toolbar: ToolbarItems[] = ['PDFExport'];
  const toolbarClick = (args: ClickEventArgs) => {
    console.log(args)
    let reportName = document.getElementById('reportName')?.value;
    let getOrientation = document.getElementsByClassName('orientation')
    let selectedOrientation = getOrientation[0]?.checked ==  true ? 'Portrait':'Landscape'
    let getHeaderCheck = document.getElementsByClassName('headerCheck')[0]?.checked;
    let headerCheck =  !getHeaderCheck
    if ( irtizaDynamicGrid && args.item.properties.id === `${Id}Grid_PDFExport` ) {
      
      
      if(headerCheck == true){
        let ress = filterColumnName.map((res, index) => {
          // Convert camelCase to Title Case and uppercase all letters
          let title = res.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
          // Calculate position dynamically
          let position = { x: 10, y: 120 + (index * 40) };
          // Define style properties
          let style = { textBrushColor: "#000000", fontSize: 12, hAlign: 'Center' };
          // Join checked values with separator '|'
          let checkedValues = fiterCheckedValue[index].join(' | ');
          
          return {
              position: position,
              style: style,
              type: "Text",
              value: `${title}: ${checkedValues},`
          };
      });
      
  const exportProperties: PdfExportProperties = {
          header: { 
          contents: [ 
            // {
            //   position: { x: 10, y: 30 },
            //   size: { width: 120, height: 80 }, // Set the width and height of the image
            //   type: "Image",
            //   src:''
            // },
            { 
              position: { x:selectedOrientation == 'Landscape' ?420:260 , y: 50 }, 
              style: { textBrushColor: "#000000", fontSize: 20, hAlign:'Center' }, 
              type: "Text", 
              value: `DALAL INDUSTRIES`
              
            },
            { 
              position: { x:selectedOrientation == 'Landscape' ?410:270 , y: 80 }, 
              style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
              type: "Text", 
              value: reportName.toUpperCase() ? reportName.toUpperCase() : InputNameForPdfExcelExport
              },  
            ...ress
          
          ]
           ,
           fromTop: 0, 
            height: 230 
        },
         footer: {
                    contents: [
                        {
                            format: 'Page {$current} of {$total}',
                            pageNumberType: 'Northwind Traders',
                            position: { x: selectedOrientation == 'Landscape' ?930:600, y: 25 },
                            style: {
                                fontSize: 16,
                                textBrushColor: '#000000'
                              },
                              type: 'PageNumber'
                            },
                            { 
                              position: { x:selectedOrientation == 'Landscape' ?20:10 , y: 25 }, 
                              style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
                              type: "Text", 
                              value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}`
                            },
                            { 
                              position: { x:selectedOrientation == 'Landscape' ?420:320 , y: 25 }, 
                              style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
                              type: "Text", 
                              value: `Printed By: ${reduxData.printedByUserName}`
                              }
                          ],
                          fromBottom: 20,
                          height: 60
                },
        pageOrientation: selectedOrientation,
        fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.pdf`
      };
      
      setTimeout(() => {
        
        return irtizaDynamicGrid.pdfExport(exportProperties); 
      }, 500);
}else{
  const withOutHeaderexportProperties: PdfExportProperties = {
    pageOrientation: selectedOrientation,
    fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.pdf`
  };
  irtizaDynamicGrid.pdfExport(withOutHeaderexportProperties);
}
    
    }
    if (
      irtizaDynamicGrid &&
      args.item.properties.id === `${Id}Grid_excelexport`
    ) {
      if(headerCheck == true){
      let excelress =   filterColumnName.map((res,ind)=>{
        let title = res.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()
        // let len = fiterCheckedValue.length()
       return { cells: [{ colSpan: 6, value: `${ title} :  ${fiterCheckedValue[ind].join(',')}` , style: {  fontSize: 12,hAlign: 'Left', }} ]}
      
      })
      const exportProperties: ExcelExportProperties = {
        
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.xlsx`,
        footer: {
          footerRows: 2,
          rows: [
              { cells: [{ colSpan: 4, value: `Printed By: ${reduxData.printedByUserName}`, style: { hAlign: 'Center', bold: true } }] },
              
          ]
      },
      header: {
          headerRows: 4 + fiterCheckedValue.length,
          rows: [
              { cells: [{ colSpan: 2, value: "", style: {  fontSize: 20, hAlign: 'Center', bold: true, } }] },
              { cells: [{ colSpan: 4, value: "DALAL INDUSTRIES", style: {  fontSize: 25, hAlign: 'Center', bold: true, } }] },
              { cells: [{ colSpan: 4, value:reportName.toUpperCase() ? reportName.toUpperCase():'PRODUCT FINISH SIZE - LIST', style: { fontSize: 15, hAlign: 'Center', bold: true, } }] },
              { cells: [{ colSpan: 4, value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}` , style: {  fontSize: 12,hAlign: 'Right' } }] },
              ...excelress
          ]
      },

      };
      irtizaDynamicGrid.excelExport(exportProperties);
     }else{
      const exportProperties: PdfExportProperties = {
        pageOrientation: selectedOrientation,
        fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.xlsx`
      };
      irtizaDynamicGrid.excelExport(exportProperties);
    }
    }
    if (
      irtizaDynamicGrid &&
      args.item.properties.id === `${Id}Grid_columnchooser`
    ) {
    }
    if (irtizaDynamicGrid && args.item.properties.id === 'Delete' ) {
      
      if (irtizaDynamicGrid.selectedRowIndex === -1) {
        // no rows selected
        
        // setDialogWarningVisibility(false);
        let obj={
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)

      } else {
        ToolbarClicking(irtizaDynamicGrid, args ,irtizaDynamicGrid.getSelectedRecords()[0]  , getListService,setDataSourceState)

        if(ListDelete.length == 0){
          
          let GridObj = {...irtizaDynamicGrid.getSelectedRecords()[0]};
        for (let key in GridObj) {
          if (GridObj.hasOwnProperty(key)) {
            const dateKeys = ['ApprovedByDate', 'CreationDate', 'UpdationDate', 'DeletionDate'];
            const booleanKeys = ['IsDeleted', 'IsClosed', 'IsApproved'];
            if(key.endsWith('ID') || key.includes('ID') || booleanKeys.includes(key) || dateKeys.includes(key) ){
              
              delete GridObj[key]
            }else{
              if(key.includes('Date')){
                if(GridObj[key] instanceof Date){
                  GridObj[key] = moment(GridObj[key]).format('DD-MMM-YY')
                  
                  
                }
              }else if(key.includes('Rate')){
                GridObj[key] = parseFloat( GridObj[key]).toFixed(2)
              
              }else if(!isNaN( GridObj[key])){
                GridObj[key] = parseFloat( GridObj[key]).toFixed(1)
              }else{
                GridObj[key] = GridObj[key]
              }

            }
          }
          
        }
        
        // deleteDialog.current.hide();
        let obj={
          TableName:Id,
          ColumnName:DefaultSortedColumnName,
          // DeleteSecurity:ListDelete[0]?.DeleteSecurity ?  ListDelete[0].DeleteSecurity : undefined,
          RowID:irtizaDynamicGrid.getSelectedRecords()[0][DefaultSortedColumnName],
          SelectedRow:true,
          UserID:userId,
          SelectedRowData:GridObj
            }
            if(DeleteType == 'Soft'){
              obj.DeleteSecurity = `${Id}Delete`
              }
            
          setDeletingProps(obj)
        setShowDialogs(true)
        }else{
        
          ToolbarClicking(irtizaDynamicGrid, args ,irtizaDynamicGrid.getSelectedRecords()[0],getListService ,setDataSourceState)

        let GridObj = {...irtizaDynamicGrid.getSelectedRecords()[0]};
        for (let key in GridObj) {
          if (GridObj.hasOwnProperty(key)) {
            const dateKeys = ['ApprovedByDate', 'CreationDate', 'UpdationDate', 'DeletionDate'];
            const booleanKeys = ['IsDeleted', 'IsClosed', 'IsApproved'];
            if(key.endsWith('ID') || key.includes('ID') || booleanKeys.includes(key) || dateKeys.includes(key) ){
              
              delete GridObj[key]
            }else{
              if(key.includes('Date')){
                if(GridObj[key] instanceof Date){
                  GridObj[key] = moment(GridObj[key]).format('DD-MMM-YY')
                  
                  
                }
              }else if(key.includes('Rate')){
                GridObj[key] = parseFloat( GridObj[key]).toFixed(2)
              
              }else if(!isNaN( GridObj[key])){
                GridObj[key] = parseFloat( GridObj[key]).toFixed(1)
              }else{
                GridObj[key] = GridObj[key]
              }

            }
          }
          
        }
        
        // deleteDialog.current.hide();
        let obj={
          TableName:ListDelete[0].TableName,
          ColumnName:ListDelete[0].TableID,
          // DeleteSecurity:ListDelete[0]?.DeleteSecurity ?  ListDelete[0].DeleteSecurity : undefined,
          RowID:irtizaDynamicGrid.getSelectedRecords()[0][ListDelete[0].TableID],
          SelectedRow:true,
          UserID:userId,
          SelectedRowData:GridObj
            }
            if(ListDelete[0]?.TableSecurity){
              obj.DeleteSecurity = ListDelete[0]?.TableSecurity
              }
            
          setDeletingProps(obj)
        setShowDialogs(true)
      }
    } 
    } 
    if (irtizaDynamicGrid && args.item.properties.id) {
      console.log(args.item.properties)
      if(args.item?.mustSelectRow == true){
        if(irtizaDynamicGrid.selectedRowIndex === -1){
          // let key=exportContractGrid.getSelectedRecords()[0]?.ExportContractID;
          // handleRedirectPlanning(key)
          let obj={
    
          }
          setDeletingProps(obj)
              setShowDialogs(true)
    
      }else{
        ToolbarClicking(irtizaDynamicGrid, args,irtizaDynamicGrid.getSelectedRecords()[0]  , getListService,setDataSourceState)

      }
      }else{
        ToolbarClicking(irtizaDynamicGrid, args,irtizaDynamicGrid.getSelectedRecords()[0]  , getListService,setDataSourceState)
        
      }
      
  }
  };
  


  const [processDesc, setProcessDesc] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const allRecordsRef = useRef([]); // Use a ref to store allRecords
  const intervalIdRef = useRef(null); // Use a ref to store the interval ID

  const findValidStepValue = (totalRecords, maxSteps) => {
    for (let steps = 4; steps <= maxSteps; steps++) {
      const chunkSize = Math.floor(totalRecords / steps);
      if (totalRecords % steps === 0) {
        return { steps, chunkSize };
      }
    }
    return { steps: 1, chunkSize: totalRecords }; // Fallback if no valid step found
  };
  const loadMoreRecords = () => {
    const allRecords = allRecordsRef.current; // Access allRecords from the ref
    console.log('All Records:', allRecords);
    const endIndex = Math.min(currentIndex + CHUNK_SIZE, allRecords.length);
    
    setDataSourceState(
      // ...allRecords.slice(currentIndex, currentIndex + CHUNK_SIZE),
    allRecords
    );
    clearInterval(intervalIdRef.current)
    // setDataSourceState((prevRecords) => [
    //   ...prevRecords,
    //   // ...allRecords.slice(currentIndex, currentIndex + CHUNK_SIZE),
    //   ...allRecords.slice(currentIndex, endIndex),
    // ]);
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + CHUNK_SIZE;
      
      // Clear the interval once all records are loaded
      if (newIndex >= allRecords.length) {
        clearInterval(intervalIdRef.current);
      }
      
      return newIndex;
    });
  };


  const getListService = async () => {
  
    if (GridDataSource[0].DataSourceType === 'Function') {
      try {
        // const cachedData = await getFromDB(GridDataSource[0].DataSourceIndexName ? GridDataSource[0].DataSourceIndexName  : Id);
        // // const cachedData = await getFromDB('InvoicePurchaseDetail');
        // console.log(cachedData)
        // if (cachedData.length > 0 && cachedData ) {
        //   console.log('Fetched from IndexedDB:', cachedData);
        //   setDataSourceState(cachedData);
        //   // return; // Exit early if data is found in cache
        // }
        // setLoadingDataSourceState(true)
        // document.getElementById(`${Id}Grid`).style.opacity = '0 !important'
        const response = await GridDataSource[0].DataSource();
          console.log(response)


        const data = response.data.recordset.map((record) => {
          Object.keys(record).map((key) => {
            if (key.toLowerCase().endsWith('date') && record[key]) {
                console.log(record[key].split('T')[0])
                console.log(new Date(record[key].split('T')[0]))
                const [year, month, day] = record[key].split('T')[0].split('-');
record[key] = new Date(year, month - 1, day); // Month is 0-indexed
              console.log(record[key])
              // record[key] = new Date(record[key].split('T', 1)[0])
              //record[key] = record[key].split('T')[0]; // Extract?s "2024-11-23" as a plain string

            }else if(key.toLowerCase().startsWith('isdeleted') || key.toLowerCase().startsWith('isapproved') ){
              
              record[key] = record[key] == true ? 'Yes': 'No'
            }
          });
          return record
        });
        setLoadingDataSourceState(false)

        // allRecordsRef.current = data;
        // const { steps, chunkSize } = findValidStepValue(data.length, STEPS); // Max 20 steps
        // console.log(steps)
        // console.log(chunkSize)
        // CHUNK_SIZE = chunkSize
  
        // setChunkSize(calculatedChunkSize)
        setDataSourceState(data);
       
        console.log(GridDataSource[0].DataSourceIndexName )

        // setCurrentIndex(CHUNK_SIZE);
  
        // Start loading the remaining records in intervals
        // intervalIdRef.current = setInterval(() => {
        //   // loadMoreRecords();
        // }, data.length > 50000 ? 1000 : INTERVAL);
        // setDataSourceState(data);
        // setTimeout(() => {
        //   saveToDB(GridDataSource[0].DataSourceIndexName  ? GridDataSource[0].DataSourceIndexName  : Id ,
        //     data);
        // }, 2000);
        setTimeout(() => {
          // document.getElementById(`${Id}Grid`).style.transition = '0.4'
          // document.getElementById(`${Id}Grid`).style.opacity = '1 !important'
        }, 2000);
      } catch (e) {
        
      }
    } else {
      setTimeout(() => {
            
        setLoadingDataSourceState(false)
      }, 500);
      setDataSourceState([]);
      // accessoryBOMService.getAccessoryBOMForList()
      //   .then((response) => {
          
      //     setDataSourceState(response.data.recordset);
      //   })
      //   .catch((e) => {
          
      //   });
    }
  };
  

  // useEffect(() => {
  // }, []);

  const getRoute = () =>
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');

      let toolbarOptions = ToolbarOption;  // Assign directly from the input
      
// Check if toolbarOptions is a string and equals 'All'
if (typeof toolbarOptions === 'string' && toolbarOptions === 'All') {
  // toolbarOptions = ['Add', 'Edit', { text: "Delete", tooltipText: "Delete", id: "Delete", disabled: !SecurityService.canUserAccess('MadeupTransferTransactionsDelete') }, 'Update', 'Cancel', 'PDFExport', 'ExcelExport', 'Search', 'ColumnChooser'];
  toolbarOptions = ['Add', 'Edit', { text: "Delete", tooltipText: "Delete", id: "Delete", disabled: !SecurityService.canUserAccess(DeleteSecurity == '' ? `${Id}Delete`:DeleteSecurity) }, 'Update', 'Cancel', 'PDFExport', 'ExcelExport', 'Search', 'ColumnChooser'];
} else if (Array.isArray(toolbarOptions) && toolbarOptions.length > 0) {
  // Ensure toolbarOptions is an array and run the existing condition
  toolbarOptions = [...toolbarOptions];
  let deleteIndex = toolbarOptions.findIndex(res => res === 'Delete');
  if (deleteIndex !== -1) {
    // toolbarOptions[deleteIndex] = { text: "Delete", tooltipText: "Delete", id: "Delete", disabled: !SecurityService.canUserAccess('MadeupTransferTransactionsDelete') };
    toolbarOptions[deleteIndex] = { text: "Delete", tooltipText: "Delete", id: "Delete", disabled: !SecurityService.canUserAccess(DeleteSecurity == '' ? `${Id}Delete`:DeleteSecurity) };
  }
} else if (Array.isArray(toolbarOptions) && toolbarOptions.length === 0) {
  toolbarOptions = null;
}
     // Check if AllowPdfExport is false and remove PDFExport from toolbarOptions if present
if (!AllowPdfExport && Array.isArray(toolbarOptions) && toolbarOptions.length > 0) {
  toolbarOptions = toolbarOptions.filter(option => option !== 'PDFExport');
}

// Check if AllowExcelExport is false and remove ExcelExport from toolbarOptions if present
if (!AllowExcelExport && Array.isArray(toolbarOptions) && toolbarOptions.length > 0) {
  toolbarOptions = toolbarOptions.filter(option => option !== 'ExcelExport');
} 
if (!ShowColumnChooser && Array.isArray(toolbarOptions) && toolbarOptions.length > 0) {
  toolbarOptions = toolbarOptions.filter(option => option !== 'ColumnChooser');
} 
    
      

  const databound = (AllowDataBound) => {
    setTimeout(()=>{
      
      if (irtizaDynamicGrid && AllowDataBound == true ) {
       
      irtizaDynamicGrid.autoFitColumns();
    }
  },200)
  }
 
  const dialogTemplate = (props) => {
    //  { isAdd, GridDialog, dataSourceState } 
    
    const { DialogType, Dialog } = GridDialog[0];
    if(DialogType === 'Self'){
    
    var DialogProps = Dialog.props
    var SelfDialog = Dialog.type}
    if (props.isAdd) {
      return DialogType === 'Master' ? (
        <IrtizDynamicInputAdd {...props} dataList={dataSourceState} />
      ) : DialogType === 'Self' ? (
        <SelfDialog {...DialogProps} {...props} PrintedByUserName={reduxData.printedByUserName}  dataList={dataSourceState}/>

        // <InvoiceEvaluationAdd {...props} dataList={dataSourceState} />
    ): (
        <IrtizDynamicInputAdd {...props} dataList={dataSourceState} />
      );
    } else {
      return DialogType === 'Master' ? (
        <IrtizDynamicInputAdd {...props} dataList={dataSourceState} />
      ) : DialogType === 'Self' ? (
        <SelfDialog {...DialogProps} {...props}  dataList={dataSourceState}/>
      ) : (
        <IrtizDynamicInputAdd {...props} dataList={dataSourceState} />
      );
    }
  };
  

  const editSettings: EditSettingsModel = {
    
    allowEditing: SecurityService.canUserAccess(EditSecurity == '' ? `${Id}Edit`:EditSecurity),
    allowAdding: SecurityService.canUserAccess(AddSecurity == '' ? `${Id}Add`:AddSecurity),
    allowDeleting: SecurityService.canUserAccess(DeleteSecurity == '' ? `${Id}Delete`:DeleteSecurity),
  //   allowDeleting:true,

  //  allowEditing: true,
  //  allowAdding: true,
    // allowDeleting: true,

    mode: GridEditSettingMode,
    showDeleteConfirmDialog: true,
    headerTemplate:GridEditSettingHeaderTemplate
   };
   if(GridEditSettingMode == 'Dialog'){
    editSettings.template= dialogTemplate;

   }
    
  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
    // columns: [
    //   {
    //     field: 'IsDeleted',
    //     operator: 'notequal',
    //     value: 'True'
    //   }
    // ]
    columns: filterState

  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
    columns: groupState,
    sortDirection:DefaultSortedColumnSort
  };

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  var visibleSortPassFields = useRef([defaultSortColumnName])
  var visibleSortPass = useRef([defaultSortColumnType])
  var ctrlPressed =  useRef(false)
  var groupingArray = []
  const actionComplete = (args: DialogEditEventArgs) => {
    
    if(args.requestType == "grouping"){
      groupingArray.push(args.columnName)
    }
    if(args.requestType == "ungrouping"){
      groupingArray.splice(groupingArray.indexOf(args.columnName),1)
    }
    ctrlPressed.current = false;
    let handleStore = (e) => {
      e.stopPropagation();
      return  ctrlPressed.current = e.ctrlKey; // Use e.ctrlKey, not e.ctrlkey
    };
    document.addEventListener('keydown', handleStore);
    setTimeout(() => {
      if(args.requestType ==  'sorting'){
        
        setTimeout(() => {
          if(ctrlPressed.current == true ){
            
            if(args.columnName == visibleSortPassFields.current[0] && visibleSortPassFields.current.length == 1){
            }else{
              if(visibleSortPassFields.current.includes(args.columnName)){
                
                visibleSortPass.current[visibleSortPassFields.current.indexOf(args.columnName)] = args.direction;
              }else{
                visibleSortPassFields.current.push(args.columnName)
                visibleSortPass.current.push(args.direction)
              }
            }
            setTimeout(() => {
              return ctrlPressed.current = false;
            }, 200);
          }else{
            
            visibleSortPassFields.current =[args.columnName]
            visibleSortPass.current = [args.direction]
          }
        }, 80);
        
        setTimeout(() => {
          
          
          
          
        }, 300);
          }
        }, 200);
          if(args.requestType == 'filtering'  && args.action == 'filter'){
            let filterArray = [...filterColumnName , args.currentFilteringColumn]
            filterColumnName = filterArray.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    }
    
    if (args?.requestType === 'columnstate') {
      
      irtizaDynamicGrid?.autoFitColumns();
    }
    if(args.requestType == 'filtering'  && args.action == "clear-filter"){
      if(recordColumnName.includes(args.currentFilteringColumn)){

        recordColumnName.splice(recordColumnName.indexOf(args.currentFilteringColumn),1)
        
        fiterCheckedValue.splice(recordColumnName.indexOf(args.currentFilteringColumn),1)
        if(filterColumnName.includes(args.currentFilteringColumn) ){
          filterColumnName.splice(filterColumnName.indexOf(args.currentFilteringColumn),1)
        }
      }
   
    }
    if(args.requestType == 'filtering'  && args.action == 'filter'){
      
      if(!recordColumnName.includes(args.currentFilteringColumn)){
        const filteredData = irtizaDynamicGrid.getFilteredRecords();
        let checkedValue   = filteredData.map((val)=>{ return val[args.currentFilteringColumn]}).filter((value, index, self) => { return self.indexOf(value) === index })
        recordColumnName = [...recordColumnName , args.currentFilteringColumn]
        fiterCheckedValue = [...fiterCheckedValue , checkedValue]
        }else{
      
      fiterCheckedValue.splice(recordColumnName.indexOf(args.currentFilteringColumn) , 1)
        const filteredData = irtizaDynamicGrid.getFilteredRecords();
        let checkedValue   = filteredData.map((val)=>{ return val[args.currentFilteringColumn]}).filter((value, index, self) => { return self.indexOf(value) === index })
        fiterCheckedValue = [...fiterCheckedValue , checkedValue]
        
      } 
  }
    if (args.form) {
      ListActionComplete(
        irtizaDynamicGrid ,
         args,
         getListService ,
          selectedRows ,
          setDataSourceState)

      
            

    }}

  // const commandClick = (args: CommandClickEventArgs) => {
  //   setShowDialog(false);
  //   
  //   setSelectedRow(args.rowData);
  //   setShowDialog(true);
  // };

  // const commands: CommandModel[] = [
  //   {
  //     buttonOption: {
  //       content: 'Delivery Schedule',
  //       cssClass: 'e-flat',
  //     },
  //   },
  // ];

  // const dialogClose = () => {
  //   this.buttonInstance.style.display = 'inline-block';
  //   setShowDialog(false);
  // };

  

  

  const userId = SecurityService.getLoggedInUserId();
      
 // DYNAMIC TEMPLATE CODE
 const GridSum = (GridSumColumns , ColumnsList)=>{



  // Initialize gridSum as an empty array
let gridSum = [];
if(AllowGridSum  == true){
if (GridSumColumns.length > 0) {
  // Map over GridSumColumns to set format based on conditions
  gridSum = GridSumColumns.map((column) => {
    if (column.includes('Rate') || column.endsWith('Percent')) {
      return {
        field: column,
        format: 'n2', // Format for rate or percent
      };
    } else {
      return {
        field: column,
        format: 'n0', // Default format
      };
    }
  });
} else {
  // If GridSumColumns is empty, map over ColumnsList
  gridSum = ColumnsList.map((column) => {
    // Check if format is defined in other[0]
    if (column.other[0]?.format) {
      return {
        field: column.field,
        format: column.other[0].format,
      };
    }
  }).filter((result) => result !== undefined); // Filter out undefined results
}
}else{
  gridSum = [
    {
      field:'dontmakesum',
      format: 'n0',
    }
  ]
}
// Log the resulting gridSum array


return( 
  
  <AggregatesDirective>

  <AggregateDirective>
    <AggregateColumnsDirective>
      {
gridSum.map(res=>{
return(<AggregateColumnDirective field={res.field} type='Sum' format={res.format} footerTemplate={footerSum} />)
})}
    </AggregateColumnsDirective>
  </AggregateDirective>

  <AggregateDirective>
    <AggregateColumnsDirective>
    {
gridSum.map(res=>{
return(<AggregateColumnDirective field={res.field} type='Sum' format={res.format} groupFooterTemplate={footerSum} />)
})}
      
     

    </AggregateColumnsDirective>
  </AggregateDirective>

</AggregatesDirective>
)
 }



 const ListGridComponent = ({ restrictPageSize = 12 }) => {
  // const columns = useMemo(() => {
    
  //   if (reduxData.gridColumnsListWithDetails.length > 0) {
  //     return reduxData.gridColumnsListWithDetails.map((field, index) => {
  //       if (field.field == 'Commands') {
  //         return (
  //           <ColumnDirective
  //             key={index} // You may use index as key here if there is no unique field key available
  //             headerText={field.headerText}
  //             width={field.width}
  //             headerTextAlign={field.headerTextAlign}
  //             textAlign={field.textAlign}
  //             {...field.other[0]}
  //             // direction={visibleSort[index]}
  //           />
  //         );
  //       } else {
        

  //           return (
  //             <ColumnDirective
  //             key={field.field}
  //             headerText={field.headerText}
  //             id={field.field}
  //             field={field.field}
  //             width={field.width}
  //             headerTextAlign={field.headerTextAlign}
  //             textAlign={field.textAlign}
  //             {...field.other[0]}
              
  //             // direction={visibleSort[index]}
  //             />
  //             );
            
  //       }
  //     });
  //   } else {
  //     return <ColumnDirective field='IsDeleted' visible={false} />; // You don't need to wrap it with () here
  //   }
  // }, [reduxData.gridColumnsListWithDetails.length > 0]);
  const columns = useMemo(() => {
    // Determine the appropriate column list based on IsDialog
    const sourceColumns = IsDialog ? ColumnsList : reduxData.gridColumnsListWithDetails;
  
    const updatedColumns = sourceColumns.map((column) => {
      if (column.field.endsWith('Date')) {
        return {
          ...column,
          other: [{ ...column.other[0], format: formatOptions }],
        };
      } else if (
        column.field.startsWith('Is') &&
        column.field !== 'IsDeleted' &&
        column.field !== 'IsApproved'
      ) {
        return {
          ...column,
          other: [
            {
              ...column.other[0],
              template: (props) => (
                <p style={{ margin: '0px' }}>
                  {props.IsApprovedDetail === true ? 'Yes' : 'No'}
                </p>
              ),
            },
          ],
        };
      }
      return column;
    });
  
    // Determine the data source length based on IsDialog
    const dataSourceLength = IsDialog ? dataSourceState.length : reduxData.gridColumnsListWithDetails.length;
  
    if (dataSourceLength > 0) {
      return updatedColumns.map((field, index) => {
        if (field.field === 'Commands') {
          return (
            <ColumnDirective
              key={index} // Use index as a key if a unique key is not available
              headerText={field.headerText}
              width={field.width}
              headerTextAlign={field.headerTextAlign}
              textAlign={field.textAlign}
              {...field.other[0]}
              // direction={visibleSort[index]}
            />
          );
        } else {
          return (
            <ColumnDirective
              key={field.field}
              headerText={field.headerText}
              id={field.field}
              field={field.field}
              width={field.width}
              headerTextAlign={field.headerTextAlign}
              textAlign={field.textAlign}
              {...field.other[0]}
              // direction={visibleSort[index]}
            />
          );
        }
      });
    } else {
      return <ColumnDirective field="IsDeleted" visible={false} />;
    }
  }, [IsDialog, ColumnsList, reduxData.gridColumnsListWithDetails, formatOptions]);
  
return (
    <GridComponent
          ref={(grid) => (irtizaDynamicGrid = grid)}
          id={`${Id}Grid`}
          dataSource={dataSourceState}
          editSettings={editSettings}
          pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
          toolbar={toolbarOptions}
          toolbarClick={toolbarClick}
          allowExcelExport={AllowExcelExport}
          allowPdfExport={AllowPdfExport}
          showColumnChooser={ShowColumnChooser}
          allowPaging={AllowPaging}
          allowGrouping={IsDialog == true ? false : AllowGrouping}
        
          
          
          filterSettings={filterOptions}
          groupSettings={groupOptions}
          actionComplete={actionComplete}
          allowTextWrap='true'
          
          allowSorting={true}
          allowFiltering={true}
          allowReordering={true}
          allowResizing={true}
          sortSettings={    IsDialog ?
                { columns:[{ field: defaultSortColumnName, direction: defaultSortColumnType }]}
    :
          reduxData.gridSortingOptionsDetails}
          dataBound={databound(AllowDataBound)}
          rowSelected={
            (args)=>{
              
              selectedRows = args.data
              
                        setTimeout(() => {
                
              }, 200);
            }
          }
        >
            <ColumnsDirective>
            
          {columns}
            </ColumnsDirective>
         {   GridSum( GridSumColumns, ColumnsList )}

          <Inject
            services={[
              Edit,
              Toolbar,
              Page,
              Sort,
              PdfExport,
              ExcelExport,
              Filter,
              Group,
              Aggregate,
              Freeze,
              ColumnChooser,
              Reorder,
              Resize,
              CommandColumn,
            ]}
          />
        </GridComponent>

)
};

// DYNAMIC TEMPLATE CODE
  const getColumnsDetail = ()=>{
    return irtizaDynamicGrid.getColumns()
  }

return (
    <div className={IsDialog == false?`content-wrapper`:''}>
      <div>
            <div>
      {IsDialog == true ? (<></>):(<>
              <h3>{
                ListTitle
                }
              {/* // DYNAMIC TEMPLATE CODE */}
              {AllowTemplate == true ? <button 
              onClick={(e)=>{ e.preventDefault();setIsToggled(!isToggled)}}
              className={`toggle-templete ${isToggled ? 'active' : ''}`}
              >
       {ToggledValue}               
          </button >:null }
          {/* isToggled ,
  ColumnProps ,
  getListService ,
  getColumns , 
  InputListSecurity ,
  visibleSortPassFields ,
  visibleSortPass ,
  groupingArray ,
  defaultSortColumnName ,
  defaultSortColumnType */}
              </h3> </>)}
              {IsDialog == true ? (<></>):(<>
              <TemplateCode
               isToggled={isToggled}
               ColumnProps={columnData}
               getListService={getListService}
               getColumns={getColumnsDetail}
               InputListSecurity={InputListSecurity}
               visibleSortPassFields={visibleSortPassFields}
               visibleSortPass={visibleSortPass}
               groupingArray={groupingArray}
               defaultSortColumnName={defaultSortColumnName}
               defaultSortColumnType={defaultSortColumnType}
               isHaveFilterColumn={FilterColumns.length > 0 && FilterColumns[0].field == 'IsDeleted'? true : false}

               />      
               </>)}
              <div>
              </div>
             
              {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                setTimeout(() => {
                        
                  getListService();
              }, 200);
    }
              }} 
                ></HardDelete>
                  :null}
            {
            loadingdataSourceState == false ?  
            <ListGridComponent />
              :                 
              <div className='loader-div'> <img src={imgSrc} id='' /> LOADING...</div>
            }
          </div>
            </div>
          </div>
  );
}





