import React, { useRef, useState , useEffect,useMemo} from 'react';
import moment from 'moment';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../dynamic-input/dynamic-main-inputs';

const ProductAdd = (props) => {
  
  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};

  const [inputProps, setInputProps] = useState({
  
    ProductID:{name: 'ProductID',   value: props.ProductID || '' ,enabled:false},
    ProductName:{name: 'ProductName',   value: props.ProductName || '' },
    UnitOfMeasurement:{
      name:{id:'UnitOfMeasurementID',value:'UnitOfMeasurement'},
      caption: 'Select  UNIT OF MEASUREMENT',
      value: {id: props.UnitOfMeasurementID || undefined ,value:props.UnitOfMeasurement || ''},
      dataSource: {type:'Route',route:'UnitOfMeasurement'},
      addNew:{Route:'UnitOfMeasurement',Security:'UnitOfMeasurement'}

        },
    });
// EXTRA CODE START

// EXTRA CODE END

const handleInputCreated = async (e, name) => {
  console.log(e);
    console.log(`Input ${name} changed to ${e}`);
  };
const handleInputFocus = async (e, name) => {
  
    console.log(e);
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {
  
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {Input: HiddenComponent ,Props: {...inputProps.ProductID, setInputProps },ColumnSize:0},

    {Input: TextComponent,Props: {...inputProps.ProductName,setInputProps },ColumnSize:12},

    {Input: DropdownComponent,Props: {...inputProps.UnitOfMeasurement, setInputProps },ColumnSize:12},
    
    
  ];
  return (
    <div onKeyDown={(e)=>e.stopPropagation()}>
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   mainProps={props} title={`Product ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
      </div>
  );
}; 

export default ProductAdd;
