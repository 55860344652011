import React, { useRef, useState , useEffect,useMemo} from 'react';
import invoiceEvaluationService from './invoice-evaluation.service';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../dynamic-input/dynamic-main-inputs';


// const DynamicDropDownInput = (props) => {
//   console.log(props)
//   const [loading, setLoading] = useState(false);
//   const [dataSourceState, setDataSourceState] = useState([]);
//   const [dropdownID, setDropdownID] = useState(props.value.id ? props.value.id : undefined);
//   const [dropdownValue, setDropdownValue] = useState(props.value.value ?props.value.value : '');
//   const [addNew, setAddNew] = useState(props.addNew ? props.addNew : {});
//   const [addNewClicked, setAddNewClicked] = useState(false);


//   useEffect(() => {
//     setDropdownID(props.value.id)
//     setDropdownValue(props.value.value)
//   }, [props.value.id, props.value.value]);
//   const refs = useRef({});

//   // Assign a dynamic ref based on the name prop
//   if (!refs.current[props.name.value]) {
//     refs.current[props.name.value] = React.createRef();
//   }
//   const dynamicRef = refs.current[props.name.value];
//   console.log(dynamicRef)
//   const ddlFields: object = { value: 'value' };

//   useEffect(() => {
//     if(props.dataSource.type == 'Route' ){
//       console.log(props.dataSource.type);
//       console.log(props.dataSource.route);
//       getDropdownService(props.dataSource.route)
//     }
    
//   }, []);

//   const getDropdownService = async (type) => {
//     try {
//       setLoading(false)
//       const response = await DropdownService.getAllForList(type);
//       if (response.data || response.data.recordset) {
//               setLoading(false)
//               setDataSourceState(response.data.recordset);
//               if(props.isAdd == true && props.value.id != 'undefined'){
//                 let filterData = response.data.recordset.filter(res=>res.id == props.value.id)
//                 console.log(filterData)
//                 setDropdownID(filterData[0].id)
//                 setDropdownValue(filterData[0].value)
//               }
//        } else {
//         setLoading(false)
//         console.error("Invalid response structure:", response);
//         setDataSourceState([])
//       }
//      } catch (error) {
//       setLoading(false)
      
//       setDataSourceState([])
//     }
//   };


//   const handleInputFocus = (inputRef) => {if (inputRef ) {inputRef.current.showPopup();}};

//   const handleChange = (e) =>{
//     setDropdownID(e?.itemData?.id ? e?.itemData?.id : undefined)
//     setDropdownValue(e?.itemData?.value ? e?.itemData?.value : '')
//     if(e?.itemData?.id ){
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name.value]: {...prevValues[props.name.value] ,value :{id:e?.itemData?.id ? e?.itemData?.id : undefined , value:e?.itemData?.value ? e?.itemData?.value :''} }
//     }));
//     }
//   }

//   const handleAddNewBlur = (id, ref, value, elementId, productArray, isHaveAddNew) => {
//     if (id === 0 || ref.current.value == null || ref.current.value === '' || id === undefined || id === null) {
//       if (value !== '') {
//         const isValueInProductDesc = productArray.some((e) => e.value === value);
//         if (!isValueInProductDesc) {
//           const elem = document.getElementById(elementId).parentElement.parentElement.firstChild;
//           elem.style.border = '3px solid red';
//           setTimeout(() => {
//             elem.style.border = '1px solid #c8c8c8';
//           }, 1000);
//         }
//       }
//       if (!isHaveAddNew) {
//         ref.current.element.focus();
//       }
//     }
//   };
//   return (
//     loading ? (
//       <div>... Loading {props.name.value.replace(/([A-Z])/g, ' $1').trim()}</div>
//     ) : (
//       <div>
//         <AutoCompleteComponent 
//           id={props.name.value} 
//           dataSource={dataSourceState}  
//           placeholder={props.caption ? props.caption : `Select ${props.name.replace(/([A-Z])/g, ' $1').trim()}`}
//           fields={ddlFields}
//           floatLabelType='Auto' 
//           highlight={true}
        
//           created={(e)=>(props.onCreated ? props.onCreated(e, props.name.value) : null)}
        
//           focus={(e) =>(props.onFocus ? props.onFocus(e, props.name.value) : null ,handleInputFocus(dynamicRef))}
        
//           change={(e) => (props.onChange ? props.onChange({
//               id:e?.itemData?.id ? e?.itemData?.id : undefined,
//               value:e?.itemData?.value ? e?.itemData?.value : '' 
//           }, props.name.value) : null,handleChange(e))}
        
//           select={(e) => (props.onSelect ? props.onSelect({
//             id:e?.itemData?.id ? e?.itemData?.id : undefined,
//             value:e?.itemData?.value ? e?.itemData?.value : '' 
//         }, props.name.value): null,handleChange(e))}

//         blur={() =>(
//           props?.isControlBlur  == false? null :  handleAddNewBlur(dropdownID,dynamicRef,dropdownValue,props.name.value,dataSourceState,addNewClicked),
//             props.onBlur ? props.onBlur({
//               id:dropdownID,
//               value:dropdownValue
//             }, props.name.value) : null )
//           }
//           value={dropdownValue}
//           ref={dynamicRef}
//           disabled={props?.enabled   == undefined ? false : !props?.enabled}     
//           />
//         <TextBoxComponent
//           className='e-input'
//           type='hidden'
//           name={props.name.id}
//           floatLabelType='Auto'
//           value={dropdownID}
//         />
//         {addNew.Route && addNew.Security?
//         <AddNewDialog Route={addNew.Route} Security={addNew.Security} />
//         :null}
//       </div>
//     )
//   );
// };



// const DynamicDateInput = (props) => {
//   console.log(`${props.name } props: ${props}`)

//   const handleBlur = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :e?.target?.value || prevValues[props.name].value}
//     }));
//   };
//   const handleChange = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :e?.value || prevValues[props.name].value }
//     }));
//   };

//   return (
//     <>
//       <DatePickerComponent
//         id={props.name}
//         name={props.name}
//         placeholder={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
//         floatLabelType='Auto'
//         format='dd-MM-yy'
//         created={(e)=>(props.onCreated ? props.onCreated(e, props.name) : null)}
//         onBlur={(e)=>(props.onBlur ? props.onBlur(e.target.value, props.name) : null ,handleBlur(e))}
//         focus={(e) => props.onFocus ? props.onFocus(e, props.name) : null}
//         onChange={(e) => (props.onChange ? props.onChange(e.value, props.name) : null,handleChange(e))}
//         value={props.value == 'currentDate'?  new Date() : props.value || ''}
//         disabled={props?.enabled   == undefined ? false : !props?.enabled}     
//       />
//     </>
//   );
// };

// const DynamicNumericInput = (props) => {
//   console.log(props)
//   const handleChange = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :e?.value == "" ? prevValues[props.name].value :  e.value}
//     }));
//   };
//   const handleBlur = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :e?.target?.value == "" ?  parseFloat(0).toFixed(props.decimal || 0)  : parseFloat(e.target.value).toFixed(props.decimal || 0)}
//     }));
//   };
//   const handleCreated = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :parseFloat(props.value).toFixed(props.decimal || 0) || 0.0}
//     }));
//   };
//   return (
//     <>
//       <TextBoxComponent
//         className='e-input'
//         type='number'
//         placeholder={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
//         name={props.name}
      
//         id={props.name}
//         floatLabelType='Auto'
//         value={props.value || 0.0}
//         created={(e)=>(handleCreated(e) , props.onCreated ? props.onCreated(e, props.name) : null )}
//         onBlur={(e)=>(handleBlur(e) , props.onBlur ? props.onBlur(e.target.value, props.name) : null)}
//         focus={(e) => props.onFocus ? props.onFocus(e, props.name) : null}
//         onChange={(e) => (handleChange(e) , props.onChange ? props.onChange(e.value, props.name) : null)}
//         enabled={props?.enabled == false  ?  props?.enabled :    true}     
//         // disabled={!props?.enabled  false}

//         />
//     </>
//   );
// };

// const DynamicTextInput = (props) => {
//   console.log(props)
//   const handleChange = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :e?.value == "" ? prevValues[props.name].value :  e.value}
//     }));
//   };
//   const handleBlur = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :e?.target?.value == "" ?  "" : e.target.value}
//     }));
//   };
//   const handleCreated = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :props.value || ""}
//     }));
//   };
//   return (
//     <>
//       <TextBoxComponent
//         className='e-input'
//         placeholder={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
//         name={props.name}
//         id={props.name}
//         floatLabelType='Auto'
//         value={props.value || ''}
//         created={(e)=>(handleCreated(e) , props.onCreated ? props.onCreated(e, props.name) : null )}
//         onBlur={(e)=>(handleBlur(e) , props.onBlur ? props.onBlur(e.target.value, props.name) : null)}
//         focus={(e) => props.onFocus ? props.onFocus(e, props.name) : null}
//         onChange={(e) => (handleChange(e) , props.onChange ? props.onChange(e.value, props.name) : null)}
//         enabled={props?.enabled == false  ?  props?.enabled :    true}     
//         // disabled={!props?.enabled  false}

//         />
//     </>
//   );
// };

// const DynamicCheckBoxInput = (props) => {
//   console.log(props)
//   const handleChange = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :e?.checked == true ? 1 : 0 }
//     }));
//   };
//   // const handleBlur = (e) => {
//   //   console.log(e)
//   //   props.setInputProps((prevValues) => ({
//   //     ...prevValues,
//   //     [props.name]: {...prevValues[props.name] ,value :e.target.value == 'on' ? true : false}
//   //   }));
//   // };
  
  
//   return (
//     <>
//     <CheckBoxComponent
//         className="e-input"
//         label={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
//         floatLabelType="Auto"
//         checked={props.value ==  true ? 1 : false}
//         change={(e)=>(handleChange(e) , props.onChange ? props.onChange(e.checked == true ? 1:0, props.name) : null)}
//         onBlur={(e)=>( console.log(e), props.onBlur ? props.onBlur(props.value , props.name) : null)}
//         disabled={props?.enabled   == undefined ? false : !props?.enabled}     

//             />
//             <TextBoxComponent
//               name={props.name}
//               id={props.name}
//               value={props.value}
//               type='hidden'

//             />
      
//     </>
//   );
// };

// const DynamicHiddenInput = (props) => {
//   console.log(props)
  
//   const handleCreated = (e) => {
//     console.log(e)
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {...prevValues[props.name] ,value :props.value || ""}
//     }));
//   };
//   return (
//     <>
//       <TextBoxComponent
//         className='e-input'
//         name={props.name}
//         id={props.name}
//         floatLabelType='Auto'
//         value={props.value || ''}
//         created={(e)=>( props.onCreated ? props.onCreated(e, props.name) : null )}
//         type='hidden'
//         />
//     </>
//   );
// };

// const StructuredGenerate = ({ title , mainProps , Inputs }) => {
//   console.log(Inputs)
//   return (
//     <div className='row'>
//         <div  className={`col-sm-${12}`} style={{textAlign:'center'}}>
//             <h1 style={{padding:'4px'}}>{title}</h1>
//             </div>
//       {Inputs.map((res, index) => {
//         let Input = res.Input;
//         if(res.Input.name == "DynamicHiddenInput"){
//       return(<Input {...res.Props} {...mainProps} />)
//         }else{
//      return  (
//           <div key={index} className={`col-sm-${res.ColumnSize  ?res.ColumnSize  : 4}`}>
//             <Input {...res.Props} {...mainProps} />
//           </div>

//         )
//       }
//       })}
//     </div>
//   );
// };

const InvoiceEvaluationAdd = (props) => {
let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput};

  const [inputProps, setInputProps] = useState({
  
    // TransactionDate:{name: 'TransactionDate',   value: props.TransactionDate || ''},
    InvoiceNo:{
      name:{id:'MainInvoiceID',value:'InvoiceNo'},
      caption: 'Select Invoice No',
      value: {id: props.MainInvoiceID || 'undefined' ,value:props.InvoiceNo || ''},
      dataSource: {type:'Route',route:'InvoiceRI'},
      //   addNew:{Route:'sldf',Security:'df'},
      // isControlBlur:false
    },
    ExchangeRate:{name: 'ExchangeRate', caption: 'Exchange Rate',  value: props.ExchangeRate || 0 , decimal:1},
    // ExchangeRate:{name: 'ExchangeRate', caption: 'Exchange Rate',  value:  '' },
    HawalaCommissionPercent:{name: 'HawalaCommissionPercent', caption: 'Hawala Commission %',  value: props.HawalaCommissionPercent || 0.00 , decimal:2},
    CurrencyName:{
      name:{id:'CurrencyID',value:'CurrencyName'},
      caption: 'Select Currency',
      value: {id: props.CurrencyID || '2' ,value:props.CurrencyName || ''},
      dataSource: {type:'Route',route:'Currency'},
      enabled:false

      //   addNew:{Route:'sldf',Security:'df'},
      // isControlBlur:false
    },
    CurrencyNameHawala:{
      name:{id:'CurrencyIDHawala',value:'CurrencyNameHawala'},
      caption: 'Select Currency Hawala',
      value: {id: props.CurrencyIDHawala || '1' ,value:props.CurrencyNameHawala || ''},
      dataSource: {type:'Route',route:'Currency'},
      enabled:false
       //   addNew:{Route:'sldf',Security:'df'},
      // isControlBlur:false
    },
    
    AccountTitleHawalaDr:{
      name:{id:'AccountIDHawalaDr',value:'AccountTitleHawalaDr'},
      caption: 'Select Account Hawala Dr',
      dataSource: {type:'Route',route:'ChartOfAccount'},
      value: {id: props.AccountIDHawalaDr || 'undefined' ,value:props.AccountTitleHawalaDr || ''},
      enabled:false
   //   addNew:{Route:'sldf',Security:'df'},
      // isControlBlur:false
    },
    
    AccountTitleHawalaCr:{
      name:{id:'AccountIDHawalaCr',value:'AccountTitleHawalaCr'},
      caption: 'Select Account Hawala Cr',
      value: {id: props.AccountIDHawalaCr || 'undefined' ,value:props.AccountTitleHawalaCr || ''},
      dataSource: {type:'Route',route:'ChartOfAccount'},
      enabled:false
      //   addNew:{Route:'sldf',Security:'df'},
      // isControlBlur:false
    }
    
    });
// EXTRA CODE START
const getDefaultValueOfAccountTitleHawalaDr = async ()=>{
    try {
      const response = await invoiceEvaluationService.getDefaultAccountTitleHawalaDr();
      if (response.data && response.data.recordset) {
        console.log(response.data.recordset[0].id)
        setInputProps((prevValues) => ({
          ...prevValues,
          ['AccountTitleHawalaDr']: {...prevValues['AccountTitleHawalaDr'] ,value :{  value:response.data.recordset[0].value , id:response.data.recordset[0].id } }
    
        }));
      }
        else {
        console.error("Invalid response structure:", response);
      }
     } catch (error) {
      }
  }
const getDefaultValueOfAccountTitleHawalaCr = async ()=>{
    try {
      const response = await invoiceEvaluationService.getDefaultAccountTitleHawalaCr();
      if (response.data && response.data.recordset) {
        console.log(response)
        setInputProps((prevValues) => ({
          ...prevValues,
          ['AccountTitleHawalaCr']: {...prevValues['AccountTitleHawalaCr'] ,value :{id:response.data.recordset[0]?.id, value:response.data.recordset[0]?.value} }
    
        }));
      }
        else {
        console.error("Invalid response structure:", response);
      }
     } catch (error) {
      }
  }
// EXTRA CODE END

const handleInputCreated = async (e, name) => {
  if(name == 'AccountTitleHawalaDr'){
    return await getDefaultValueOfAccountTitleHawalaDr()
  }else if(name ==  'AccountTitleHawalaCr'){
    return await getDefaultValueOfAccountTitleHawalaCr()
  }
  
  console.log(e);
    console.log(`Input ${name} changed to ${e}`);
  };
const handleInputFocus = async (e, name) => {
  
    console.log(e);
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {
  
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
  
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {
      Input: DropdownComponent,
      Props: {
        ...inputProps.InvoiceNo, 
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
      },
    {
      Input: DropdownComponent,
      Props: {
        ...inputProps.CurrencyName, 
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
      },
    // {
    //   Input: DynamicDateInput,
    //   Props: {
    //     ...inputProps.TransactionDate, 
    //     onCreated:handleInputCreated,  
    //     onFocus: handleInputFocus, 
    //     onChange: handleInputChange,
    //     onBlur: handleInputBlur,
    //     setInputProps },
    //   ColumnSize: 4
    // },
   
    {
      Input: NumberComponent,
      Props: {
        ...inputProps.ExchangeRate, 
        onCreated:handleInputCreated,
        onBlur: handleInputBlur,
        setInputProps },
      },
    {
      Input: NumberComponent,
      Props: {
        ...inputProps.HawalaCommissionPercent, 
        onBlur: handleInputBlur,
        setInputProps }
    },
    {
      Input: DropdownComponent,
      Props: {
        ...inputProps.CurrencyNameHawala, 
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
      },
    {
      Input: DropdownComponent,
      Props: {
        ...inputProps.AccountTitleHawalaDr, 
        onCreated:handleInputCreated,
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
      },
    {
      Input: DropdownComponent,
      Props: {
        ...inputProps.AccountTitleHawalaCr, 
        onCreated:handleInputCreated,
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
      },
  ];
//   const FirstGridComponent = useMemo(()=>()=>{
//  return( <StructuredGenerate   mainProps={props} title={'Invoice Evaluation Add'} Inputs={inputsList}/>)
//   },[inputProps])
  return (
    <>
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   mainProps={props} title={`Invoice Evaluation ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
    </>
  );
}; 

export default InvoiceEvaluationAdd;
