import React, { useRef, useState , useEffect,useMemo} from 'react';
import moment from 'moment';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../dynamic-input/dynamic-main-inputs';

const CurrencyAdd = (props) => {
  
  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};
// MainTransferID	
// CurrencyID	
// TransferDate	
// FromShopID	
// ToShopID
  
  const [inputProps, setInputProps] = useState({
  
    CurrencyID:{name: 'CurrencyID',   value: props.CurrencyID || '' ,enabled:false},
    CurrencyName:{name: 'CurrencyName',   value: props.CurrencyName || '' },
    AccountTitle:{
      name:{id:'AccountID',value:'AccountTitle'},
      caption: 'Select Account',
      value: {id: props.AccountID || undefined ,value:props.AccountTitle || ''},
      dataSource: {type:'Route',route:'ChartOfAccountVoucher'},
        },
    });
// EXTRA CODE START

// EXTRA CODE END

const handleInputCreated = async (e, name) => {
  console.log(e);
    console.log(`Input ${name} changed to ${e}`);
  };
const handleInputFocus = async (e, name) => {
  
    console.log(e);
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {
  
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {Input: HiddenComponent ,Props: {...inputProps.CurrencyID, setInputProps },ColumnSize:0},

    {Input: TextComponent,Props: {...inputProps.CurrencyName,setInputProps },ColumnSize:12},

    {Input: DropdownComponent,Props: {...inputProps.AccountTitle, setInputProps },ColumnSize:12},
    
    
  ];
  return (
    <div onKeyDown={(e)=>e.stopPropagation()}>
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   mainProps={props} title={`Currency ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
      </div>
  );
}; 

export default CurrencyAdd;
