import React, { useRef, useState , useEffect,useMemo} from 'react';
import moment from 'moment';
import DropdownService from '../dynamic-input/dropdown-api.service';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../dynamic-input/dynamic-main-inputs';
import IrtizaDynamicInputGrid from '../dynamic-input/dynamic-input-grid';


import './invoice.scss'
import invoiceService from './invoice.service';
import MultiDropDown from '../MultiDropDown';
import { AutoComplete, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';





const InvoiceDetail = (props) => {
  console.log(props)
  // SET
  let saleProduct =  useRef(null)
  let totalInvoiceAmountRef =  useRef(null)
  let mainInvoiceID =  useRef(undefined)
  // let CalculationValue =  useRef(null)

  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};
  
  // InvoiceID	
  // InvoiceType	
  // InvoiceDate	
  // InvoiceNo	
  // AccountID	
  // AccountIDBill	
  // CurrencyID	
  // ShopID	
  // AdjustmentAmountFC	
  // FreightAmountFC	
  // FREIGHT + ADJUSTMENT COST textbox
  // Remarks	
  // HasFinancialImpact
  
  const [inputProps, setInputProps] = useState({
    CallGridDataSource:{name: 'CallGridDataSource', value:props.isAdd == false},
    MainInvoiceID:{name: 'MainInvoiceID', caption: 'Main Invoice ID', value:props.MainInvoiceID || undefined},
    InvoiceID:{name: 'InvoiceID', caption: 'Invoice ID',enabled:false , value:props.InvoiceID},
    InvoiceType:{name: 'InvoiceType', caption: 'Invoice Type' , value:props.Type == 'Purchase' ? 'RI':'II'},
    InvoiceDate:{name: 'InvoiceDate', caption: 'Invoice Date' , value:props.InvoiceDate ? props.InvoiceDate:'currentDate'},
    InvoiceNo:{name: 'InvoiceNo', caption: 'Invoice No' , value: props.InvoiceNo || '-' },
    AccountTitle:{
      name:{id:'AccountID',value:'AccountTitle'},
      caption: 'Select Account Title',
      dataSource: {type:'Route',route:props.Type == 'Purchase' ? 'ChartOfAccountInvoicePurchase' : 'ChartOfAccountInvoiceSale'},
      value: {id: props.AccountID || 'undefined' ,value:props.AccountTitle || ''},
      //enabled:true
      //   addNew:{Route:'sldf',Security:'df'},
      // isControlBlur:false
    },
    AccountIDBill:{name: 'AccountIDBill', caption: 'Account ID Bill' , value: props.AccountIDBill },
    AccountTitleBill:{name: 'AccountTitleBill', caption: 'Account Title Bill' , value: props.AccountTitleBill ,  enabled:false },

    CurrencyID:{name: 'CurrencyID', caption: 'Currency ID' , value: props.CurrencyID },
    CurrencyName:{name: 'CurrencyName', caption: 'Currency Name' , value: props.CurrencyName ,  enabled:false },
    AdjustmentAmountFC:{name: 'AdjustmentAmountFC', caption: 'Adjustment Amount', decimal:2 , value: props.AdjustmentAmountFC == undefined ? 0 : props.AdjustmentAmountFC},
    FreightAmountFC:{name: 'FreightAmountFC', caption: 'Freight Amount', decimal:2 , value: props.FreightAmountFC == undefined ? 0 : props.FreightAmountFC  },
    FreightAdjustmentFCCost:{name: 'FreightAdjustmentFCCost', caption: 'Freight Adjustment Cost', decimal:2 , value:  (props.FreightAmountFC + props.AdjustmentAmountFC).toLocaleString() || 0 , enabled:false},
    Remarks:{name: 'Remarks', caption: 'Remarks',  value: props.Remarks || '-' },
    HasFinancialImpact:{name: 'HasFinancialImpact', caption: 'HasFinancialImpact',  value: 1},
    BrandName:{
      name:{id:'BrandID',value:'BrandName'},
      caption: 'Select Brand Name',
      dataSource: {type:'Route',route:'Brand'},
      value: {id: props.BrandID || 'undefined' ,value:props.BrandName || ''},
      //enabled:true
      //   addNew:{Route:'sldf',Security:'df'},
      isControlBlur:false
    },
    BrandButton:{name: 'BrandButton', caption: 'GET PURCHASE',isClicked: false},
    });


// EXTRA CODE START
const getNewInvoiceID = async ()=>{
    try {
      const response = await invoiceService.getNewInvoiceID(props.ShopID, `${props.Type}Invoice`);
      if (response.data && response.data.recordset) {
        console.log(response)
        setInputProps((prevValues) => ({
          ...prevValues,
          ['InvoiceID']: {...prevValues['InvoiceID'] ,value :response.data.recordset[0]?.InvoiceID }    
        }));
      }
        else {
        console.error("Invalid response structure:", response);
      }
     } catch (error) {
      }
      
  }

const getAccountBill = async ()=>{
    try {
      console.log(props.Type )
      const response = await invoiceService.getAccountBill( props.Type);
      if (response.data && response.data.recordset) {
        setInputProps((prevValues) => ({
          ...prevValues,
          ['AccountIDBill']: {...prevValues['AccountIDBill'] ,value :response.data.recordset[0]?.AccountIDBill },
          ['AccountTitleBill']: {...prevValues['AccountTitleBill'] ,value :response.data.recordset[0]?.AccountTitleBill }
    
        }));
      }
        else {
        console.error("Invalid response structure:", response);
      }
     } catch (error) {
      }
  }
const getCurrencyValue = async (id)=>{
    try {
      const response = await DropdownService.getAllForList('Currency');
      if (response.data && response.data.recordset) {
              
                let filterData = response.data.recordset.filter(res=>res.id == id)
                console.log(filterData)
              setInputProps((prevValues) => ({
          ...prevValues,
          ['CurrencyName']: {...prevValues['CurrencyName'] ,value :filterData[0].value},
          ['CurrencyID']: {...prevValues['CurrencyID'] ,value :filterData[0].id}
    
        }));
      }
        else {
        console.error("Invalid response structure:", response);
      }
     } catch (error) {
      }
  }

  
  const callGridDataSource = ()=>{
    return invoiceService.getGridDataSource(props.ShopID , inputProps.BrandName.value.id)
  }

// EXTRA CODE END

const handleInputCreated = async (e, name) => {
  if(name == 'InvoiceID' && props.isAdd == true){
    return await getNewInvoiceID()
  }else if(name ==  'AccountTitleBill' && props.isAdd == true){
    return await getAccountBill()
  }
  else if(name ==  'CurrencyName' && props.Type == 'Purchase'){
    
    return await getCurrencyValue(1)
  }
  };

const handleInputFocus = async (e, name) => {
    if(name == 'BrandName'){
      setInputProps((prevValues) => ({
        ...prevValues,
        ['BrandButton']: {...prevValues['BrandButton'] ,isClicked :false ,enabled:true }
      }));
    
    }
    
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {
  if(name ==  'AccountTitle' && props.Type == 'Sale'){
    let accountID = inputProps.AccountTitle.value.id;
    console.log(accountID)
    return await  accountID == 953 ?  getCurrencyValue(1) : getCurrencyValue(2)
  }else if(name == 'AdjustmentAmountFC' ||  name == 'FreightAmountFC' && props.Type == 'Purchase'){
    setTimeout(() => {
      console.log(inputProps.AdjustmentAmountFC.value);
      console.log(inputProps.FreightAmountFC.value);
      console.log(totalInvoiceAmountRef);
    
      // Convert the string values to numbers
      let adjustmentAmount = parseFloat(inputProps.AdjustmentAmountFC.value) || 0; // Fallback to 0 if NaN
      let freightAmount = parseFloat(inputProps.FreightAmountFC.value) || 0; // Fallback to 0 if NaN
    
      // Calculate the sum and format it
      let sumOfAdjustmentAndFreight = (adjustmentAmount + freightAmount).toFixed(2);
    
      console.log(sumOfAdjustmentAndFreight);
    
      // Update the state with the formatted value
      setInputProps((prevValues) => ({
        ...prevValues,
        ['FreightAdjustmentFCCost']: {
          ...prevValues['FreightAdjustmentFCCost'],
          value: parseFloat(sumOfAdjustmentAndFreight).toLocaleString(),
        },
      }));
   
    
      if(props.isAdd == false && totalInvoiceAmountRef.current == null){
        console.log(value)
        var totalAmount = props.TotalInvoiceCost
        if(name == 'AdjustmentAmountFC'){
          totalAmount = totalAmount - props.AdjustmentAmountFC
        }else if(name == 'FreightAmountFC'){
          totalAmount = totalAmount - props.FreightAmountFC
        }
        totalInvoiceAmountRef.current = props.TotalInvoiceCost - props.AdjustmentAmountFC - props.FreightAmountFC
        console.log(totalAmount)
        let EditCalculationValue = totalAmount + Number(value)
        if(document.getElementById('CalculationValue')){
        document.getElementById('CalculationValue').value =EditCalculationValue.toLocaleString()
        }
      }else if(totalInvoiceAmountRef.current == null){
      }else{
        let inputValues = parseInt(inputProps.AdjustmentAmountFC.value) +  parseInt(inputProps.FreightAmountFC.value)

        let CalculationValue =inputValues + totalInvoiceAmountRef.current
        document.getElementById('CalculationValue').value =CalculationValue.toLocaleString()

      }
    },100)
   }
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
  if(name == 'Remarks' && props.Type == 'Sale'){
    setTimeout(()=>document.getElementById('InvoiceDetailGrid_add').click(),500)

  }


    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputClick = async  ( name) => {
  
  if(name == 'BrandButton' ){
    console.log(inputProps.BrandName.value.id)
    if(inputProps.BrandName.value.id == undefined){
      
    // //  await getListService(true)
    console.log('203')
      setInputProps((prevValues) => ({
        ...prevValues,
        ['CallGridDataSource'] : {...prevValues['CallGridDataSource'] ,value:true},
        ['BrandButton'] : {...prevValues['BrandButton'] ,isClicked : true,enabled:false }
      })) 
      setTimeout(() => {
        
        setInputProps((prevValues) => ({
          ...prevValues,
          ['CallGridDataSource'] : {...prevValues['CallGridDataSource'] ,value:false},
          ['BrandButton'] : {...prevValues['BrandButton'] ,isClicked : false,enabled:false }
        }))
      }, 100);
        setTimeout(()=>document.getElementById('InvoiceDetailGrid_add').click(),700)
    
    }else{
      setTimeout(async() => {
      
        // callGridDataSource()
  
        console.log('20')
        setInputProps((prevValues) => ({
          ...prevValues,
          ['BrandButton'] : {...prevValues['BrandButton'] ,isClicked : false,enabled:false }
        }))
        
      //   // setCallPush(true)
        
        }, 200);
      
    }
    console.log(name);
  }
  
  };
 
  let inputsList = [
    {Input: HiddenComponent ,Props: {...inputProps.MainInvoiceID, setInputProps },ColumnSize:0},

    {Input: props.isAdd == true ? HiddenComponent :  TextComponent,Props: {...inputProps.InvoiceID, onCreated:handleInputCreated,setInputProps },ColumnSize: props.isAdd == true ? 0 : 4},

    { Input: HiddenComponent,Props: {...inputProps.InvoiceType, setInputProps },ColumnSize:0},

    {Input: DateComponent,Props: {...inputProps.InvoiceDate, setInputProps }},

    { Input: props.Type == 'Purchase' ? TextComponent : HiddenComponent,Props: {...inputProps.InvoiceNo, setInputProps },ColumnSize: props.Type == 'Purchase' ? 4 : 0},
    
    { Input: DropdownComponent,Props: {...inputProps.AccountTitle,onChange:handleInputChange, setInputProps }},
    
    { Input: HiddenComponent,Props: {...inputProps.AccountIDBill, setInputProps },ColumnSize:0},
    
    { Input: TextComponent,Props: {...inputProps.AccountTitleBill, onCreated:handleInputCreated,setInputProps }},
    
    { Input: HiddenComponent,Props: {...inputProps.CurrencyID, setInputProps },ColumnSize:0},
    
    { Input: TextComponent,Props: {...inputProps.CurrencyName, onCreated:handleInputCreated,setInputProps }},
    
    { Input:  HiddenComponent,Props: {name:'spaceCreate',setInputProps },ColumnSize:props.isAdd ? 4:0},

    { Input: props.Type == 'Purchase' && props.UserID == '0' ?  NumberComponent :  HiddenComponent,Props: {...inputProps.AdjustmentAmountFC, onChange:handleInputChange,onBlur:handleInputBlur,setInputProps },ColumnSize:props.Type == 'Purchase' && props.UserID == '0' ? 4 : 0},
    
    { Input: props.Type == 'Purchase' && props.UserID == '0' ?  NumberComponent :  HiddenComponent,Props: {...inputProps.FreightAmountFC, onChange:handleInputChange,onBlur:handleInputBlur,setInputProps },ColumnSize:props.Type == 'Purchase' && props.UserID == '0' ? 4 : 0},
    
    { Input: props.Type == 'Purchase' && props.UserID == '0' ?  TextComponent :  HiddenComponent,Props: {...inputProps.FreightAdjustmentFCCost, setInputProps },ColumnSize:props.Type == 'Purchase' && props.UserID == '0' ? 4 : 0},
    
    { Input: TextComponent,Props: {...inputProps.Remarks, onBlur:handleInputBlur,setInputProps },ColumnSize:12},
    
    { Input: HiddenComponent,Props: {...inputProps.HasFinancialImpact, setInputProps },ColumnSize:0},
    
    { Input: props.Type == 'Purchase'  && props.isAdd == true ?  DropdownComponent : HiddenComponent,Props: {...inputProps.BrandName,onFocus:handleInputFocus,  setInputProps },ColumnSize:props.Type == 'Purchase'  && props.isAdd == true ? 4 : 0},
    { Input: props.Type == 'Purchase' && props.isAdd == true ? ButtonComponent : HiddenComponent,Props: {...inputProps.BrandButton,onClick:handleInputClick,onBlur:handleInputBlur, setInputProps },ColumnSize:props.Type == 'Purchase' && props.isAdd == true ? 4 : 0},
  ];
//************************************************************************************* */
//************************************************************************************* */
//************************************************************************************* */
//************************************************************************************* */
//************************************************************************************* */
//************************************************************************************* */
//************************************************************************************* */
//************************************************************************************* */
  // Grid  Sections


 
// SET
const ddlFields: object = { value: 'value' };

// SET
// const [saleProductNameDataSource, setSaleProductNameDataSource] = useState([]);
const getSaleProductNameDataSource =  async (id,productID)=>{
  try {
    console.log(productID)
    let response =  await invoiceService.getMultiDropDownSource(`Stock ,0, 01-JUN-24 ,  ${moment().format('DD-MMM-YY')},${id},0,${props.ShopID}`)
      if(response.data  && response.data.recordset){
        console.log(response.data.recordset)
      saleProduct.current.dataSource = response.data.recordset 
      if(productID){
      let value = response.data.recordset.filter(res => res.ProductID == productID)
      console.log(value)
      saleProduct.current.itemData = {value : value[0].value , id :value[0].id , BalQty:value[0].BalQty}
      document.getElementById('ProductName').value = value[0].value
      }
    }
    
  } catch (error) {
    console.log(error)
  }  
  
}



  let addColumns =[
    { field:"BrandName",headerText:'Brand Name',width:'120px',textAlign:'left' ,headerTextAlign:'center',other:[{'editType':'dropdownedit',edit:{
      caption:'Brand' ,source:{type:"Route",dataSource:'Brand'} , field:{id:'BrandID',value:'BrandName'}, filter:{},
      // select:{source:{type:'Function',dataSource:{function:invoiceService.getMultiDropDownSource , params :[{type:'Value',source:'Stock'},{type:'Value',source:'0'},{type:'Value',source:'01-JUN-24'},{type:'Value',source:moment().format('DD-MMM-YY')},{type:'Column',source:'BrandID'}] , dropDownKey:'ProductID'}}},
    }
    }]},
    { field:"BrandID",headerText:'Brand ID',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:false}]},
    
    { field:"ProductName",headerText:'Product Name',width:props.Type == 'Purchase' ? '120px' : '400px',textAlign:'left' ,headerTextAlign:'center',other:props.Type == 'Purchase' ? [
      {'editType':'dropdownedit',edit:{
      caption:'Product' ,
      source:{type:"Route",dataSource:'Product'} , 
      // select:{source:{type:'Function',dataSource:{function:invoiceService.getUnitOfMeasurement , params :[{type:'Column',source:'ProductID'}] , dropDownKey:'UnitOfMeasurementID'}}},
      field:{id:'ProductID',value:'ProductName'}, 
      filter:{}}
    } 
  ] :
  [
    {
      // SET
       editTemplate:(props)=>{
        // console.log(saleProductNameDataSource)
        return(
          <div>
          <AutoCompleteComponent 
          // dataSource={saleProductNameDataSource}
          fields={ddlFields}
          name='ProductName'
          id='ProductName'
          ref={saleProduct}
          floatLabelType='Auto' 
          highlight={true}
          focus={()=>{if (saleProduct ) {setTimeout(() => {saleProduct.current.showPopup()}, 200)}}}
          itemTemplate={(data) => {
            console.log(data);
            return (
              <table className='template-container'>
                <tbody>
                  <tr className='template-row' >
                <td className='template-cell'>{data.ProductName}</td>
                <td className='template-cell' style={{textAlign:'right'}}>{data.BalQty.toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
         
            );
          }}
          headerTemplate={() => {
            return (
              <table className='template-container'>
        <thead>
        <tr className='template-row' style={{paddingRight:'38px'}}>
        <th className='template-cell' style={{textAlign:'center',color:'#5CC95E'}}>Product Name</th>
        <th className='template-cell' style={{textAlign:'center',color:'#5CC95E'}}>Bal Qty</th>
        </tr>
        </thead>
        </table>
            );
          }}
          /> 
          </div>
        )
      }
    }
  ]
},
    { field:"ProductID",headerText:'Product ID',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:false}]},
    
    { field:"InvoiceQuantity",headerText:'Invoice Qty',width:'110px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0','editType':'numericedit'}]},
    
    { field:"InvoiceRate",headerText:'Invoice Rate',width:'110px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n2','editType':'numericedit' ,visible:props.UserID  == 0  || props.Type == 'Sale' }]},
    
    { field:"InvoiceAmount",headerText:'Invoice Amount',width:'110px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0','allowEditing':false,visible:props.UserID  == 0 || props.Type == 'Sale'}]},
    
    { field:"UnitOfMeasurement",headerText:'U O M ',width:'120px',textAlign:'left' ,headerTextAlign:'center',other:[{allowEditing:false}]},
    { field:"UnitOfMeasurementID",headerText:'UnitOfMeasurement ID',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:false}]},
    
    { field:"WeightPerBale",headerText:'Weight Per Bale',width:'110px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n2','editType':'numericedit' ,visible:props.UserID  == 0 && props.Type == 'Purchase'}]},
    
    { field:"InvoiceWeight",headerText: 'Invoice Weight',width:'110px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n2','allowEditing':false ,visible:props.UserID  == 0  && props.Type == 'Purchase'}]},
    
    { field:"UnitOfMeasurementWeight",headerText:'U O M Weight',width:'120px',textAlign:'left' ,headerTextAlign:'center',other:[{'editType':'dropdownedit', visible : props.UserID  == 0 ,edit:{
      caption:'U O M' ,source:{type:"Route",dataSource:'UnitOfMeasurement'} , field:{id:'UnitOfMeasurementIDWeight',value:'UnitOfMeasurementWeight'}, filter:{}}
    }]},
    { field:"UnitOfMeasurementIDWeight",headerText:'Unit Of Measurement ID Weight',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:false}]},
    
  ]
  

  // var mainInvoiceID = undefined;
  // SET
  useEffect(()=>{
    if(props.isAdd == false){
      mainInvoiceID.current =  props.MainInvoiceID
      // setInputProps((prevValues) => ({
      //   ...prevValues,
      //   ['CallGridDataSource']: {...prevValues['CallGridDataSource'] ,value :true},    
      //   // ['CallGridDataSource']: {...prevValues['CallGridDataSource'] ,value :false}    
      // }));
      setInputProps((prevValues) => ({
        ...prevValues,
        ['CallGridDataSource']: {...prevValues['CallGridDataSource'] ,value :false},    
        // ['CallGridDataSource']: {...prevValues['CallGridDataSource'] ,value :false}    
      }));
    }
    if(props.Type ==  'Purchase' && props.isAdd == false){
      setTimeout(() => {
        console.log(props.TotalInvoiceCost)
        if( document.getElementById('CalculationValue')){

          document.getElementById('CalculationValue').value = props.TotalInvoiceCost.toLocaleString()
        }
      }, 2000);

    }
    
  

    
  },[])


  const calculationGenerate = (response ,  data) =>{
    if(props.Type == 'Purchase'){
    const recordsets = response.data.Recordsets.recordsets[0];


const totalInvoiceAmount = recordsets.reduce((sum, record) => {
  return sum + (record.InvoiceAmount || 0);
}, 0);
totalInvoiceAmountRef.current = totalInvoiceAmount || 0
console.log("Total Invoice Amount:", totalInvoiceAmount  + Number(data.AdjustmentAmountFC) +  Number(data.FreightAmountFC));
let adjustmentAmt = data.AdjustmentAmountFC == 0 ? 0 : Number(data.AdjustmentAmountFC)  
let freightAmt = data.FreightAmountFC == 0 ? 0 : Number(data.FreightAmountFC)  
let valueStroe =  totalInvoiceAmount  + adjustmentAmt + freightAmt; 
if(document.getElementById('CalculationValue')){
document.getElementById('CalculationValue').value =  valueStroe.toLocaleString()
}
      // return CalculationValue.current.Value = totalInvoiceAmount  + Number(data.AdjustmentAmountFC) +  Number(data.FreightAmountFC)
  }
  }
  // console.log(CalculationValue.current?.Value)
  const tooltipRef = useRef(null);

  const showTooltip = (target, message) => {
    if (tooltipRef.current) {
      tooltipRef.current.destroy(); // Destroy existing tooltip if any
    }
    tooltipRef.current = new TooltipComponent({
      isVisible:true,
      content: `<span class="error-tooltip">${message}</span>`,
      position: 'TopCenter',
    });
    tooltipRef.current.appendTo(target);
    tooltipRef.current.open(target);
  };

  const hideTooltip = () => {
    if (tooltipRef.current) {
      tooltipRef.current.close(); // Close the tooltip
      tooltipRef.current.destroy(); // Destroy the tooltip instance
      tooltipRef.current = null; // Reset the ref
    }
  };
  const addActionComplete = async (grid, args ,setDataSourceState ,getListService  , selectedRow , selectRowByID , startEdit )=>{
    
     console.log(grid)
      console.log(inputProps)
      console.log(getListService)
     
        if(args.requestType == 'add' || args.requestType == 'beginEdit')
        {
          document.querySelector('#InvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
          console.log(args)
          if(props.Type == 'Sale'){
            if(args.requestType == 'add' ){
              // document.getElementById('InvoiceDetailGridInvoiceWeight').value = 0
              document.getElementById('InvoiceDetailGridInvoiceQuantity').value = 0
              document.getElementById('InvoiceDetailGridInvoiceRate').value = 0
              document.getElementById('InvoiceDetailGridInvoiceAmount').value = 0
              // document.getElementById('InvoiceDetailGridWeightPerBale').value = 0
            }else{
              console.log(args);
              await getSaleProductNameDataSource(args.rowData.BrandID,args.rowData?.ProductID)

            }

            document.getElementById('InvoiceDetailGridBrandName').addEventListener('blur', async (e)=>{
              console.log(e)
              // SET
              if(args.requestType ==  'add'){
            await getSaleProductNameDataSource(args.rowData.BrandID,args.rowData?.ProductID)
          }
            })
            document.getElementById('InvoiceDetailGridInvoiceQuantity').addEventListener('focus', async (e)=>{
              // SET
              if(saleProduct.current.itemData.ProductID){
              const response = await invoiceService.getUnitOfMeasurement(saleProduct.current.itemData.ProductID);
              if(response.data && response.data.recordset){
                document.getElementById('InvoiceDetailGridUnitOfMeasurement').value = response.data.recordset[0].value;
                args.rowData.UnitOfMeasurement = response.data.recordset[0].value
                args.rowData.UnitOfMeasurementID = response.data.recordset[0].id
                args.rowData.ProductID =saleProduct.current.itemData.ProductID
                console.log(args)
              }
              }
            }) 
            // SET
            document.getElementById('InvoiceDetailGridInvoiceQuantity').addEventListener('blur', async (e)=>{
                  console.log(saleProduct)
                  const formattedNumber = parseFloat(document.getElementById('InvoiceDetailGridInvoiceQuantity').value.replace(/,/g, ''));
                  console.log(formattedNumber)
                  console.log(args)
                  if(formattedNumber > saleProduct.current.itemData.BalQty ){ 
                    console.log(e)
                    document.getElementById('InvoiceDetailGridInvoiceQuantity').focus()
                    showTooltip(e.target, `<span class="error-tooltip">Insufficient Stock</span>`);

                  }else{
                    hideTooltip();
                  }

                })
                const updateAmountQuantity = ()=>{
                
                let invoiceQty = parseInt(document.getElementById('InvoiceDetailGridInvoiceQuantity').value.replace(/,/g, ''))   
                let invoiceRate = parseFloat(document.getElementById('InvoiceDetailGridInvoiceRate').value.replace(/,/g, '')).toFixed(2)  
                let invoiceAmount = invoiceQty * invoiceRate;
                document.getElementById('InvoiceDetailGridInvoiceAmount').value = invoiceAmount.toLocaleString();
                args.rowData.InvoiceAmount = invoiceAmount
                
                }
                document.getElementById('InvoiceDetailGridInvoiceQuantity').addEventListener('keydown', updateAmountQuantity)
                document.getElementById('InvoiceDetailGridInvoiceRate').addEventListener('keydown', updateAmountQuantity)
                if(props.UserID != 0){
                  document.getElementById('InvoiceDetailGridInvoiceRate').addEventListener('blur', (e)=>{
                    console.log('blurred' ,grid)
                    console.log('blurred' ,e)
                 
                      setTimeout(() => document.getElementById('InvoiceDetailGridInvoiceRate') && grid?.endEdit(), 200);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth"  // Optional: adds smooth scrolling
                      });
                    
                
                  })
                }
          
          }
          if(props.Type ==  'Purchase'){
            if(args.requestType == 'add' ){
              if(props.UserID == 0){

                document.getElementById('InvoiceDetailGridInvoiceWeight').value = 0 ;
                document.getElementById('InvoiceDetailGridInvoiceRate').value = 0.00
                document.getElementById('InvoiceDetailGridInvoiceAmount').value = 0
                document.getElementById('InvoiceDetailGridWeightPerBale').value = 0
              }
            document.getElementById('InvoiceDetailGridInvoiceQuantity').value = 0
            }

            
            document.getElementById('InvoiceDetailGridProductName').addEventListener('blur', async (e)=>{
              if(args.rowData.ProductID){
              const response = await invoiceService.getUnitOfMeasurement(args.rowData.ProductID);
              if(response.data && response.data.recordset){
                document.getElementById('InvoiceDetailGridUnitOfMeasurement').value = response.data.recordset[0].value;
                args.rowData.UnitOfMeasurement = response.data.recordset[0].value
                args.rowData.UnitOfMeasurementID = response.data.recordset[0].id
                console.log(args)
                
              }
              }
            }) 
         
            const updateAmountQuantity = ()=>{
              if(props.UserID  == 0){
            let invoiceQty = parseInt(document.getElementById('InvoiceDetailGridInvoiceQuantity').value.replace(/,/g, ''))   
            let invoiceRate = parseFloat(document.getElementById('InvoiceDetailGridInvoiceRate').value.replace(/,/g, '')).toFixed(2)
            let invoiceWeightPerBale = parseFloat(document.getElementById('InvoiceDetailGridWeightPerBale').value.replace(/,/g, '')).toFixed(2)   
            let invoiceAmount = invoiceQty * invoiceRate;
            let invoiceWeightValue = invoiceQty * invoiceWeightPerBale;
            document.getElementById('InvoiceDetailGridInvoiceAmount').value = invoiceAmount.toLocaleString();
            document.getElementById('InvoiceDetailGridInvoiceWeight').value = invoiceWeightValue.toLocaleString();
            args.rowData.InvoiceAmount = invoiceAmount
            args.rowData.InvoiceWeight = invoiceWeightValue
          }
            }
            document.getElementById('InvoiceDetailGridInvoiceQuantity').addEventListener('keydown', updateAmountQuantity)
            if(props.UserID  == 0){

            document.getElementById('InvoiceDetailGridInvoiceRate').addEventListener('keydown', updateAmountQuantity)
            document.getElementById('InvoiceDetailGridWeightPerBale').addEventListener('keydown', updateAmountQuantity)
            }
           
            
            if(props.UserID != 0){
              console.log('blured',document.getElementById('InvoiceDetailGridInvoiceQuantity'))
              document.getElementById('InvoiceDetailGridInvoiceQuantity').addEventListener('blur', (e)=>{
                console.log('blurred' ,grid)
                console.log('blurred' ,e)
             
                  setTimeout(() => document.getElementById('InvoiceDetailGridInvoiceQuantity') && grid?.endEdit(), 200);
                 
                
              })
      
            }
        }
        if(props.UserID == 0){
          document.getElementById('InvoiceDetailGridUnitOfMeasurementWeight').value  = 'Lbs'
          args.rowData.UnitOfMeasurementWeight = 'Lbs'
          args.rowData.UnitOfMeasurementIDWeight = 2
          
          document.getElementById('InvoiceDetailGridUnitOfMeasurementWeight').addEventListener('blur', (e)=>{
            console.log('blurred' ,grid)
            console.log('blurred' ,e)
         
              setTimeout(() => document.getElementById('InvoiceDetailGridUnitOfMeasurementWeight') && grid?.endEdit(), 200);
              window.scrollTo({
                top: 100,
                behavior: "smooth"  // Optional: adds smooth scrolling
              });
            
          })
        }
      }
      if(args.requestType == 'save' || args.requestType == 'cancel'){
        hideTooltip()
        document.getElementById('InvoiceGrid_dialogEdit_wrapper_dialog-content').scrollTop = 260;

        setTimeout(()=>{document.querySelector('#InvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';},300)
      }
      if(args.requestType == 'save'){
        console.log(args)
        console.log(grid)

        setTimeout(()=>{
          console.log(grid)
          // let gridDataSource = {}
          // setDataSourceState((prevValues) => {
          //   console.log(prevValues);
          //   gridDataSource = prevValues
          //   return {...prevValues
          //   // ['MainInvoiceID']: {...prevValues['MainInvoiceID'] ,value :response.data.MainInvoiceID }    
          // }});
          // console.log(gridDataSource)
        },5000)
        let inputProp = {}
        setInputProps((prevValues) => {
          console.log(prevValues);
          inputProp = prevValues
          return {...prevValues
          // ['MainInvoiceID']: {...prevValues['MainInvoiceID'] ,value :response.data.MainInvoiceID }    
        }});

        function convertToMozambiqueTime(dateString) {
          // Parse the date string with the GMT offset
          // const gmtDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
          
          // // Convert the parsed date to Mozambique time zone (Africa/Maputo)
          // const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
          
          // return dateInMozambique;
          const gmtDate = moment(dateString, "DD-MMM-YY");

      // Convert the parsed date to Mozambique time zone (Africa/Maputo)
      const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
  
      return dateInMozambique;
      }
                let  data = {
          InvoiceID:inputProp.InvoiceID.value,
          InvoiceType:inputProp.InvoiceType.value,
          InvoiceDate:convertToMozambiqueTime(inputProp.InvoiceDate.value),
          InvoiceNo:inputProp.InvoiceNo.value,
          AccountID:inputProp.AccountTitle.value.id,
          AccountIDBill:inputProp.AccountIDBill.value,
          CurrencyID:	inputProp.CurrencyID.value,
          ShopID:props.ShopID,
          AdjustmentAmountFC: inputProp.AdjustmentAmountFC.value,
          FreightAmountFC:	inputProp.FreightAmountFC.value,
          Remarks:inputProp.Remarks.value,
          HasFinancialImpact:inputProp.HasFinancialImpact.value,

          //DETAIL
          MainInvoiceID:mainInvoiceID.current,
          BrandID:args.data.BrandID,
          ProductID:args.data.ProductID,
          InvoiceQuantity:	args.data.InvoiceQuantity || 0,
          InvoiceRate:	args.data.InvoiceRate || 0,
          InvoiceAmount:args.rowData.InvoiceAmount || 0,
          UnitOfMeasurementID:	args.data.UnitOfMeasurementID,
          WeightPerBale:	args.data.WeightPerBale || 0,
          InvoiceWeight:	args.rowData.InvoiceWeight || 0,
          UnitOfMeasurementIDWeight: 	args.data.UnitOfMeasurementIDWeight || 2,
          InvoiceDetailID:args.data?.InvoiceDetailID,
          params:{
            FromDate:'01-JAN-01' ,
            ToDate:moment().format('DD-MMM-YY'),
            MainInvoiceID: 'runtime' ,
            InvoiceID:0 ,
            InvoiceType: props.Type == 'Purchase' ? 'RI' : 'II',
            AccountID:0,
            CurrencyID:0,
            ShopID :0,
            BrandID:0,
            ProductID:0,
            InvoiceDetailID :0
          }

    }
      console.log(data)

      if(args.action == 'add'){
        
        const response = data.MainInvoiceID == undefined ? await invoiceService.addInvoice(data) : await invoiceService.addGridInvoice(data);
          console.log(response)
          mainInvoiceID.current = response.data.MainInvoiceID
          
            
            setInputProps((prevValues) => ({
              ...prevValues,
              ['MainInvoiceID']: {...prevValues['MainInvoiceID'] ,value :response.data.MainInvoiceID }    
            }));
            
          
          // if(inputProp.BrandName.value.id != 'undefined' && inputProp.BrandButton.isClicked == true){
            // console.log(inputProp.BrandName.value.id != 'undefined' && inputProp.BrandButton.isClicked == true)
          if(inputProp.BrandName.value.id != 'undefined' ){
            console.log(inputProp.BrandName.value.id != 'undefined')
            console.log('WITH SELECTED BRAND',args.rowIndex)
            mainInvoiceID.current = response.data.MainInvoiceID

            setDataSourceState(prevArray =>
              prevArray.map((obj, index) =>
                index == 0 ? { ...obj,IsEdit: true,
                  InvoiceDetailID : response.data.InvoiceDetailID, 
                  BrandName:args.data.BrandName,
                  ProductName:args.data.ProductName,
                  InvoiceQuantity:	args.data.InvoiceQuantity,
                  InvoiceRate:	args.data.InvoiceRate,
                  InvoiceAmount:data.InvoiceAmount,
                  UnitOfMeasurement:	args.data.UnitOfMeasurement,
                  WeightPerBale:	args.data.WeightPerBale,
                  InvoiceWeight:	data.InvoiceWeight,
                  UnitOfMeasurementWeight:	args.data.UnitOfMeasurementWeight,
                  } : obj
              )
            
            );
            
            document.getElementById('InvoiceGrid_dialogEdit_wrapper_dialog-content').scrollTop = 260;
          
            setTimeout(()=>document.getElementById('InvoiceDetailGrid_add').click(),1000)
            
            calculationGenerate(response, data)
            
          }else{
            console.log('WITH OUT SELECTED BRAND')
            console.log(response)
            // 
            //  response.data.Recordsets.recordsets[0]
            await setDataSourceState(response.data.Recordsets.recordsets[0])
            document.getElementById('InvoiceGrid_dialogEdit_wrapper_dialog-content').scrollTop = 260;

            setTimeout(async ()=>{
              if(response.data.Recordsets.recordsets[0].length > 0){
                document.getElementById('InvoiceDetailGrid_add').click()

              }
              },1000 )
              calculationGenerate(response, data)
              
          }
        }
        if(args.action == 'edit'){
        console.log(inputProp.BrandName.value.id != 'undefined' )
        if(inputProp.BrandName.value.id != 'undefined' ){
        if(args.data.IsEdit == false){
            console.log(mainInvoiceID.current)
            const response =mainInvoiceID.current == undefined ? await invoiceService.addInvoice(data) : await invoiceService.addGridInvoice({...data ,  MainInvoiceID : mainInvoiceID.current});
            console.log(response)
            mainInvoiceID.current = response.data.MainInvoiceID
            setInputProps((prevValues) => ({
              ...prevValues,
              ['MainInvoiceID']: {...prevValues['MainInvoiceID'] ,value :response.data.MainInvoiceID }    
            }));
            
            var gridLength =grid.currentViewData.length
            setDataSourceState(prevArray => {
              console.log(prevArray.length);
              gridLength = prevArray.length
              return prevArray.map((obj, index) => 
                index === args.rowIndex 
                  ? { 
                      ...obj,
                      IsEdit: true,
                      InvoiceDetailID: response.data.InvoiceDetailID,
                      BrandName: args.data.BrandName,
                      ProductName: args.data.ProductName,
                      InvoiceQuantity: data.InvoiceQuantity,
                      InvoiceRate: data.InvoiceRate,
                      InvoiceAmount: data.InvoiceAmount,
                      UnitOfMeasurement: args.data.UnitOfMeasurement,
                      WeightPerBale: args.data.WeightPerBale,
                      InvoiceWeight: data.InvoiceWeight,
                      UnitOfMeasurementWeight: args.data.UnitOfMeasurementWeight,
                    } 
                  : obj
              );
            });
            
            document.getElementById('InvoiceGrid_dialogEdit_wrapper_dialog-content').scrollTop = 260;
            setTimeout(() => {
              
              let  rowsInd = args.rowIndex +1;

            if(rowsInd < gridLength){
                
              //(args)
              selectRowByID(rowsInd)
              startEdit() 

            }else{
              if(props.isAdd){
                // productsDetailGrid.refresh()
              }
            }
             
             }, 500);
             calculationGenerate(response, data)
          
           }else{
            console.log(mainInvoiceID.current)
            const response = await invoiceService.editGridInvoice({...data ,  MainInvoiceID : mainInvoiceID.current});
            console.log(response)
            var gridLength =grid.currentViewData.length
            setDataSourceState(prevArray => {
              console.log(prevArray.length);
              gridLength = prevArray.length
              return prevArray.map((obj, index) => 
                index === args.rowIndex 
                  ? { 
                      ...obj,
                      IsEdit: true,
                      InvoiceDetailID: response.data.InvoiceDetailID,
                      BrandName: args.data.BrandName,
                      ProductName: args.data.ProductName,
                      InvoiceQuantity: data.InvoiceQuantity,
                      InvoiceRate: data.InvoiceRate,
                      InvoiceAmount: data.InvoiceAmount,
                      UnitOfMeasurement: args.data.UnitOfMeasurement,
                      WeightPerBale: args.data.WeightPerBale,
                      InvoiceWeight: data.InvoiceWeight,
                      UnitOfMeasurementWeight: args.data.UnitOfMeasurementWeight,
                    } 
                  : obj
              );
            });
            
            document.getElementById('InvoiceGrid_dialogEdit_wrapper_dialog-content').scrollTop = 260;
            setTimeout(() => {
              console.log(gridLength)
              let  rowsInd = args.rowIndex +1;

            if(rowsInd < gridLength){
                
              //(args)
              selectRowByID(rowsInd)
              startEdit() 

            }else{
              if(props.isAdd){
                // productsDetailGrid.refresh()
              }
            }
             
             }, 500);
             calculationGenerate(response, data)

           }
           
                   }else{
            const response = await invoiceService.editGridInvoice(data);
          console.log(response)
          // await getListService()
          await setDataSourceState(response.data.Recordsets.recordsets[0])
          // setTimeout(async ()=>{
          //   if(response.data.Recordsets.recordsets[0].length > 0){
          //     document.getElementById('InvoiceDetailGrid_add').click()

          //   }
          //   },1000 )
          document.getElementById('InvoiceGrid_dialogEdit_wrapper_dialog-content').scrollTop = 260;
          setTimeout(() => {
            console.log(response.data.Recordsets.recordsets[0].length)
            let  rowsInd = args.rowIndex +1;

          if(rowsInd < response.data.Recordsets.recordsets[0].length){
              
            //(args)
            selectRowByID(rowsInd)
            startEdit() 

          }else{
            if(props.isAdd){
              // productsDetailGrid.refresh()
            }
          }
           
           }, 500);
          calculationGenerate(response, data)
          // window.scrollTo({
          //   top: 0,
          //   behavior: "smooth"  // Optional: adds smooth scrolling
          //   });
          }
        }


      }
  }

  
  const callScratechGridDataSource = ()=>{
    let data = {
      FromDate:'01-JAN-01' ,
      ToDate:moment().format('DD-MMM-YY'),
      MainInvoiceID: inputProps.MainInvoiceID.value  ,
      InvoiceID:0 ,
      InvoiceType: props.Type == 'Purchase' ? 'RI' : 'II',
      AccountID:0,
      CurrencyID:0,
      ShopID :0,
      BrandID:0,
      ProductID:0,
      InvoiceDetailID :0
    }
    return invoiceService.getScratechGridDataSource(data);
  }
  


  return (
    <div onKeyDown={(e)=>e.stopPropagation()}>
  
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   
      mainProps={props} title={`Invoice ${props.Type} ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
      <IrtizaDynamicInputGrid
        Id='InvoiceDetail'
         
        
        ColumnsList={addColumns} 
        GridDataSource= {[
          {
            
        DataSourceType: inputProps.CallGridDataSource.value === true && inputProps.BrandButton.isClicked === true 
        ? 'Reset' : inputProps.BrandName.value.id !== 'undefined' 
        ? (inputProps.BrandButton.isClicked === true ? 'Function' : 'Rest') 
        : (inputProps.MainInvoiceID.value && inputProps.CallGridDataSource.value === true ? 'Function' : 'Rest'),
        DataSource: inputProps.BrandButton.isClicked == true &&  inputProps.BrandName.value.id != 'undefined' ? callGridDataSource :  callScratechGridDataSource,
        DataSourceSaveType:  inputProps.BrandButton.isClicked == true &&  inputProps.BrandName.value.id != 'undefined' ? 'push' : 'replace',
        CreateColumns:inputProps.BrandButton.isClicked == true ||  inputProps.BrandName.value.id != 'undefined' ? [{columnName:'IsEdit',columnValue:false}] :[],
        
         }]
  }
        DefaultSortedColumnName={'InvoiceDetailID'} 
      
        ListActionComplete={addActionComplete}


        // mainSection={inputProps}
        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
        AllowTemplate={false}
        ToolbarOption={['Add','Edit','Delete','Update','Cancel']}
        AllowGrouping={false}
        AllowExcelExport={false}
        AllowPdfExport={false} 
        ShowColumnChooser={false}
        AllowPaging={inputProps.BrandName.value.id == undefined ? true :false}
        AllowDataBound={false}
        ListSecurity={'ListSecurity'}
        AddSecurity={'InvoiceEvaluationList'}
        EditSecurity={'InvoiceEvaluationList'}
        DeleteSecurity={'InvoiceEvaluationList'}
        DefaultSortedColumnSort={'Descending'}
        FilterColumns={[]} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
        GroupColumns={[]} 
        ListDelete={[]}
        DeleteType={'hard'} 
        GridEditSettingMode={'Normal'}
        GridEditSettingHeaderTemplate={'<p></p>'}
        GridSumColumns={['InvoiceWeight','InvoiceQuantity','InvoiceAmount']}
        footerSumCaption={[
          {ColumnName:'InvoiceQuantity - sum',Caption:'Qty'},
          {ColumnName:'InvoiceAmount - sum',Caption:props.Type == `Purchase` ? `Amount $` : `Amount`},
          {ColumnName:'InvoiceWeight - sum',Caption:props.Type == 'Purchase' ? 'Lbs'   : 'Sum'}
        ]}
        
        AllowGridSum={true}
            {...props}
            ></IrtizaDynamicInputGrid>
  {/* {
props.Type == 'Purchase' ?
    (<table>
              <thead>
                <tr>
                <th>TOTAL COST</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td>
                  {CalculationValue.current?.Value ? CalculationValue.current.Value.toLocaleString() : 0}
                </td>
                </tr>
              </tbody>
            </table> ): (<></>) 
            } */}
   {
props.Type == 'Purchase'  && props.UserID == 0 ?
    <div className={`col-sm-${4}`} style={{marginTop:'15px'}}>
      
            <TextBoxComponent
            name='CalculationValue'
            id='CalculationValue'
            placeholder= 'TOTAL COST'
            floatLabelType='Always'
            disabled={true}
             value={0}
             enabled={false}
            //  type='number'
             
             />
             </div> : (<></>)}
    </div>
  );
}; 

export default InvoiceDetail;
