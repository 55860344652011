import React, { useRef, useState , useEffect,useMemo} from 'react';
import moment from 'moment';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../dynamic-input/dynamic-main-inputs';
import IrtizaDynamicInputGrid from '../dynamic-input/dynamic-input-grid';

import shopTransferService from './shop-transfer.service';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import DropdownService from '../dynamic-input/dropdown-api.service';

const ShopTransferAdd = (props) => {
  
  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};
// MainTransferID	
// ShopTransferID	
// TransferDate	
// FromShopID	
// ToShopID
  
  const [inputProps, setInputProps] = useState({
  
    CallGridDataSource:{name: 'CallGridDataSource',   value: props.isAdd == false},
    MainTransferID:{name: 'MainTransferID',   value: props.MainTransferID || undefined },

    ShopTransferID:{name: 'ShopTransferID',   value: props.ShopTransferID || '' ,enabled:false},
    
    TransferDate:{name: 'TransferDate',   value: props.TransferDate || 'currentDate' },

    FromShopName:{
      name:{id:'FromShopID',value:'FromShopName'},
      caption: 'Select From Shop',
      value: {id: props.FromShopID || props.ShopID ,value:props.FromShopName || ''},
      dataSource: {type:'Route',route:'Shop'},
      enabled:false
        },
   
      ToShopName:{
      name:{id:'ToShopID',value:'ToShopName'},
      caption: 'Select To Shop',
      value: {id: props.ToShopID || 'undefined' ,value:props.ToShopName || ''},
      dataSource: {type:'Route',route:'Shop'}},
      
      });
// EXTRA CODE START
const getShopTransferIDValue = async ()=>{
    try {
      const response =   await shopTransferService.getShopTransferIDValue(props.ShopID) ;
      if (response.data || response.data.recordset) {
        console.log(response)
        setInputProps((prevValues) => ({
          ...prevValues,
          ['ShopTransferID']: {...prevValues['ShopTransferID'] ,value :response.data.recordset[0]?.ShopTransferID || '' }
    
        }));
      }
        else {
        console.error("Invalid response structure:", response);
      }
     } catch (error) {
      }
  }
// EXTRA CODE END

const handleInputCreated = async (e, name) => {
  if(name == 'ShopTransferID'  && props.isAdd  == true){
    return await getShopTransferIDValue()
  }
  console.log(e);
    console.log(`Input ${name} changed to ${e}`);
  };
const handleInputFocus = async (e, name) => {
  
    console.log(e);
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {
  
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
  if(name == 'ToShopName'){
    setTimeout(()=>document.getElementById('ShopTransferDetailGrid_add').click(),700)

  }
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {Input: HiddenComponent ,Props: {...inputProps.MainTransferID, setInputProps },ColumnSize:0},

    {Input: props.isAdd == true ? HiddenComponent :  TextComponent,Props: {...inputProps.ShopTransferID, onCreated:handleInputCreated,setInputProps },ColumnSize:props.isAdd == true ?0 :4},

    {Input: DateComponent,Props: {...inputProps.TransferDate, setInputProps }},

    {Input: DropdownComponent,Props: {...inputProps.FromShopName, setInputProps },},
    
    {Input: DropdownComponent,Props: {...inputProps.ToShopName, onChange: handleInputChange,onBlur: handleInputBlur,setInputProps },},
    
  ];
// Detail Section Start
// MainTransferID	
// BrandID	
// ProductID	
// TransferQty	
// UnitOfMeasurementID	
// ShopTransferDetailID

let ProductNameRef = useRef(null)

let addColumns =[
  { field:"BrandName",headerText:'Brand Name',width:'120px',textAlign:'left' ,headerTextAlign:'center',other:[{'editType':'dropdownedit',edit:{
    caption:'Brand' ,source:{type:"Route",dataSource:'Brand'} , field:{id:'BrandID',value:'BrandName'}, filter:{},
    // select:{source:{type:'Function',dataSource:{function:shopTransferService.getMultiDropDownSource , params :[{type:'Value',source:'Stock'},{type:'Value',source:'0'},{type:'Value',source:'01-JUN-24'},{type:'Value',source:moment().format('DD-MMM-YY')},{type:'Column',source:'BrandID'}] , dropDownKey:'ProductID'}}},
  }
  }]},
  { field:"BrandID",headerText:'Brand ID',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:false}]},
  
  { field:"ProductName",headerText:'Product Name',width:'400px' ,textAlign:'left' ,headerTextAlign:'center',other: [
  //   {'editType':'dropdownedit',edit:{
  //   caption:'Product' ,
  //   source:{type:"Route",dataSource:'Product'} , 
  //   // select:{source:{type:'Function',dataSource:{function:shopTransferService.getUnitOfMeasurement , params :[{type:'Column',source:'ProductID'}] , dropDownKey:'UnitOfMeasurementID'}}},
  //   field:{id:'ProductID',value:'ProductName'}, 
  //   filter:{}}
  // } 
  {
     editTemplate:(props)=>{
        // console.log(saleProductNameDataSource)
        const ddlFields: object = { value: 'value' };
     
     
      // setTimeout(() => {
        
        
      //   const autoCompleteElement = ProductNameRef.current;
      //         const dropdownElement = autoCompleteElement.element;
      
      //         const handleKeydown = () => {
      //             setTimeout(() => {
      //                 const activeItem = dropdownElement.querySelector('.e-list-item.e-active');
      //                 if (activeItem) {
      //                     activeItem.style.backgroundColor = 'gray';
      //                 }
      //             }, 0);
      //         };
      
      //         dropdownElement.addEventListener('keydown', handleKeydown);
      // }, 1000);
      
             
          

        return(
          <div>
          
<AutoCompleteComponent 
  // dataSource={multiDropDownSource}
  fields={ddlFields}
  name='ProductName'

  id='ProductName'
  ref={ProductNameRef}
  floatLabelType='Auto' 
  highlight={true}
  focus={()=>{if (ProductNameRef ) {setTimeout(() => {ProductNameRef.current.showPopup()}, 200)}}}
  
  itemTemplate={(data) => {
    console.log(data);
    return (
      <table className='template-container'>
        <tbody>
          {/* <tr className='template-row' onKeyDown={ColorBox}> */}
          <tr className='template-row'>
        <td className='template-cell'>{data.ProductName}</td>
        <td className='template-cell' style={{textAlign:'right'}}>{data.BalQty.toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
 
    );
  }}
  headerTemplate={() => {
    return (
      <table className='template-container'>
<thead>
<tr className='template-row' style={{paddingRight:'38px'}}>
<th className='template-cell' style={{textAlign:'center',color:'#5CC95E'}}>Product Name</th>
<th className='template-cell' style={{textAlign:'center',color:'#5CC95E'}}>Bal Qty</th>
</tr>
</thead>
</table>
    );
  }}
/>
          </div>
        )
      }
  }
] },
{ field:"TransferQty",headerText:'Transfer Qty',width:'130px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0','editType':'numericedit'}]},

{ field:"UnitOfMeasurement",headerText:'U O M ',width:'120px',textlign:'left' ,headerTextAlign:'center',other:[{allowEditing:false}]},
{ field:"UnitOfMeasurementID",headerText:'UnitOfMeasurement ID',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:false}]}
]
const callGridDataSource = (id)=>{
  let data ={
    FromDate:'01-JAN-01' ,
    ToDate:moment().format('DD-MMM-YY'),
      MainTransferID:props.MainTransferID ? props.MainTransferID :   id,
      ShopTransferID:0,
      FromShopID:0,
      ToShopID:0,
      BrandID:0,
      ProductID:0,
      ShopTransferDetailIDint:0
  }
  return shopTransferService.getGridDataSource(data)
}
// EDIT TEMPLATE CODE HERE START
//8*********************************
//8*********************************
//8*********************************
//8*********************************
//8*********************************
//8*********************************


const getSaleProductNameDataSource =  async (id,productID)=>{
  try {
    console.log(productID)
    let response =  await shopTransferService.getMultiDropDownSource(`Stock ,0, 01-JUN-24 ,  ${moment().format('DD-MMM-YY')},${id},0,${props.ShopID}`)
      if(response.data  && response.data.recordset){
        
        console.log(response.data.recordset)
      ProductNameRef.current.dataSource = response.data.recordset
      if(productID){
      let value = response.data.recordset.filter(res => res.ProductID == productID)
      console.log(value)
      ProductNameRef.current.itemData = {...value[0] ,value : value[0].value , id :value[0].id , BalQty:value[0].BalQty }
      document.getElementById('ProductName').value = value[0].value
      }
    }
    
  } catch (error) {
    console.log(error)
  }  
}

useEffect(()=>{
if(props.isAdd == false){
  setInputProps((prevValues) => ({
    ...prevValues,
    ['CallGridDataSource']: {...prevValues['CallGridDataSource'] ,value :false}    
  }));
}
},[])


const tooltipRef = useRef(null);

  const showTooltip = (target, message) => {
    if (tooltipRef.current) {
      tooltipRef.current.destroy(); // Destroy existing tooltip if any
    }
    tooltipRef.current = new TooltipComponent({
      content: `<span class="error-tooltip">${message}</span>`,
      position: 'TopCenter',
    });
    tooltipRef.current.appendTo(target);
    tooltipRef.current.open(target);
  };

  const hideTooltip = () => {
    if (tooltipRef.current) {
      tooltipRef.current.close(); // Close the tooltip
      tooltipRef.current.destroy(); // Destroy the tooltip instance
      tooltipRef.current = null; // Reset the ref
    }
  };
const addActionComplete = async (grid, args ,setDataSourceState ,getListService  , selectedRow   )=>{
    
  console.log(args)
  console.log(grid)
  if(args.requestType == 'add' || args.requestType == 'beginEdit'){
  if(args.requestType == 'add' ){
    document.getElementById('ShopTransferDetailGridTransferQty').value = 0
    }else{
      await getSaleProductNameDataSource(args.rowData.BrandID,args.rowData?.ProductID)

    }
    document.getElementById('ShopTransferDetailGridBrandName').addEventListener('blur', async (e)=>{
      
      if(args.rowData.BrandID && args.requestType == 'add' ){
      await getSaleProductNameDataSource(args.rowData.BrandID,args.rowData?.ProductID)
      }
    })
    document.getElementById('ShopTransferDetailGridTransferQty').addEventListener('focus', async (e)=>{
      // SET
      console.log(ProductNameRef.current)
      if(ProductNameRef.current.itemData.id){
      const response = await shopTransferService.getUnitOfMeasurement(ProductNameRef.current.itemData.id);
      if(response.data && response.data.recordset){
        document.getElementById('ShopTransferDetailGridUnitOfMeasurement').value = response.data.recordset[0].value;
        args.rowData.UnitOfMeasurement = response.data.recordset[0].value
        args.rowData.UnitOfMeasurementID = response.data.recordset[0].id
        args.rowData.ProductID = ProductNameRef.current.itemData.id
        args.rowData.ProductName = ProductNameRef.current.itemData.ProductName
        console.log(args)
        
      }
    }
  }) 
  document.getElementById('ShopTransferDetailGridTransferQty').addEventListener('blur', async (e)=>{
    const formattedNumber = parseFloat(document.getElementById('ShopTransferDetailGridTransferQty').value.replace(/,/g, ''));
    console.log(formattedNumber)
      console.log(ProductNameRef.current.itemData.BalQty)
      if(formattedNumber > ProductNameRef.current.itemData.BalQty ){
        console.log('max')
        document.getElementById('ShopTransferDetailGridTransferQty').focus()
        showTooltip(e.target, `<span class="error-tooltip">Insufficient Stock</span>`);

      }else{
        hideTooltip();

    setTimeout(() => document.getElementById('ShopTransferDetailGridTransferQty') && grid?.endEdit(), 200);
      }
  })
  }
  
  console.log(inputProps)
  
  
  console.log(args)
  console.log(selectedRow)
  if(args.requestType == 'save'){
    hideTooltip()

    let inputProp = {}
        setInputProps((prevValues) => {
          console.log(prevValues);
          inputProp = prevValues
          return {...prevValues
          // ['MainInvoiceID']: {...prevValues['MainInvoiceID'] ,value :response.data.MainInvoiceID }    
        }});
    console.log(inputProp)
    function convertToMozambiqueTime(dateString) {
      // Parse the date string with the GMT offset
      // const gmtDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
      
      // // Convert the parsed date to Mozambique time zone (Africa/Maputo)
      // const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
      
      // return dateInMozambique;

      const gmtDate = moment(dateString, "DD-MMM-YY");

      // Convert the parsed date to Mozambique time zone (Africa/Maputo)
      const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
  
      return dateInMozambique;
  }
    let  data = {
      MainTransferID:inputProp.MainTransferID.value,	
      ShopTransferID:inputProp.ShopTransferID.value,
      TransferDate:convertToMozambiqueTime(inputProp.TransferDate.value),
      FromShopID:inputProp.FromShopName.value.id,
      ToShopID:inputProp.ToShopName.value.id,
      
      //DETAIL
      BrandID:args.data.BrandID,
      ProductID:args.data.ProductID,
      TransferQty:args.data.TransferQty,
      UnitOfMeasurementID:args.data.UnitOfMeasurementID,
      ShopTransferDetailID:args.data?.ShopTransferDetailID,
      params:{
        FromDate:'01-JAN-01' ,
      ToDate:moment().format('DD-MMM-YY'),
      MainTransferID:'runtime',
      ShopTransferID:0,
      FromShopID:0, 
      ToShopID:0,
      BrandID:0,
      ProductID:0,
      ShopTransferDetailIDint:0
      } 
}
if(args.action == 'add'){
  const response = data.MainTransferID == undefined ? await shopTransferService.addShopTransfer(data) : await shopTransferService.addGridShopTransfer(data);
          console.log(response)
          setInputProps((prevValues) => ({
            ...prevValues,
            ['MainTransferID']: {...prevValues['MainTransferID'] ,value :response.data.MainTransferID }    
          }));
          await setDataSourceState(response.data.Recordsets.recordsets[0])

          setTimeout(()=>document.getElementById('ShopTransferDetailGrid_add').click(),500)

}
if(args.action == 'edit'){
  const response = await shopTransferService.editGridShopTransfer(data);
          console.log(response)
          await setDataSourceState(response.data.Recordsets.recordsets[0])

}
  console.log(data)

    console.log(inputProps)
  }
}



// Usage in your main component



  return (
    <div onKeyDown={(e)=>e.stopPropagation()}>
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   mainProps={props} title={`Shop Transfer ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
      <IrtizaDynamicInputGrid
        Id='ShopTransferDetail'
        
        ColumnsList={addColumns}
        GridDataSource= {[
          {
        DataSourceType:inputProps.CallGridDataSource.value ? 'Function' : 'REST',
        DataSource:  ()=>  callGridDataSource(inputProps.MainTransferID.value) ,
        // CreateColumns: [{columnName:'IsEdit',columnValue:false}]
         }]
  }
        DefaultSortedColumnName={'ShopTransferDetailID'}
      
        ListActionComplete={addActionComplete}

        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
        AllowTemplate={false}
        ToolbarOption={['Add','Edit','Delete','Update','Cancel']}
        AllowGrouping={false}
        AllowExcelExport={false}
        AllowPdfExport={false} 
        ShowColumnChooser={false}
        AllowPaging={true}
        AllowDataBound={false}
        ListSecurity={'ListSecurity'}
        AddSecurity={'ShopTransferList'}
        EditSecurity={'ShopTransferList'}
        DeleteSecurity={'ShopTransferList'}
        DefaultSortedColumnSort={'Descending'}
        FilterColumns={[]} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
        GroupColumns={[]} 
        ListDelete={[]}
        DeleteType={'hard'} 
        GridEditSettingMode={'Normal'}
        GridEditSettingHeaderTemplate={'<p></p>'}
        GridSumColumns={[]}
        AllowGridSum={true}
        footerSumCaption={[{
          ColumnName:'TransferQty - sum',
          Caption:'Qty'
        }]}
            {...props}
            ></IrtizaDynamicInputGrid>
    </div>
  );
}; 

export default ShopTransferAdd;
