import React, { useEffect, useRef,  useState,useMemo } from 'react';

import SecurityService from '../security/security.service';
import HardDelete from '../hard-delete/hard-delete';
import AuditTableInput from '../audit-table/audit-table';
import TemplateCode from '../template-code/template-code';
import { useDispatch, useSelector } from 'react-redux';
import { gridColumnsList } from '../../store/slices/list-tempate'
import {
    GridComponent,
    Inject,
    Toolbar,
    ToolbarItems,
    Edit,
    EditSettingsModel,
    Page,
    Sort,
    PdfExport,
    PdfExportProperties,
    ExcelExport,
    Grid,
    Resize,
    FilterSettingsModel,
    Filter,
    Group,
    GroupSettingsModel,
    AggregateColumnDirective,
    ColumnDirective,
    ColumnsDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    Aggregate,
    Freeze,
    ColumnChooser,
    Reorder,
    DialogEditEventArgs,
    CommandColumn,
    CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
// import AccessoryTransferAdd from './full-yarn-count-add';
import CommonService from '../../services/common.service';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import ChartOfAccount from './chart-of-account';
// import AccessoryPurchaseTransactionsReturnList from './accessory-purchase-transaction-return-list';
import moment from 'moment';
import chartOfAccountService from '../../services/chart-of-account.service';

let selectedRows = []
export default function AccessoryTransferList(props) {
    const [accessoryTransferForDataList, setAccessoryTransferForDataList] = useState([]);
    let chartOfAccountGrid: Grid;
    // const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
    // const [dialogWarningVisibility, setDialogWarningVisibility] = useState(false);
    // const [warningMessage, setWarningMessage] = useState('');
    // const deleteDialog = useRef();
    // const warningDialog = useRef();
    // Default Column name and its detail
    let ColumnProps = 
    [

      {  field: "AccountID",headerText:'ID',width:'90px',textAlign:'right' ,headerTextAlign:'center',other:[]},
      {  field: "AccountCode",headerText:'Account Code',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
      {  field: "AccountTitle",headerText:'Account Title',width:'200px',textAlign:'left' ,headerTextAlign:'center',other:[]},
      {  field: "Transactional",headerText:'Trans',width:'110px',textAlign:'right' ,headerTextAlign:'center',other:[]},
      {  field: "Inactive",headerText:'In active',width:'110px',textAlign:'right' ,headerTextAlign:'center',other:[]},
      {  field: "AccountType",headerText:'Acc. Type',width:'130px',textAlign:'right' ,headerTextAlign:'center',other:[]},
      {  field: "OpeningBalance",headerText:'Opening Balance',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
      {  field: "CreditDays",headerText:'Credit Days',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0',visible:false}]},
      {  field: "CreditAmountLimit",headerText:'Credit Amount Limit',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0',visible:false}]},
      {  field: "ContactPerson",headerText:'Contact Person',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{visible:false}]},
      {  field: "OfficeAddress",headerText:'Office Address',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{visible:false}]},
      {  field: "LandlineNumber",headerText:'Landline Number',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{visible:false}]},
      {  field: "CellNumber",headerText:'Cell Number',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{visible:false}]},
      {  field: "AccountCategory",headerText:'Account Category',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{visible:false}]},
      {  field: "NTN",headerText:'NTN',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0',visible:false}]},
      {  field: "STRN",headerText:'STRN',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0',visible:false}]},
      {  field: "EmailAddress",headerText:'Email Address',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{visible:false}]},
      {  field: "Aging",headerText:'Aging',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{visible:false}]},
    ]
  
    const [showDialogs, setShowDialogs] = useState();
    const [deletingProps, setDeletingProps] = useState();
    const [deletingID, setDeletingID] = useState(0);
    let InputListSecurity ='ChartOfAccountList'
    let defaultSortColumnName = 'AccountID'
    let defaultSortColumnType = 'Descending'
    let InputNameForPdfExcelExport = 'Chart Of Account - LIST'
    let exportFileName = 'Chart_Of_Account'
    // static code start ************************************************************************************************************
    // ***************** ************************************************************************************************************
        //  DYNAMIC CODE 
      const dispatch = useDispatch()
      const reduxData = useSelector(state =>{return state.listTemplate})
      var filterColumnName = []
      var recordColumnName = []
      var fiterCheckedValue = []
      const [dataSourceState, setDataSourceState] = useState([]);
      
    useEffect(() => {
      dispatch(gridColumnsList(ColumnProps))
      setTimeout(() => {
        console.log(reduxData)
      }, 1000);
    }, []);
    const [isToggled, setIsToggled] = useState(false);
    const [ToggledValue, setToggledValue] = useState( 'Hide Template');
    useEffect(() => {
      setTimeout(() => {
        setToggledValue(isToggled == true? 'Hide Template' : 'Show Template')
      }, 100);
    }, [isToggled]);
    const toolbarClick = (args: ClickEventArgs) => {
      let reportName = document.getElementById('reportName')?.value;
      let getOrientation = document.getElementsByClassName('orientation')
      let selectedOrientation = getOrientation[0]?.checked ==  true ? 'Portrait':'Landscape'
      let getHeaderCheck = document.getElementsByClassName('headerCheck')[0]?.checked;
      let headerCheck =  !getHeaderCheck
      if ( chartOfAccountGrid && args.item.properties.id === 'chartOfAccountGrid_PDFExport' ) {
        console.log(filterColumnName)
        console.log(fiterCheckedValue)
         if(headerCheck == true){
          let ress = filterColumnName.map((res, index) => {
            // Convert camelCase to Title Case and uppercase all letters
            let title = res.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
            // Calculate position dynamically
            let position = { x: 10, y: 120 + (index * 40) };
            // Define style properties
            let style = { textBrushColor: "#000000", fontSize: 12, hAlign: 'Center' };
            // Join checked values with separator '|'
            let checkedValues = fiterCheckedValue[index].join(' | ');
            
            return {
                position: position,
                style: style,
                type: "Text",
                value: `${title}: ${checkedValues},`
            };
        });
        
    const exportProperties: PdfExportProperties = {
            header: { 
            contents: [ 
              // {
              //   position: { x: 10, y: 30 },
              //   size: { width: 120, height: 80 }, // Set the width and height of the image
              //   type: "Image",
              //   src:''
              // },
              { 
                position: { x:selectedOrientation == 'Landscape' ?420:260 , y: 50 }, 
                style: { textBrushColor: "#000000", fontSize: 20, hAlign:'Center' }, 
                type: "Text", 
                value: `DALAL INDUSTRIES`
                
              },
              { 
                position: { x:selectedOrientation == 'Landscape' ?410:270 , y: 80 }, 
                style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
                type: "Text", 
                value: reportName.toUpperCase() ? reportName.toUpperCase() : InputNameForPdfExcelExport
                },  
              ...ress
            
            ]
             ,
             fromTop: 0, 
              height: 230 
          },
           footer: {
                      contents: [
                          {
                              format: 'Page {$current} of {$total}',
                              pageNumberType: 'Northwind Traders',
                              position: { x: selectedOrientation == 'Landscape' ?930:600, y: 25 },
                              style: {
                                  fontSize: 16,
                                  textBrushColor: '#000000'
                                },
                                type: 'PageNumber'
                              },
                              { 
                                position: { x:selectedOrientation == 'Landscape' ?20:10 , y: 25 }, 
                                style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
                                type: "Text", 
                                value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}`
                              },
                              { 
                                position: { x:selectedOrientation == 'Landscape' ?420:320 , y: 25 }, 
                                style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
                                type: "Text", 
                                value: `Printed By: ${reduxData.printedByUserName}`
                                }
                            ],
                            fromBottom: 20,
                            height: 60
                  },
          pageOrientation: selectedOrientation,
          fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.pdf`
        };
        console.log('clicked on pdf export',selectedOrientation);
        setTimeout(() => {
          
          return chartOfAccountGrid.pdfExport(exportProperties); 
        }, 500);
  }else{
    const withOutHeaderexportProperties: PdfExportProperties = {
      pageOrientation: selectedOrientation,
      fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.pdf`
    };
    chartOfAccountGrid.pdfExport(withOutHeaderexportProperties);
  }
      
      }
      if (
        chartOfAccountGrid &&
        args.item.properties.id === 'chartOfAccountGrid_excelexport'
      ) {
        if(headerCheck == true){
        let excelress =   filterColumnName.map((res,ind)=>{
          let title = res.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()
          // let len = fiterCheckedValue.length()
         return { cells: [{ colSpan: 6, value: `${ title} :  ${fiterCheckedValue[ind].join(',')}` , style: {  fontSize: 12,hAlign: 'Left', }} ]}
        
        })
        const exportProperties: ExcelExportProperties = {
          
          pageOrientation: 'Landscape',
          fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.xlsx`,
          footer: {
            footerRows: 2,
            rows: [
                { cells: [{ colSpan: 4, value: `Printed By: ${reduxData.printedByUserName}`, style: { hAlign: 'Center', bold: true } }] },
                
            ]
        },
        header: {
            headerRows: 4 + fiterCheckedValue.length,
            rows: [
                { cells: [{ colSpan: 2, value: "", style: {  fontSize: 20, hAlign: 'Center', bold: true, } }] },
                { cells: [{ colSpan: 4, value: "DALAL INDUSTRIES", style: {  fontSize: 25, hAlign: 'Center', bold: true, } }] },
                { cells: [{ colSpan: 4, value:reportName.toUpperCase() ? reportName.toUpperCase():'PRODUCT FINISH SIZE - LIST', style: { fontSize: 15, hAlign: 'Center', bold: true, } }] },
                { cells: [{ colSpan: 4, value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}` , style: {  fontSize: 12,hAlign: 'Right' } }] },
                ...excelress
            ]
        },
  
        };
        chartOfAccountGrid.excelExport(exportProperties);
       }else{
        const exportProperties: PdfExportProperties = {
          pageOrientation: selectedOrientation,
          fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.xlsx`
        };
        chartOfAccountGrid.excelExport(exportProperties);
      }
      }
      if (
        chartOfAccountGrid &&
        args.item.properties.id === 'chartOfAccountGrid_columnchooser'
      ) {
      }
  
      // static code end
      if (chartOfAccountGrid && args.item.properties.id === 'Delete') {
        console.log('toolbar Delete');
        if (chartOfAccountGrid.selectedRowIndex === -1) {
          // no rows selected
          console.log('Delete ', chartOfAccountGrid.getSelectedRecords()[0]);
          // setDialogWarningVisibility(false);
          let obj={
            TableName:`ChartOfAccount`,
            ColumnName:`ChartOfAccountID`,
            RowID:undefined,
            SelectedRow:false
             }
          setDeletingProps(obj)
          setShowDialogs(true)
         
        } else {
          console.log('delete: ', chartOfAccountGrid.getSelectedRecords()[0]?.ChartOfAccountID)
          // deleteDialog.current.hide();
          let obj={
            TableName:`ChartOfAccount`,
            ColumnName:`AccountID`,
            RowID:chartOfAccountGrid.getSelectedRecords()[0]['AccountID'],
            SelectedRow:true,
            SelectedRowData:{
              AccountCode: chartOfAccountGrid.getSelectedRecords()[0].AccountCode,
              AccountTitleShort:  chartOfAccountGrid.getSelectedRecords()[0].AccountTitleShort,
            
            
              
            }
          } 
          setDeletingProps(obj)
          setShowDialogs(true)
        }
    }
    };

    useEffect(() => {
        getListService();
        // localStorage.removeItem('gridAccessoryDisplayGrid');
    }, []);

    const getListService = async (type: string) => {
        chartOfAccountService.getList()
            .then((response) => {
              setAccessoryTransferForDataList(response.data.recordset)
              let data = response.data.recordset.map((v)=>{
                v.Transactional = v.Transactional == true ? 'YES': 'NO'
                v.Inactive = v.Inactive == true ? 'YES': 'NO'
                v.Aging = v.Aging == true ? 'YES': 'NO'
                return v
              })
                setDataSourceState( data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const toolbarOptions: ToolbarItems[] = [
         'Add',
  //  'Edit',
    // {
    //   text: "Add" , tooltipText: "View", id: "Add", disabled:! SecurityService.canUserAccess('AccessoryTransferAdd')
    // },
    // 'Delete',
        'Update',
        'Cancel',
        'PDFExport',
        'ExcelExport',
        'Search',
        'ColumnChooser',
    ];

    const dialogTemplate = (props) => {
        console.log('props: ', props);
        return props.isAdd ? (
            
            <ChartOfAccount dataList={accessoryTransferForDataList} {...props} > </ChartOfAccount>
        ) :
            (
                <ChartOfAccount dataList={accessoryTransferForDataList} {...props} > </ChartOfAccount>
            );
    };



    // const footerClickHandler = () =>{
    //   console.log('irtiza')
    // }
    
    // useEffect(() => {
    //   setTimeout(() => {
    //     console.log('ddd')
    //     const elements = document.getElementById('EXIT-POPUP');
        
    //     if (elements) {
    //     elements.addEventListener('click', footerClickHandler);
    //     // elements.addEventListener('click', footerClickHandler);
    //   }
    // }, 2000);
    // }, [editSettings.footerTemplate]);
    const editSettings: EditSettingsModel = {
        allowEditing:false,
        
        allowAdding: SecurityService.canUserAccess('ChartOfAccountAdd'),
        allowDeleting: false,
        mode: 'Dialog',
        showDeleteConfirmDialog: true,
        template: dialogTemplate,
        headerTemplate: '<p></p>',
        footerTemplate: '<p></p>',
    };
    // editSettings.footerTemplate = `<button id="EXIT-POPUP" >Exit</button>`;
    const filterOptions: FilterSettingsModel = {
        type: 'Excel',
        // columns: [
        //     {
        //         field: 'IsDeleted',
        //         operator: 'notequal',
        //         value: 'True'
        //     }
        // ]
    };

    const groupOptions: GroupSettingsModel = {
        showDroopArea: true,
    };

    const sortingOptions: SortSettingsModel = {
        columns: [{ field: 'AccountID', direction: 'Descending' }]
    }

    const footerSum = (e: any) => {
        return <span> Sum: {e.Sum}</span>;
    };

    const userId = SecurityService.getLoggedInUserId();

    var visibleSortPassFields = useRef([defaultSortColumnName])
    var visibleSortPass = useRef([defaultSortColumnType])
    var ctrlPressed =  useRef(false)
    var groupingArray = []
    const actionComplete = (args: DialogEditEventArgs) => {
      if(args.requestType == "grouping"){
        groupingArray.push(args.columnName)
      }
      if(args.requestType == "ungrouping"){
        groupingArray.splice(groupingArray.indexOf(args.columnName),1)
      }
      ctrlPressed.current = false;
      let handleStore = (e) => {
        e.stopPropagation();
        return  ctrlPressed.current = e.ctrlKey; // Use e.ctrlKey, not e.ctrlkey
      };
      document.addEventListener('keydown', handleStore);
      setTimeout(() => {
        if(args.requestType ==  'sorting'){
          console.log(ctrlPressed.current)
          setTimeout(() => {
            if(ctrlPressed.current == true ){
              console.log(ctrlPressed.current)
              if(args.columnName == visibleSortPassFields.current[0] && visibleSortPassFields.current.length == 1){
              }else{
                if(visibleSortPassFields.current.includes(args.columnName)){
                  console.log(visibleSortPassFields.current.indexOf(args.columnName))
                  visibleSortPass.current[visibleSortPassFields.current.indexOf(args.columnName)] = args.direction;
                }else{
                  visibleSortPassFields.current.push(args.columnName)
                  visibleSortPass.current.push(args.direction)
                }
              }
              setTimeout(() => {
                return ctrlPressed.current = false;
              }, 200);
            }else{
              console.log(ctrlPressed.current)
              visibleSortPassFields.current =[args.columnName]
              visibleSortPass.current = [args.direction]
            }
          }, 80);
          
          setTimeout(() => {
            console.log('data: ', args);
            console.log(visibleSortPassFields.current)
            console.log(visibleSortPass.current)
            
          }, 300);
            }
          }, 200);
            if(args.requestType == 'filtering'  && args.action == 'filter'){
              let filterArray = [...filterColumnName , args.currentFilteringColumn]
              filterColumnName = filterArray.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      }
      
      if (args?.requestType === 'columnstate') {
        console.log('adjusting column');
        chartOfAccountGrid?.autoFitColumns();
      }
      if(args.requestType == 'filtering'  && args.action == "clear-filter"){
        if(recordColumnName.includes(args.currentFilteringColumn)){
  
          recordColumnName.splice(recordColumnName.indexOf(args.currentFilteringColumn),1)
          
          fiterCheckedValue.splice(recordColumnName.indexOf(args.currentFilteringColumn),1)
          if(filterColumnName.includes(args.currentFilteringColumn) ){
            filterColumnName.splice(filterColumnName.indexOf(args.currentFilteringColumn),1)
          }
        }
     
      }
      if(args.requestType == 'filtering'  && args.action == 'filter'){
        console.log(chartOfAccountGrid.getFilteredRecords())
        if(!recordColumnName.includes(args.currentFilteringColumn)){
          const filteredData = chartOfAccountGrid.getFilteredRecords();
          let checkedValue   = filteredData.map((val)=>{ return val[args.currentFilteringColumn]}).filter((value, index, self) => { return self.indexOf(value) === index })
          recordColumnName = [...recordColumnName , args.currentFilteringColumn]
          fiterCheckedValue = [...fiterCheckedValue , checkedValue]
          }else{
        
        fiterCheckedValue.splice(recordColumnName.indexOf(args.currentFilteringColumn) , 1)
          const filteredData = chartOfAccountGrid.getFilteredRecords();
          let checkedValue   = filteredData.map((val)=>{ return val[args.currentFilteringColumn]}).filter((value, index, self) => { return self.indexOf(value) === index })
          fiterCheckedValue = [...fiterCheckedValue , checkedValue]
          
        } 
    }
        console.log('data: ', args);
        if (args?.requestType === 'columnstate') {
            console.log('adjusting column');
            chartOfAccountGrid?.autoFitColumns();
        }
        if (args.form) {
            console.log('data: ', args);
            if (args.requestType === 'beginEdit' || args.requestType === 'add') {
                // console.log('e: ', args.form.ej2_instances[0]);
                // /** Add Validation Rules */
                // args.form.ej2_instances[0].addRules('YarnCountID', { required: true });
                // args.form.ej2_instances[0].addRules('YarnThread', { required: true, });
                // args.form.ej2_instances[0].addRules('YarnQualityID', { required: true });
                // args.form.ej2_instances[0].addRules('YarnTypeID', { required: true });
                // args.form.ej2_instances[0].addRules('HSCodeID', { required: true, });

            }
            if (args.requestType === 'save' || args.requestType ==  'cancel') {
              setDataSourceState([])
              getListService()
              // chartOfAccountGrid.refresh();

            }
            if (args.requestType === 'save' && args.data.ActionType ==  'Add') {
              console.log(args.data)
              function convertToMozambiqueTime(dateString) {
                // Parse the date string with the GMT offset
                const gmtDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
                
                // Convert the parsed date to Mozambique time zone (Africa/Maputo)
                const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
                
                return dateInMozambique;
            }
                const data = {
                   AccountCode:args.data.AccountCode
                  ,AccountTitle:args.data.AccountTitle
                  ,AccountTitleShort:args.data.AccountTitle
                  ,Transactional:args.data.Transactional == false ? '0':'1'
                  ,Inactive:args.data.Inactive == false ? '0':'1'
                  ,AccountTypeID:args.data.AccountTypeID
                  ,OpeningBalance:args.data.OpeningBalance
                  ,CreditDays:args.data.CreditDays
                  ,CreditAmountLimit:args.data.CreditAmountLimit
                  ,ContactPerson:args.data.ContactPerson == undefined ? '-' : args.data.ContactPerson
                  ,OfficeAddress:args.data.OfficeAddress == undefined ? '-' : args.data.OfficeAddress
                  ,LandlineNumber:args.data.LandlineNumber == undefined ? '-' : args.data.LandlineNumber
                  ,CellNumber:args.data.CellNumber == undefined ? '-' : args.data.CellNumber
                  ,NTN:args.data.NTN == undefined ? '-' : args.data.NTN
                  ,STRN:args.data.STRN == undefined ? '-' :args.data.STRN
                  ,EmailAddress:args.data.EmailAddress == undefined ? '-' : args.data.EmailAddress
                  ,AccountCategoryID:args.data.AccountCategoryID
                  ,Aging:args.data.Aging == false ? '0':'1'
                 ,   ApprovedByDate: moment().format('YYYY-MM-DD'),
                    CreatedByUserID: userId,
                    TransactionDate: convertToMozambiqueTime(args.data.TransactionDate)
                }
                console.log('args: ', data)
                chartOfAccountService.create(data)
                    .then((response) => {
                       
                      getListService()
                        chartOfAccountGrid.refresh();
                    });

            }
            if (args.requestType === 'save' && args.data.ActionType ==  'Edit') {
              console.log(args.data)
                const data = {
                   AccountCode:args.data.AccountCode
                  ,AccountTitle:args.data.AccountTitle
                  ,AccountTitleShort:args.data.AccountTitle
                  ,Transactional:args.data.Transactional == false ? '0':'1'
                  ,Inactive:args.data.Inactive == false ? '0':'1'
                  ,AccountTypeID:args.data.AccountTypeID
                  ,OpeningBalance:args.data.OpeningBalance
                  ,CreditDays:args.data.CreditDays
                  ,CreditAmountLimit:args.data.CreditAmountLimit
                  ,ContactPerson:args.data.ContactPerson == undefined ? '-' : args.data.ContactPerson
                  ,OfficeAddress:args.data.OfficeAddress == undefined ? '-' : args.data.OfficeAddress
                  ,LandlineNumber:args.data.LandlineNumber == undefined ? '-' : args.data.LandlineNumber
                  ,CellNumber:args.data.CellNumber == undefined ? '-' : args.data.CellNumber
                  ,NTN:args.data.NTN == undefined ? '-' : args.data.NTN
                  ,STRN:args.data.STRN == undefined ? '-' :args.data.STRN
                  ,EmailAddress:args.data.EmailAddress == undefined ? '-' : args.data.EmailAddress
                  ,AccountCategoryID:args.data.AccountCategoryID
                  ,Aging:args.data.Aging == false ? '0':'1'
                 ,   ApprovedByDate: moment().format('YYYY-MM-DD'),
                    CreatedByUserID: userId,
                    TransactionDate: moment(args.data.TransactionDate).format('YYYY-MM-DD')
                }
                console.log('args: ', data)
                chartOfAccountService.update(data)
                    .then((response) => {
                       
                      getListService()
                        chartOfAccountGrid.refresh();
                    });

            }

        }

    };

    const formatOptions = { type: 'date', format: 'dd/MM/yy' }
    // const deleteRow = () => {
    //     CommonService.delete(userId, 'AccessoryTransferDelete', 'AccessoryAdjustTransferTransactions', 'AccessoryAdjustTransferTransactionID', deletingID, 1)
    //       .then(async (response) => {
    //         // setDialogWarningVisibility(false);
    //         // console.log("Message is about to set to ", response?.data?.recordset[0]?.Message);
    //         setWarningMessage(response?.data?.recordset[0]?.Message);
    //         // console.log("Message is set");
    //         warningDialog.current.show();
    //         // if (!(response?.data?.recordset[0]?.Message.includes('access denied'))) {
    //         //   // record was succesfully deleted
    //         //   const type = getRoute()[1];
    //         //   await getYarnContracts(type);
    //         //   // console.log("record was succesfully deleted");
    //         // }
    //         // console.log("Dialog Turned on");
    //       })
    //       .catch((e) => {
    //         // setWarningMessage('Error: ');
    //         // setDialogWarningVisibility(true);
    //         console.log('Error: ', e);
    //       });;
    //   };
    
    //   const deleteButtons = [
    //     {
    //       buttonModel: {
    //         content: 'OK',
    //         cssClass: 'e-flat',
    //         isPrimary: true,
    //       },
    //       click: (e) => {
    //         deleteDialog.current.hide();
    //         console.log(e);
    //         deleteRow();
    //       },
    //     },
    //     {
    //       buttonModel: {
    //         content: 'Cancel',
    //         cssClass: 'e-flat',
    //       },
    //       click: () => {
    //         deleteDialog.current.hide();
    //       },
    //     },
    //   ];
    
    //   const warningButtons = [
    //     {
    //       buttonModel: {
    //         content: 'OK',
    //         cssClass: 'e-flat',
    //         isPrimary: true,
    //       },
    //       click: () => {
    //         warningDialog.current.hide();
    //       },
    //     },
    //   ];
    
      // function deleteDialogClose() {
      //   setDeleteDialogVisibility(false);
      // }
    
      // function warningDialogClose() {
      //   setDialogWarningVisibility(false);
      //   if (!(warningMessage.includes('access denied'))) {
      //   getListService()
      //   }
      // }

  //   const AccessoryTransferGridComponent = ({ restrictPageSize = 12 }) => (
  //       <GridComponent
  //           ref={(grid) => (chartOfAccountGrid = grid)}
  //           id='AccessoryTransferGrid'
  //           dataSource={dataSourceState}
  //           editSettings={editSettings}
  //           toolbar={toolbarOptions}
  //           pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
  //           filterSettings={filterOptions}
  //           groupSettings={groupOptions}
  //           actionComplete={actionComplete}
  //           allowTextWrap='true'
  //           allowPaging={true}
  //           allowSorting={true}
  //           allowPdfExport={true}
  //           allowExcelExport={true}
  //           toolbarClick={toolbarClick}
  //           allowFiltering={true}
  //           allowGrouping={true}
  //           showColumnChooser={true}
  //           allowReordering={true}
  //           allowResizing={true}
  //           sortSettings={sortingOptions}
  //       >
  //           <ColumnsDirective>
  //           <ColumnDirective
  //               headerText="ID"
  //               field="AccountID"
  //               width="90"
  //               textAlign="Right"
  //               headerTextAlign="center"
  //             />

  //             <ColumnDirective
  //               headerText="Account 
  //               Code"
  //               field="AccountCode"
  //               width="130"
                
  //               textAlign="left"
  //               headerTextAlign="center"
                
  //             />
  //             <ColumnDirective
  //               headerText="Account 
  //               Title "
  //               field="AccountTitle"
  //               width="200"
  //               textAlign="Left"
  //               // textAlign="Right"
  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="Trans"
  //               field="Transactional"
  //               width="120"
  //               textAlign="left"
  //               headerTextAlign="center"
  //               // template={(rowData) => (
  //               //   <span>{rowData.Transactional ? 'Yes' : 'No'}</span>
  //               // )}
  //             />
  //             <ColumnDirective
  //               headerText="Inactive"
  //               field="Inactive"
  //               width="140"
  //               textAlign="left"
  //               visible={false}
  //               headerTextAlign="center"
  //               // template={(rowData) => (
  //               //   <span>{rowData.Inactive ? 'Yes' : 'No'}</span>
  //               // )}
  //             />
  //             <ColumnDirective
  //               headerText="Acc.
  //                Type"
  //               field="AccountType"
  //               width="125"
  //               textAlign="left"
  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="Opening 
  //               Balance"
  //               field="OpeningBalance"
  //               width="140"
  //               textAlign="right"
  //               format='n0'
  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="Credit
  //               Days"
  //               field="CreditDays"
  //               width="130"
  //               visible={false}


  //               textAlign="Right"
  //               headerTextAlign="center"
  //             />
                       
	
  // <ColumnDirective
  //               headerText="Credit 
  //               Amt Limit"
  //               field="CreditAmountLimit"
  //               width="130"
  //               visible={false}
  //               textAlign="right"
  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="Contact
  //               Person"
  //               visible={false}
  //               field="ContactPerson"
  //               width="135"
  //               textAlign="left"
  //               format='n4'
  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="Office 
  //               Address"
  //               visible={false}
  //               field="OfficeAddress"
  //               width="135"
  //               textAlign="left"
  //               // valueAccessor={thousandSeperator}

  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="Land line
  //               Number"
  //               visible={false}
  //               field="LandlineNumber"
  //               width="140"
  //               textAlign="left"
  //               // valueAccessor={thousandSeperator}

  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="Cell Number"
  //               field="CellNumber"
  //               width="140"                visible={false}
  //               textAlign="left"
  //               // valueAccessor={thousandSeperator}

  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="NTN"
  //               visible={false}
  //               field="NTN"
  //               width="130"
  //               textAlign="left"
  //               // valueAccessor={thousandSeperator}

  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="STRN"
  //               field="STRN"
  //               visible={false}
  //               width="130"
  //               textAlign="left"
  //               // valueAccessor={thousandSeperator}

  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="Email 
  //               Address"
  //               field="EmailAddress"
                
  //               visible={false}

  //               width="145"
  //               textAlign="left"
  //               // valueAccessor={thousandSeperator}

  //               headerTextAlign="center"
  //             />
  //             <ColumnDirective
  //               headerText="Account
  //               Category"
  //               visible={false}

  //               field="AccountCategory"
  //               width="145"
  //               textAlign="left"
  //               // valueAccessor={thousandSeperator}

  //               headerTextAlign="center"
  //             />

  //             <ColumnDirective headerText="Aging"
              
  //             visible={false}
  //             field="Aging" width="120"
  //             textAlign="left"
  //             headerTextAlign="center"
  //             // template={(rowData) => (
  //             //   <span>{rowData.Aging ? 'Yes' : 'No'}</span>
  //             // )}


  //             />
  //           </ColumnsDirective>
  //           <AggregatesDirective>
  //             <AggregateDirective>
  //               <AggregateColumnsDirective>
  //                 <AggregateColumnDirective
  //                   field='AdjustTransferQuantity'
  //                   type='Sum'
  //                   format='n0'
  //                   footerTemplate={footerSum}
  //                 />
               
  //               </AggregateColumnsDirective>
  //             </AggregateDirective>
  //             <AggregateDirective>
  //               <AggregateColumnsDirective>
  //                 <AggregateColumnDirective
  //                   field='AdjustTransferQuantity'
  //                   type='Sum'
  //                   format='n0'
  //                   groupFooterTemplate={footerSum}
  //                 />
                
                
  //               </AggregateColumnsDirective>
  //             </AggregateDirective>
  //           </AggregatesDirective>
  //           <Inject
  //               services={[
  //                   Edit,
  //                   Toolbar,
  //                   Page,
  //                   Sort,
  //                   PdfExport,
  //                   ExcelExport,
  //                   Filter,
  //                   Group,
  //                   Aggregate,
  //                   Freeze,
  //                   ColumnChooser,
  //                   Reorder,
  //                   Resize,
  //                   // CommandColumn,
  //               ]}
  //           />
  //       </GridComponent>
  //   );
// DYNAMIC TEMPLATE CODE
const ListGridComponent = ({ restrictPageSize = 12 }) => {
  const columns = useMemo(() => {
    console.log(reduxData.gridColumnsListWithDetails.length)
    if (reduxData.gridColumnsListWithDetails.length > 0) {
      return reduxData.gridColumnsListWithDetails.map((field, index) => (
        <ColumnDirective
          key={field.field}
          headerText={field.headerText}
          id={field.field}
          field={field.field}
          width={field.width}
          headerTextAlign={field.headerTextAlign}
          textAlign={field.textAlign}
          {...field.other[0]}
          // direction={visibleSort[index]}
        />
      ));
    } else {
      return (
        <ColumnDirective visible={false}></ColumnDirective>
         );
    }
  }, [reduxData.gridColumnsListWithDetails.length > 0]);
return (
    <GridComponent
          ref={(grid) => (chartOfAccountGrid = grid)}
          id='chartOfAccountGrid'
          dataSource={dataSourceState}
          editSettings={editSettings}
          toolbar={toolbarOptions}
          pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
          filterSettings={filterOptions}
          groupSettings={groupOptions}
          actionComplete={actionComplete}
          allowTextWrap='true'
          allowPaging={true}
          allowSorting={true}
          allowPdfExport={true}
          allowExcelExport={true}
          toolbarClick={toolbarClick}
          allowFiltering={true}
          allowGrouping={true}
          showColumnChooser={true}
          allowReordering={true}
          allowResizing={true}
          sortSettings={reduxData.gridSortingOptionsDetails}
          // dataBound={databound}
          rowSelected={
            (args)=>{
              console.log(args.data)
              selectedRows = args.data
              
                        setTimeout(() => {
                console.log('ss')
              }, 200);
            }
          }
        >
            <ColumnsDirective>
            
          {columns}
            </ColumnsDirective>
                      <AggregatesDirective>
      <AggregateDirective>
        <AggregateColumnsDirective>
          <AggregateColumnDirective
                    field='AdjustTransferQuantity'
                    type='Sum'
                    format='n0'
                    footerTemplate={footerSum}
                  />
               
                </AggregateColumnsDirective>
              </AggregateDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='AdjustTransferQuantity'
                    type='Sum'
                    format='n0'
                    groupFooterTemplate={footerSum}
                  />
                
                
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
          <Inject
            services={[
              Edit,
              Toolbar,
              Page,
              Sort,
              PdfExport,
              ExcelExport,
              Filter,
              Group,
              Aggregate,
              Freeze,
              ColumnChooser,
              Reorder,
              Resize,
              // CommandColumn,
            ]}
          />
        </GridComponent>

)
};

// DYNAMIC TEMPLATE CODE
  const getColumnsDetail = ()=>{
    return chartOfAccountGrid.getColumns()
  }
    return (
      (props.isDialog) ? (
        <div>
              {showDialogs && (
            <HardDelete
              {...deletingProps}
              showResult={(e) => {
                setShowDialogs(false);
                if (e === false) {
                  setShowDialogs(false);
                  getListService();
                }
              }}
            />
          )}
          <ListGridComponent restrictPageSize={5}  />
        </div>
      )
        :
        (
          <div className="content-wrapper">
            <div>
              <div>
                <h3>Chart Of Account - List
                {/* // DYNAMIC TEMPLATE CODE */}
                <button 
                onClick={(e)=>{ e.preventDefault();setIsToggled(!isToggled)}}
                className={`toggle-templete ${isToggled ? 'active' : ''}`}
                >
         {ToggledValue}               
            </button > 
        
                </h3>
                <TemplateCode
                 isToggled={isToggled}
                 ColumnProps={ColumnProps}
                 getListService={getListService}
                 getColumns={getColumnsDetail}
                 InputListSecurity={InputListSecurity}
                 visibleSortPassFields={visibleSortPassFields}
                 visibleSortPass={visibleSortPass}
                 groupingArray={groupingArray}
                 defaultSortColumnName={defaultSortColumnName}
                 defaultSortColumnType={defaultSortColumnType}
                 />      
                 {showDialogs && (
            <HardDelete
              {...deletingProps}
              showResult={(e) => {
                setShowDialogs(false);
                if (e === false) {
                  setShowDialogs(false);
                  getListService();
                }
              }}
            />
          )}
                <div>
                </div>
                  <ListGridComponent />
              </div>
            </div>
            
          </div>
        )







      //   (props.isDialog) ? (
      //       <div>
      //          {showDialogs ?
      //     <HardDelete
      //       {...deletingProps} 
      //       showResult={(e)=>{
      //                           console.log(e)

      //                           setShowDialogs(false)
      //                           if(e == false){
      //                             setShowDialogs(false)
      //                             // setProcessingDetails(
      //                             //   processingDetails.filter(
      //                             //     (item) => item?.FabricProcessingContractDetailID !== deletingID
      //                             //   )
      //                             // );

      //                             //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
      //   // console.log("save: ", data);
      //   // FabricProcessingContractService.create(data);
      //   setTimeout(() => {
          
      //     // yarnCountGrid.refresh()
      //     getListService();
          

      //   }, 200);
      // // getProductCutSize(props.path.substring(1));

      //                             // const type = getRoute()[1];
      //                             // getProcessDesc(type);
      //                           }
      //         }} 
      //           ></HardDelete>
      //             :null}
      //           <AccessoryTransferGridComponent restrictPageSize={5} />
      //       </div>
      //   )
      //       :
      //       (
      //           <div className="content-wrapper">
      //              {showDialogs ?
      //     <HardDelete
      //       {...deletingProps} 
      //       showResult={(e)=>{
      //                           console.log(e)

      //                           setShowDialogs(false)
      //                           if(e == false){
      //                             setShowDialogs(false)
      //                             // setProcessingDetails(
      //                             //   processingDetails.filter(
      //                             //     (item) => item?.FabricProcessingContractDetailID !== deletingID
      //                             //   )
      //                             // );

      //                             //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
      //   // console.log("save: ", data);
      //   // FabricProcessingContractService.create(data);
      //   setTimeout(() => {
          
      //     // yarnCountGrid.refresh()
      //     getListService();
          

      //   }, 200);
      // // getProductCutSize(props.path.substring(1));

      //                             // const type = getRoute()[1];
      //                             // getProcessDesc(type);
      //                           }
      //         }} 
      //           ></HardDelete>
      //             :null}
      //               <div>
      //                   <div>
      //                       <h3>Chart Of Account - List
      //                       <button 
      //         onClick={(e)=>{ e.preventDefault();setIsToggled(!isToggled)}}
      //         className={`toggle-templete ${isToggled ? 'active' : ''}`}
      //         >
      //  {ToggledValue}               
      //     </button > 
      

      //                       </h3>
      //                       <TemplateCode
      //          isToggled={isToggled}
      //          ColumnProps={ColumnProps}
      //          getListService={getListService}
      //          getColumns={getColumnsDetail}
      //          InputListSecurity={InputListSecurity}
      //          visibleSortPassFields={visibleSortPassFields}
      //          visibleSortPass={visibleSortPass}
      //          groupingArray={groupingArray}
      //          defaultSortColumnName={defaultSortColumnName}
      //          defaultSortColumnType={defaultSortColumnType}
      //          />    
      //                       <div>
      //     <DialogComponent
      //           ref={deleteDialog}
      //           width={500}
      //           visible={deleteDialogVisibility}
      //           close={deleteDialogClose}
      //           content={() => (<div>Are you sure you want to delete?</div>)}
      //           isModal={true}
      //           header='Confirmation'
      //           buttons={deleteButtons}
      //         // footerTemplate={footerTemplate}
      //         />
      //         <DialogComponent
      //           ref={warningDialog}
      //           width={400}
      //           visible={dialogWarningVisibility}
      //           close={warningDialogClose}
      //           content={() => (<div>{warningMessage}</div>)}
      //           isModal={true}
      //           header='Warning'
      //           buttons={warningButtons}
      //         // footerTemplate={footerTemplate}
      //         />
      //     </div>
      //                       <div>
      //                       </div>
      //                       <AccessoryTransferGridComponent />
      //                   </div>
      //               </div>
      //           </div>
            // )
    );
}
