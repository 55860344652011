import React ,{useRef ,useEffect}from 'react';
import './shop-transfer.scss'
import IrtizaDynamicInputList from '../dynamic-input/dynamic-input-list';
import moment from 'moment'
import SecurityService from '../security/security.service';
import ShopTransferService from './shop-transfer.service';
import ShopTransferAdd from './shop-transfer-add';
import AuditTableInput from '../audit-table/audit-table'


const ShopTransferInput = (props) => {

useEffect(() => {
  
  console.log(SecurityService.getLoggedInShopID())
  return () => {
    
  };
}, []);

// ShopTransferDetailID
// MainTransferID	
// ShopTransferID	
// TransferDate	
// FromShopName	
// ToShopName	
// BrandName	
// ProductName	
// TransferQty	
// UnitOfMeasurement	
    let columns =[
        // {  field: "ShopTransferDetailID",headerText:'ID',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[]},
        {  field: "ShopTransferID",headerText:'Shop Tranfer ID',width:'130px',textAlign:'right' ,headerTextAlign:'center',other:[]},
        {  field: "MainTransferID",headerText:'Transfer ID',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "TransferDate",headerText:'Transfer Date',width:'140px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "FromShopName",headerText:'From Shop',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "ToShopName",headerText:'To Shop',width:'120px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "BrandName",headerText:'Brand',width:'120px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "ProductName",headerText:'Product',width:'150px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "TransferQty",headerText:'Transfer Qty',width:'135px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
        {  field: "UnitOfMeasurement",headerText:'Unit OF Measurement',width:'160px',textAlign:'left' ,headerTextAlign:'center',other:[]},
       ]

       const listActionComplete = (grid ,args ,getListService ,selectedRow )=>{
        console.log(args )
        console.log(getListService )
        console.log(selectedRow )
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          }
        if (args.requestType == 'save' || args.requestType == 'cancel' ) {
          getListService()

        }

        // if (args.requestType == 'save' && args.action == 'add') {
        //   console.log(args)
        //     ShopTransferService.insert({...args.data,ShopID:SecurityService.getLoggedInShopID(), CreatedByUserID:SecurityService.getLoggedInUserId()}).then((res)=>{
        //       console.log(res)
              
        //       getListService()
        //       let AddData = {
        //         ShopTransferID:args.data.ShopTransferID,
        //         ShopTransferDate:args.data.ShopTransferDate,
        //         ShopName:args.data.ShopName,
        //         ShopNameFrom:args.data.ShopNameFrom,  
        //         ShopNameTo:args.data.ShopNameTo,
        //         AmountFrom:args.data.AmountFrom,
        //         AmountTo:args.data.AmountTo,
        //         ExchangeRate:args.data.ExchangeRate 
        //       }
        //       AuditTableInput.auditAdd(`ShopTransfer`,`ShopTransfer`,`MainShopTransferID`,AddData)
        //       })
          
        // }
        if (args.requestType == 'save' ) {
          console.log(args) 
           function convertToMozambiqueTime(dateString) {
            // Parse the date string with the GMT offset
            const gmtDate = moment(dateString, "DD-MMM-YY");

            // Convert the parsed date to Mozambique time zone (Africa/Maputo)
            const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
        
            return dateInMozambique;
        }
            ShopTransferService.update({
              ...args.data,
              TransferDate:convertToMozambiqueTime(args.data.TransferDate),
              UpdatedByUserID:SecurityService.getLoggedInUserId()}).then((res)=>{
              console.log(res)
              
              getListService()
              // let EditData = {
              //   ShopTransferID:args.data.ShopTransferID,
              //   ShopTransferDate:args.data.ShopTransferDate,
              //   ShopName:args.data.ShopName,
              //   ShopNameFrom:args.data.ShopNameFrom,  
              //   ShopNameTo:args.data.ShopNameTo,
              //   AmountFrom:args.data.AmountFrom,
              //   AmountTo:args.data.AmountTo,
              //   ExchangeRate:args.data.ExchangeRate
              // }
              //           let totalColumns = Object.keys(EditData);
              //           console.log(totalColumns)
              //           AuditTableInput.auditEdit(totalColumns,selectedRow,EditData,`ShopTransfer`,args.data.MainShopTransferID)
              })
          
        }
       }
       //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************

        // Defines the  boolean
        // @default true
    let  allowTemplate = true
    let  allowGrouping = true
    let  allowExcelExport =true
    let  allowPdfExport = true
    let  showColumnChooser = true
    let  allowPaging = false
    let  allowDataBound = false
    let  allowGridSum = true

       
        // Defines the  string
        // @default ShopTransfer${typeMode} 
        let ListSecurity='ShopTransferList'
        let AddSecurity='ShopTransferAdd'
        let EditSecurity='ShopTransferEdit'
        let DeleteSecurity='ShopTransferDelete'
       
       // Defines the  ['Add','Edit','Delete','Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser'] | 'All'
       //  @default 'All'
      let toolbarOptions ='All';
      

      const toolbarClicking =  async (args)=>{
        console.log(args)
       }
       // Defines the 'Descending' | 'Ascending'
       //  @default 'Descending'
      let DefaultSortedColumnSort ='Descending';

       /* Defines the [{
                    TableName:'ShopTransfer' ,
                    TableID:'ShopTransferID',
                   // Optional if Delete is Soft // TableSecurity:'ShopTransferDelete',
                }]*/
    //  @default [] 
    //   let listDelete =  [{ TableName:'ShopTransfer' ,TableID:'ShopTransferID', Optional // TableSecurity:'ShopTransferDelete'}]
    let listDelete = [];
       
    // Defines 'Hard' | 'Soft' 
    // @Default 'Hard'
    let deleteType = 'Hard'


/* Defines the [   {field:'IsDeleted',
OPTIONAL HAI operator:'equal' or more check syncfusion documentation
        value:'No'},...more
        ] | []
      @default [] */
    //   let filterColumns = [{ field: 'IsDeleted', value: 'No' }];
      let filterColumns = [];

      /* Defines the [ColumnNames] | []
      @default [] */
    //   let groupColumns = ['IsDeleted','AccountTitleHawalaDr'];
      let groupColumns = ['MainTransferID'];

/* Defines the 'Dialog' | 'Normal'
      @default 'Dialog' */
      let gridEditSettingMode = 'Dialog';

/* Defines the '<p></p>' | Can give HTML | AND REACT FUNCTION LIKE HeaderTemplate
      @default '<p></p>' */
      let gridEditSettingHeaderTemplate = '<p></p>';

      /* Defines the  [ColumnNames] | []
      @default [] */
           //   let gridSumColumns = ['HawalaCommissionPercent'];
      let gridSumColumns = [];


    return (
        <IrtizaDynamicInputList
        Id='ShopTransfer'
        ListTitle='Shop Transfer Detail - List'
        ColumnsList={columns}
        GridDataSource= {[
                    {
                        DataSourceType: 'Function',
                        DataSource:  ()=> ShopTransferService.getShopTransferForList(SecurityService.getLoggedInUserId() == 0 ? SecurityService.getLoggedInUserId() : SecurityService.getLoggedInShopID(),props.location.pathname.split('/')[2])
                        // DataSource:  ()=> ShopTransferService.getShopTransferForList(1)
                        }]}
        // DefaultSortedColumnName={'MainTransferID'}
        DefaultSortedColumnName={''}
        GridDialog={[
            {
                DialogType:'Self',
                Dialog:<ShopTransferAdd ShopID={SecurityService.getLoggedInShopID()} />
                // Dialog:<ShopTransferAdd ShopID={1} /> 
                }
        ]}
        ListActionComplete={listActionComplete}
        ToolbarClicking ={toolbarClicking}

        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
            // AllowTemplate={allowTemplate}
            // ToolbarOption={toolbarOptions}
            // AllowGrouping={allowGrouping}
            // AllowExcelExport={allowExcelExport}
            // AllowPdfExport={allowPdfExport} 
            // ShowColumnChooser={showColumnChooser}
            // AllowPaging={allowPaging}
            // AllowDataBound={allowDataBound}
            // ListSecurity={ListSecurity}
            // AddSecurity={AddSecurity}
            // EditSecurity={EditSecurity}
            // DeleteSecurity={DeleteSecurity}
            // DefaultSortedColumnSort={DefaultSortedColumnSort}
            // FilterColumns={filterColumns} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
            GroupColumns={groupColumns} 
            // ListDelete={listDelete}
            // DeleteType={deleteType} 
            // GridEditSettingMode={gridEditSettingMode}
            // GridEditSettingHeaderTemplate={gridEditSettingHeaderTemplate}
            // GridSumColumns={gridSumColumns}
            AllowGridSum={allowGridSum}
            {...props}
            ></IrtizaDynamicInputList>
            
            );
}



export default ShopTransferInput;
