/*
ABOUT >>
THIS INPUT USED FOR SHOW POPUP LIKE ALERTS,WARNING COMFIRMATION , OTHER 
FULLY DYNAMIC HEADER , CONTENT, BUTTONS 

ACTIVE >>
ITS IN RUNNING POSITION

UPDATES >>
IT WILL BE UPDATE TO MAKE DELETE WITH IT AND MORE ENHANCE ITS FEATURES
*/




import { DialogComponent } from '@syncfusion/ej2-react-popups';
import ReactDOM from 'react-dom';

class DialogInput {
  constructor({ content = '', header = 'Alert' , width= '200px' ,showButtons = false, buttons = [] }) {
    // Create a div container to render the dialog dynamically
    this.container = document.createElement('div');
    document.body.appendChild(this.container);

    // Render the dialog
    ReactDOM.render(
      <>
      
      <div className='wrapper-div' style={{
      
          position: 'absolute',
          top: '0px',
          width: '100vw',
          height: '100vh',
          background:' #00000021',
      
      }}></div>
      <DialogComponent
        visible={true}
        header={header}
        content={content}
        width={width}
       
        {
            ...(showButtons && buttons && buttons.length > 0
              ? { buttons: buttons.map((btn) => ({
                  ...btn,
                  buttonModel: {
                    content: btn?.buttonModel?.content || 'Close',
                    ...btn.buttonModel // Ensure buttonModel is merged in
                  }
                }))}
              : {})
          }
          
        showCloseIcon={ buttons.length > 0 && showButtons == true ? false : true}
        close={this.closeHandler} // Automatically destroy on close
        
        ref={(dialog) => {
          this.dialog = dialog;
        }}
      /></>,
      this.container
    );
  }

  show() {
    if (this.dialog) {
      this.dialog.show(); // Show the dialog programmatically
    }
  }

  hide() {
    if (this.dialog) {
      this.dialog.hide(); // Hide the dialog programmatically
    }
  }
  closeHandler() {
    console.log('Dialog closing');
    if (this.dialog) {
      this.hide(); // Hide the dialog
    }
    this.destroy(); // Cleanup
  }
  destroy() {
    if (this.dialog) {
      this.dialog.destroy(); // Destroy the dialog and clean up
    }
    if (this.container) {
      ReactDOM.unmountComponentAtNode(this.container);
      document.body.removeChild(this.container);
    }
  }
}

export default DialogInput;
