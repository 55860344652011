import React, { useEffect, useRef, useState } from 'react';
import '../../switcher.scss';
import './header.scss'
import auth from '../components/security/auth';
import securityService from '../components/security/security.service';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AutoComplete, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { zIndex } from 'material-ui/styles';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Cookies from 'js-cookie';
import userThemeService from '../services/user-theme.service';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeUpdate } from '../store/slices/theme';
let tooltipInstance;
function handleClick(args) {
    if (args.target.getAttribute("data-tooltip-id")) {
        tooltipInstance.close();
    }
    else {
        tooltipInstance.open(args.target);
    }
}

function Content() { 
    const dispatch = useDispatch()
    const [activeid , setActiveID] = useState(Cookies.get('ThemeNo'))
    const updateTheme = (e)=>{
    
        const data = {
          "UserID": securityService.getLoggedInUserId(),
          "ThemeTextColor":e.txColor,
          "ThemeBackGroundColor":e.bgColor
        }
        console.log(data)
        userThemeService.update(data).then((res)=>{
          console.log(res)
          dispatch(ThemeUpdate({
            bgColor:e.bgColor,
            txColor:e.txColor,
            ThemeNo:e.id,
            modeColor:e.modeColor,
            condition:e.condition
          }))
        //   SetSetting(false)/
        Cookies.set('login-bg-color', e.bgColor, { expires: 887 }); // Expires in 7 days
        Cookies.set('login-tx-color', e.txColor, { expires: 887 }); // Expires in 7 days
        Cookies.set('login-av-color', '#876f7acf', { expires: 887 })
        Cookies.set('bgColor', e.modeColor, { expires: 887 }); // Expires in 7 days
        Cookies.set('txColor', e.txColor, { expires: 887 });  
        Cookies.set('ThemeNo', e.id, { expires: 887 });  
        Cookies.set('condition', e.condition, { expires: 887 });  
        Cookies.set('modeColor', e.modeColor, { expires: 887 });  
    
        })
          
       }    

    let themes=[
        {id:103, image:'/103.jpeg', bgColor:'#424242',txColor:'#000',modeColor:'#fff',condition:true},
        {id:104, image:'/104.jpeg', bgColor:'#424242',txColor:'#000',modeColor:'#fff',condition:true},
        {id:105, image:'/105.jpeg', bgColor:'#004157',txColor:'#000',modeColor:'#004157',condition:false},
        {id:106, image:'/106.jpeg', bgColor:'#009f60',txColor:'#000',modeColor:'#fff',condition:false},
        {id:107, image:'/107.jpeg', bgColor:'#ff6600',txColor:'#000',modeColor:'#fff',condition:false},
        {id:108, image:'/108.jpeg', bgColor:'#ff6600',txColor:'#000',modeColor:'#fff',condition:false},
        {id:109, image:'/109.jpeg', bgColor:'#009f60',txColor:'#000',modeColor:'#fff',condition:false},
        {id:110, image:'/110.jpeg', bgColor:'#004251',txColor:'#000',modeColor:'#fff',condition:false}
    ]
    return (
        <div className='theme-container'>
            <h3>Appearance Settings</h3>
            <p>Choose your preferred interface appearance. You have the option to pick a theme manually or enable automatic theme switching that aligns with your system settings,</p>
            <div className='theme-card-container'>
            {
                themes.map((response)=>{
                    console.log(response);
                    return(
                        <div className='theme-card'>
                            <div className='theme-bg-color' style={{background:`${response.bgColor}`,height:'10px'}}></div>
                            <div className='theme-image'><img src={response.image} /></div>
                            <div className='theme-tx-color' style={{background:`${response.modeColor}`,height:'10px'}}></div> 
                            <div className='theme-btn'>
                              {activeid == response.id ?  <p className='bg-activated'> Activated </p> : ""}  <ButtonComponent style={{background:'#fff'}} onClick={(e)=>{
                                e.preventDefault()
                                setActiveID(response.id)
                                updateTheme(response)

                              }}>Apply</ButtonComponent>
                            </div>
                        </div>
                    )
                })
                
            }
        </div>
        </div>
    );
}



let style = {
    fontSize:'1rem',
    zIndex:1054
};
export default function Header(props) {    
    const [ colorTheme, setColorTheme ] = useState('theme-white');
  
    useEffect(() => {
      const currentThemeColor = localStorage.getItem('theme-color');
      if(currentThemeColor){
        setColorTheme(currentThemeColor);
      }
    }, []);
  
    const changeTheme =(theme) => {
      setColorTheme(theme);
      localStorage.setItem('theme-color', theme);
    } 

    const logout = () => {        
        auth.logout(()=> {
            localStorage.clear();
            // localStorage.removeItem('login'); 

            return props?.history?.push('/');
        })
    }

    const loggedInUser = securityService.getLoggedInUser();

    return (
        <div className={`App ${colorTheme}`}>
            <div>                
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" id='pushmenu-bar' data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="/home" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="#" className="nav-link">Contact</a>
                        </li>
                        <TooltipComponent width={'660px'}  height={'550px'}isSticky={true} opensOn={'Custom'} ref={tooltip => {
            tooltipInstance = tooltip;
        }} content={Content} style={style}  position='BottomCenter'>
                    
          
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="#" className="nav-link" onClick={handleClick}>Themes</a>
                        </li>
                        </TooltipComponent>  
                    </ul>
                    {/* SEARCH FORM */}
                    <form className="form-inline ml-3">
                        <div className="input-group input-group-sm">
                            <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-navbar" type="submit">
                                    <i className="fas fa-search" />
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* Right navbar links */}                    
                           
          
                     <ul className="navbar-nav ml-auto">
                    {/* Messages Dropdown Menu */}                    
                        {/* <li>                        
                            <div id='theme-white' 
                                onClick={()=>changeTheme('theme-white')} className='active'>
                            </div>
                            <div id='theme-blue' 
                                onClick={()=>changeTheme('theme-blue')} className='active'>
                            </div>
                            <div id='theme-orange' 
                                onClick={()=>changeTheme('theme-orange')} className='active'>
                            </div>
                            <div id='theme-purple' 
                                onClick={()=>changeTheme('theme-purple')} className='active'>
                            </div>
                            <div id='theme-green' 
                                onClick={()=>changeTheme('theme-green')} className='active'>
                            </div>
                            <div id='theme-black' 
                                onClick={()=>changeTheme('theme-black')} className='active'>
                            </div>
                        </li>    */}
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#">                            
                                <span>Welcome, {loggedInUser}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <a href="" onClick={logout} className="nav-link">Logout</a>                                
                            </div>
                        </li>
                                                                                                
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-comments" />
                            <span className="badge badge-danger navbar-badge">3</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right theme-options">                      
                            <a href="#" className="dropdown-item">
                                {/* Message Start */}
                                {/* <div className="media">
                                    <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Brad Diesel
                                            <span className="float-right text-sm text-danger"><i className="fas fa-star" /></span>
                                        </h3>
                                        <p className="text-sm">Call me whenever you can...</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                    </div>
                                </div> */}
                                {/* Message End */}
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                                {/* Message Start */}
                                <div className="media">
                                    <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            John Pierce
                                            <span className="float-right text-sm text-muted"><i className="fas fa-star" /></span>
                                        </h3>
                                        <p className="text-sm">I got your message bro</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                    </div>
                                </div>
                                {/* Message End */}
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                                {/* Message Start */}
                                <div className="media">
                                    <img src="dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Nora Silvester
                                            <span className="float-right text-sm text-warning"><i className="fas fa-star" /></span>
                                        </h3>
                                        <p className="text-sm">The subject goes here</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                    </div>
                                </div>
                                {/* Message End */}
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
                        </div>
                    </li>
                    {/* Notifications Dropdown Menu */}
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-bell" />
                            <span className="badge badge-warning navbar-badge">15</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header">15 Notifications</span>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-envelope mr-2" /> 4 new messages
                                    <span className="float-right text-muted text-sm">3 mins</span>
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-users mr-2" /> 8 friend requests
                                    <span className="float-right text-muted text-sm">12 hours</span>
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-file mr-2" /> 3 new reports
                                    <span className="float-right text-muted text-sm">2 days</span>
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                            <i className="fas fa-expand-arrows-alt" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                            <i className="fas fa-th-large" />
                        </a>
                    </li>
                </ul>
                </nav>      
            </div>          
        </div>

    )    
}