import  React , {useState, useEffect } from 'react';
 import './color-picker.scss';
 import userThemeService from '../../services/user-theme.service';
 import securityService from '../security/security.service';
import { getByDisplayValue } from '@testing-library/react';
import SvgColorTheme from './svg';


import { useDispatch, useSelector } from 'react-redux';
// [class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link:focus, [class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link:hover{
  // color:  ${txHoveredColor}  !important;

  // }
export default function ColorPicker() {
  const dispatch = useDispatch()
  const reduxData = useSelector(state =>{
    console.log(state)
    return state.ThemeUpdate})
// ASIF BHAI THEME 111324   No = 103 START
    // const [bgColor, setBgColor] = useState('#424242');
    // setBgColor('#424242');
// ASIF BHAI THEME 111324   No = 103 END


// ASIF BHAI THEME 111324   No = 104 START
    // const [bgColor, setBgColor] = useState('#424242');
    // setBgColor('#424242');
// ASIF BHAI THEME 111324   No = 104 END

// ASIF BHAI THEME 111324   No = 105 START
// const [bgColor, setBgColor] = useState('#004157');
// setBgColor('#004157');
// const [condition, setCondition] = useState(false);
// setMode('#004157')
// ASIF BHAI THEME 111324   No = 105 END


// ASIF BHAI THEME 111324   No = 106 START
// const [bgColor, setBgColor] = useState('#009f60');
// setBgColor('#009f60');
// const [condition, setCondition] = useState(false);
// setMode('#fff')
// ASIF BHAI THEME 111324   No = 106 END


// ASIF BHAI THEME 111324   No = 107 START
// const [bgColor, setBgColor] = useState('#ff6600');
// setBgColor('#ff6600');
// const [condition, setCondition] = useState(false);
// setMode('#fff')
// ASIF BHAI THEME 111324   No = 107 END



// ASIF BHAI THEME 111324   No = 108 START
// const [bgColor, setBgColor] = useState('#ff6600');
// setBgColor('#ff6600');
// const [condition, setCondition] = useState(false);
// setMode('#fff')
// ASIF BHAI THEME 111324   No = 108 END


// ASIF BHAI THEME 111324   No = 109 START
// const [bgColor, setBgColor] = useState('#009f60');
// setBgColor('#009f60');
// const [condition, setCondition] = useState(false);
// setMode('#fff')
// ASIF BHAI THEME 111324   No = 109 END


     const [condition, setCondition] = useState(reduxData.Theme.condition || true);
     const [setting, SetSetting] = useState(true);
     const [textColor, settextColor] = useState(true);

     //   #3E362EBACKGROUND
     //  COLOR #A69080 || #865D36
     // MENU AND HEADER BACKGROUND COLOR #AC8968  #93785B
     //
     //
     //
     //
    //   const [txColor, settxColor] = useState(Cookies.get('txColor'));
    //  const [bgColor, setBgColor] = useState(Cookies.get('bgColor'));

    //  ASIF BHAI THEME
     const [txColor, settxColor] = useState(reduxData.Theme.txColor);
    //  const [txColor, settxColor] = useState(Cookies.get('txColor'));
    //  const [bgColor, setBgColor] = useState(Cookies.get('bgColor'));
     const [bgColor, setBgColor] = useState(reduxData.Theme.bgColor);
     
     // setBgColor(Cookies.get('bgColor'));
    //  #theme1
    //   const [bgColor, setBgColor] = useState('#865D36');
    //    const [txColor, settxColor] = useState('#3E362E');
   // const [txHoveredColor, settxHoveredColor] = useState('#fff');
// 


    // #theme2
    //  const [bgColor, setBgColor] = useState('#964734');
    //    const [txColor, settxColor] = useState('#024950');

      //  const [txHoveredColor, settxHoveredColor] = useState('#fff');
       const [mode, setMode] = useState(reduxData.Theme.modeColor || '#fff');


    //  // pink
    //  const [bgColor, setBgColor] = useState('#e83e8c');
    //   const [txColor, settxColor] = useState('#000');
    //  orange
    //  const [bgColor, setBgColor] = useState('#ff8f01');
    //  const [txColor, settxColor] = useState('#333');
    // lightBlue
     //  const [bgColor, setBgColor] = useState('#008eff');
    //  const [txColor, settxColor] = useState('#333');
     // yellow
    //  const [bgColor, setBgColor] = useState('#fdc011');
    //  const [txColor, settxColor] = useState('#000');
     // blue
    //  const [bgColor, setBgColor] = useState('#3328a7');
    //  const [txColor, settxColor] = useState('#343a40');
    //  const [textColor2, settextColor2] = useState('#fff');
    //  const [textColor3, settextColor3] = useState('#fff');
    //  const [setting, setSetting] = useState(false);
    // const pickColor = (e)=>{
    //     console.log(e)
    //     settextColor(e.value)
    // }
    // const pickColor2 = (e)=>{
      //     console.log(e)
    //     settextColor2(e.value)
    // }
    // const pickColor3 = (e)=>{
      //     console.log(e)
    //     settextColor3(e.value)
    // }





    // muliple time 2 step
      //  .sidebar-dark-success .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-success .nav-sidebar>.nav-item>.nav-link.active {
      //   background-color: ${bgColor} !important;
      //   color: #fff;
      //   font-weight: bold;
      // }
      //  .sidebar-dark-success .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-success .nav-sidebar>.nav-item>.nav-link {
      //   background-color: ${bgColor} !important;
      //   color: #fff;
      //   font-weight: bold;
      // }
// [class*=sidebar-light-] .nav-treeview > .nav-item > .nav-link {
//   color:  ${condition == "true"  ?`#000  !important`: `#fff  !important` };
// }

// .nav-sidebar .nav-treeview>.nav-item>.nav-link>.nav-icon {
//   width: 1.6rem;
//   color: ${bgColor};
// }

// .navbar-light .navbar-nav .nav-link {

//   color:${txColor};
// }

// uncomment
// .os-padding{
//   background-color: ${bgColor} !important;
// }
//   aside.main-sidebar.sidebar-light-success.elevation-4 {
//   background:  ${bgColor} !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light{
//   background:  ${bgColor} !important;
// }






let Theme103 =<style>{ `
  [class*=sidebar-light-] .nav-treeview > .nav-item > .nav-link{
    color: #000 !important;
  }
    .navbar-light .navbar-nav .nav-link{
  color: #000 !important;
}
span.e-headertext {
  font-size: 13px;
  text-transform: uppercase;
  font-family: Calibri;
}

.e-table  tbody tr td {
  font-weight: normal;
  text-align: center !important;
  font-family: Calibri;
  border: 1px solid;
  font-size: 16px;
}


.e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid !important;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}


.content-wrapper h3 {
  text-align: center;
}

table.e-table.e-sortfilter.e-tableborder {
  border: 1px solid;
}
th.e-headercell.e-fltr-icon.e-mousepointer {
  border: 1px solid black;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  font-weight: 400;
}

.content-wrapper h3 {
  text-align: center;
  font-weight: 400;
}
.e-grid .e-groupdroparea{
  font-weight: 400;
}

.e-grid .e-altrow{
  background-color: white;
}
.e-grid .e-groupdroparea{
  font-weight: 400 !important;
  
}

// tr:hover td {
//   border: none;
// }





.e-headercelldiv {
  color: black !important;
  background: #e7e7e7 !important;
}

th.e-headercell.e-fltr-icon.e-mousepointer {
  background: #e7e7e7 !important;
}

.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
  color: black !important;
}

a.nav-link.active {
    text-transform: capitalize;
}




aside.main-sidebar.sidebar-light-success.elevation-4 {
  // #theme1
  // background: #AC8968 !important;

  background: white !important;
}
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  // #theme1
  // background: #AC8968 !important;

  background: white !important;
} 
footer.main-footer{
  // #theme1
  // background: #AC8968 !important;

  background: white !important;
} 

.os-padding{
  // #theme1
  // background-color: #AC8968 !important;

  background-color: white !important;
}
.content-wrapper{ 
  // #theme1
  // background-color: #00988c;

  background-color: #F5F5F5 ;
}
.e-rowcell {
  // #theme1
  // background-color: #00988c; /* Slightly darker #00988c */
  // #theme2
  //  background-color: #afddEf; /* Slightly darker #00988c */

  //  background-color: #7adfa8; /* Slightly darker #00988c */
}



.e-dlg-header-content,
.e-dlg-content,
.e-footer-content{
  // #theme1
  // background-color: #00988c;

  background-color: #F5F5F5 ;

}

.e-grid .e-table {
  border-collapse: collapse !important;
  
}
  span.e-tbar-btn-text {
  color:#000 !important;
}
  [class*=sidebar-light-] .nav-treeview > .nav-item > .nav-link{
  color:#000 !important;
  }
  `}</style>
  
  let Theme104 =<style>{ `
    
      .navbar-light .navbar-nav .nav-link{
  color: #000 !important;
}
      span.e-tbar-btn-text {
  color:#000 !important;
}
    [class*=sidebar-light-] .nav-treeview > .nav-item > .nav-link{
    color:#000 !important;
    }
  span.e-headertext {
  font-size: 13px;
  text-transform: uppercase;
  font-family: Calibri;
}

.e-table  tbody tr td {
  font-weight: normal;
  text-align: center !important;
  font-family: Calibri;
  border: 1px solid;
  font-size: 16px;
}


.e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid !important;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}
span.e-headertext {
  font-size: 16px;
  text-transform: uppercase;
  font-family: Calibri;
}

.e-table  tbody tr td {
  font-weight: normal;
  text-align: center !important;
  font-family: Calibri;
  border: 1px solid;
  font-size: 16px;
}


.e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}


.content-wrapper h3 {
  text-align: center;
}

table.e-table.e-sortfilter.e-tableborder {
  border: 1px solid;
}
th.e-headercell.e-fltr-icon.e-mousepointer {
  border: 1px solid black;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  font-weight: 400;
}

.content-wrapper h3 {
  text-align: center;
  font-weight: 400;
}
.e-grid .e-groupdroparea{
  font-weight: 400;
}

.e-grid .e-altrow{
  background-color: white;
}
.e-grid .e-groupdroparea{
  font-weight: 400 !important;
  
}

// tr:hover td {
//   border: none;
// }





.e-headercelldiv {
  color: black !important;
  background: #e7e7e7 !important;
}

th.e-headercell.e-fltr-icon.e-mousepointer {
  background: #e7e7e7 !important;
}

.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
  color: black !important;
}

a.nav-link.active {
    text-transform: capitalize;
}




aside.main-sidebar.sidebar-light-success.elevation-4 {
  // #theme1
  // background: #AC8968 !important;

  background: white !important;
}
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  // #theme1
  // background: #AC8968 !important;

  background: white !important;
} 
footer.main-footer{
  // #theme1
  // background: #AC8968 !important;

  background: white !important;
} 

.os-padding{
  // #theme1
  // background-color: #AC8968 !important;

  background-color: white !important;
}
.content-wrapper{ 
  // #theme1
  // background-color: #00988c;

  background-color: #F5F5F5 ;
}
.e-rowcell {
  // #theme1
  // background-color: #00988c; /* Slightly darker #00988c */
  // #theme2
  //  background-color: #afddEf; /* Slightly darker #00988c */

  //  background-color: #7adfa8; /* Slightly darker #00988c */
}



.e-dlg-header-content,
.e-dlg-content,
.e-footer-content{
  // #theme1
  // background-color: #00988c;

  background-color: #F5F5F5 ;

}
table {
  border: 0PX solid;
}
.e-grid .e-table {
  border-collapse: COLLAPSE;
  table-layout: fixed;
  width: 100%;
}

`}</style>


let Theme105 =<style>{ `
  .nav-sidebar .nav-treeview>.nav-item>.nav-link>.nav-icon{
  color:white;
  }
/* Change grid header background color */
.e-gridheader {
  // #theme1
  // background-color: #AC8968; /* Light #00988c background */
  // #theme2
  background-color: #00988c; /* Light #00988c background */

  // background-color: #0fa4AF; /* Light #00988c background */
}

/* Change grid cell background color */
.e-rowcell {
  // #theme1
  background-color: #00988c; /* Slightly darker #00988c */
  // #theme2
  //  background-color: #afddEf; /* Slightly darker #00988c */

  //  background-color: #afddEf; /* Slightly darker #00988c */
}

/* Change the color of the text in the grid */
// .e-rowcell {
//   color: #00e5b2; /* Dark text color */ 
//   color: #00e5b2; /* Dark text color */ 
// }




aside.main-sidebar.sidebar-light-success.elevation-4 {
  // #theme1
  // background: #AC8968 !important;

  background: #00988c !important;
}
footer.main-footer{
  // #theme1
  // background: #AC8968 !important;

  background: #00988c !important;
  color:black !important;
} 
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  // #theme1
  // background: #AC8968 !important;

  background: #00988c !important;
} 

.os-padding{
  // #theme1
  // background-color: #AC8968 !important;

  background-color: #00988c !important;
}
.content-wrapper{ 
  // #theme1
  background-color: #00988c;

  // background-color: ##00e5b2 ;
}


.e-dlg-header-content,
.e-dlg-content,
.e-footer-content{
  // #theme1
  background-color: #00988c;

  // background-color: ##00e5b2 ;

}

.e-grid .e-icon-filter::before, .e-grid-menu .e-icon-filter::before {
  color: white;
}
.e-headercelldiv {
  background: #004258 !important;
  color: white !important;
}
th.e-headercell.e-fltr-icon.e-mousepointer {
  background: #004258 !important;
}

.e-grid .e-icon-descending::before, .e-grid-menu .e-icon-descending::before {
  color: white;
}
.e-headercontent,
.e-content {
  background: #00988c !important;
}
.e-gridheader.e-lib.e-draggable.e-droppable {
  background: #004157 !important;
}


.e-gridheader {
    background-color: #004157 !important;
    border-bottom-color: #004157 !important;
    border-top-color: #004157 !important;
    
  }
  .e-gridpager.e-control.e-pager.e-lib {
    background:#004157 !important;
  }
  .e-toolbar .e-toolbar-items,
  div#hscroll_10_nav_left,
  div#hscroll_10_nav_right,
  span.e-input-group.e-control-wrapper.e-alldrop.e-ddl.e-lib.e-keyboard.e-valid-input,
 
  .e-toolbar,
  .e-control .e-toolbar .e-lib .e-keyboard{
    background-color:#004157 !important;
  }
  .e-dlg-content,
  .e-footer-content,
  .e-dlg-header-content,
  .main-footer,
  .content-box,
  .content-wrapper,
  .sidebar,
  .main-sidebar
  {
    background-color:#00988c;
  }

  div#hscroll_10  * {
    background: #004258 !important;
    color: white !important;
}

.e-toolbar-items .e-lib .e-hscroll .e-control .e-touch * {
  background: #004258;
}
.e-toolbar-items .e-lib .e-hscroll .e-control .e-touch * {
  background: #004258 !important;
  color: white !important;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
  background: #004258;
}
span.e-btn-icon {
  color: white !important;
}



.e-pager .e-numericitem {
  background: #004157;
  border-right-color: #eaeaea;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}
.e-pagercontainer * {
  background: #004258 !important;
  color: white !important;
}
.e-pager .e-pagercontainer{
  background: #004258 !important;
  color: white !important;
}
tr.e-row ,
.e-grid .e-altrow{
    background: #009a8f; 
}
    span.e-headertext {
  font-size: 13px;
  text-transform: uppercase;
  font-family: Calibri;
}

.e-table  tbody tr td {
  font-weight: normal;
  text-align: center !important;
  font-family: Calibri;
  border: 1px solid;
  font-size: 16px;
}


.e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid !important;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}
`}</style>

let Theme106 =<style>{ `
  th.e-headercell.e-fltr-icon.e-mousepointer {
    border: 1px solid black;
}
  span.e-tbar-btn-text {
  color:#000 !important;
}
  .navbar-light .navbar-nav .nav-link{
  color: white !important;
}
  span.e-headertext {
  font-size: 13px;
  text-transform: uppercase;
  font-family: Calibri;
}

.e-table  tbody tr td {
  font-weight: normal;
  text-align: center !important;
  font-family: Calibri;
  border: 1px solid;
  font-size: 16px;
}


.e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid !important;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}
aside.main-sidebar.sidebar-light-success.elevation-4 {
  background: #233a64 !important;
}
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {

  background: #233a64 !important;
} 
footer.main-footer{

  background: #233a64 !important;
} 

.os-padding{

  background-color: #233a64 !important;
}
.e-pager .e-numericitem {
  background: #009f60;
  border-right-color: #eaeaea;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}
a.nav-link * {
  color: white !important;
}

  
`}</style>

let Theme107 =<style>{ `
  th.e-headercell.e-fltr-icon.e-mousepointer {
    border: 1px solid black;
}
   span.e-tbar-btn-text {
  color:#000 !important;
}

span.e-headertext {
  font-size: 13px;
  text-transform: uppercase;
  font-family: Calibri;
}

.e-table  tbody tr td {
  font-weight: normal;
  text-align: center !important;
  font-family: Calibri;
  border: 1px solid;
  font-size: 16px;
}


.e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid !important;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}

.navbar-light .navbar-nav .nav-link{
  color: white !important;
}
a.nav-link * {
  color: white !important;
}
// /* Change grid header background color */
.e-gridheader {
   background-color: #33424F; /* Light #00988c background */

}



aside.main-sidebar.sidebar-light-success.elevation-4 {
  // #theme1
  // background: #AC8968 !important;

  background: #33424F !important;
}
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  // #theme1
  // background: #AC8968 !important;

  background: #33424F !important;
} 
footer.main-footer{
  // #theme1
  // background: #AC8968 !important;

  background: #33424F !important;
} 

.os-padding{
  // #theme1
  // background-color: #AC8968 !important;

  background-color: #33424F !important;
}
.dropdown-menu.dropdown-menu-lg.dropdown-menu-right.show > a {
  color: red !important;
} 
  span.e-headertext {
  font-size: 13px;
  text-transform: uppercase;
  font-family: Calibri;
}

.e-table  tbody tr td {
  font-weight: normal;
  text-align: center !important;
  font-family: Calibri;
  border: 1px solid;
  font-size: 16px;
}


.e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid !important;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}
`}</style>

let Theme108 =<style>{ `
  .e-grid .e-headercelldiv{
  color:white ;
  }
  th.e-headercell.e-fltr-icon.e-mousepointer {
    border: 1px solid black !important;
}
.navbar-light .navbar-nav .nav-link{
  color: white !important;
}
   span.e-tbar-btn-text {
  color:#000 !important;
}
a.nav-link * {
  color: white !important;
}
// /* Change grid header background color */
.e-gridheader {
   background-color: #33424F; /* Light #00988c background */
}

aside.main-sidebar.sidebar-light-success.elevation-4 {
  // #theme1
  // background: #AC8968 !important;
   background: #33424F !important;
}
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  // #theme1
  // background: #AC8968 !important;

  background: #33424F !important;
} 
footer.main-footer{
  // #theme1
  // background: #AC8968 !important;

  background: #33424F !important;
} 

.os-padding{
  // #theme1
  // background-color: #AC8968 !important;

  background-color: #33424F !important;
}
.dropdown-menu.dropdown-menu-lg.dropdown-menu-right.show > a {
  color: red !important;
}
th.e-headercell.e-fltr-icon.e-mousepointer {
  background: #4f81bd !important;
}
.e-headercelldiv {
  background: #4f81bd !important;
}
    span.e-tbar-btn-text {
  color:#000 !important;
}
  span.e-headertext {
  font-size: 13px;
  text-transform: uppercase;
  font-family: Calibri;
}

.e-table  tbody tr td {
  font-weight: normal;
  text-align: center !important;
  font-family: Calibri;
  border: 1px solid;
  font-size: 16px;
}


.e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid !important;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}
`}</style>

let Theme109 =<style>{ `
    .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    color: rgba(0, 0, 0, 0.87) !important;
}
.navbar-light .navbar-nav .nav-link{
  color: white !important;
}
a.nav-link * {
  color: white !important;
}
aside.main-sidebar.sidebar-light-success.elevation-4 {
  background: #233a64 !important;
}
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {

  background: #233a64 !important;
} 
footer.main-footer {

  background: #233a64 !important;
} 

.os-padding{

  background-color: #233a64 !important;
}
.e-pager .e-numericitem {
  background: #009f60;
  border-right-color: #eaeaea;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}
a.nav-link * {
  color: white !important;
}
th.e-headercell.e-fltr-icon.e-mousepointer {
  background: #4f81bd !important;
}
.e-headercelldiv {
  background: #4f81bd !important;
  color: white;
}
.e-headercelldiv .e-headertext {
  color: white;
}
.e-grid th.e-headercell[aria-sort=ascending] .e-headertext, .e-grid th.e-headercell[aria-sort=descending] .e-headertext, .e-grid th.e-headercell[aria-sort=ascending] .e-sortfilterdiv, .e-grid th.e-headercell[aria-sort=descending] .e-sortfilterdiv{
  color: whitesmoke;
}
.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
  color: white !important;
}  span.e-headertext {
  font-size: 13px;
  text-transform: uppercase;
  font-family: Calibri;
}

.e-table  tbody tr td {
  font-weight: normal;
  text-align: center !important;
  font-family: Calibri;
  border: 1px solid;
  font-size: 16px;
}


.e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid !important;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}
`}</style>

let Theme110 =<style>{ `
  th.e-headercell.e-fltr-icon.e-mousepointer {
    border: 1px solid;
}
    .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    color: rgba(0, 0, 0, 0.87) !important;
}
  .e-pager .e-numericitem {
  background: lightgray;
  border-right-color: #eaeaea;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: #004251;
}
[class*=sidebar-light-] .nav-treeview > .nav-item > .nav-link{
color:#000 !important;
}
.e-pager .e-pagercontainer {
    background-color: transparent !important;
    border-color: WHITE;
}
    .e-toolbar-item button {
    background: transparent !important;
}
    span.e-tbar-btn-text {
    color: #000 !important;
}
    .e-pager div.e-icons {
    color: #004251;
    background: transparent;
}
    .e-ddl.e-input-group.e-control-wrapper .e-input{
    background:transparent;
    }
  .e-dropdownbase .e-list-item.e-item-focus, .e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover, .e-dropdownbase .e-list-item.e-hover {
    background-color: #bfdaf1;
    // color: #2319b8;
  }
  
  aside.main-sidebar.sidebar-light-success.elevation-4 {
    // #theme1
    // background: #AC8968 !important;
  
    background: LIGHTGRAY !important;
  }
  nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
    // #theme1
    // background: #AC8968 !important;
  
    background: LIGHTGRAY !important;
  } 
  
  footer.main-footer  {
    // #theme1
    // background: #AC8968 !important;
  
    background: LIGHTGRAY !important;
  } 
  
  .os-padding{
    // #theme1
    // background-color: #AC8968 !important;
  
    background-color: LIGHTGRAY !important;
  }
  .content-wrapper{ 
    // #theme1
    // background-color: #00988c;
  
    background-color: #F5F5F5 ;
  }
  .e-rowcell {
    // #theme1
    // background-color: #00988c; /* Slightly darker #00988c */
    // #theme2
    //  background-color: #afddEf; /* Slightly darker #00988c */
  
     background-color: #7adfa8; /* Slightly darker #00988c */
  }
  
  
  
  .e-dlg-header-content,
  .e-dlg-content,
  .e-footer-content{
    // #theme1
    // background-color: #00988c;
  
    background-color: #F5F5F5 ;
  
  }
  
  
  
  
  //#00988c color  applied in grid background
  .e-grid .e-gridheader {
    background-color: LIGHTGRAY;
    border-bottom-color: LIGHTGRAY;
    border-top-color: LIGHTGRAY;
    
  }
  .e-gridpager.e-control.e-pager.e-lib {
    background: LIGHTGRAY;
  }
  .e-toolbar .e-toolbar-items,
  div#hscroll_10_nav_left,
  div#hscroll_10_nav_right,
  span.e-input-group.e-control-wrapper.e-alldrop.e-ddl.e-lib.e-keyboard.e-valid-input,
  .e-grid .e-content,
  .e-toolbar,
  .e-control .e-toolbar .e-lib .e-keyboard{
    background-color:LIGHTGRAY !important;
  }
  .e-dlg-content,
  .e-footer-content,
  .e-dlg-header-content,
  .main-footer,
  .content-box,
  .content-wrapper,
  .sidebar,
  .main-sidebar
  {
    background-color: LIGHTGRAY !important;
  }
  
`}</style>

    let styled=<style>{`
      .dark-node > .e-text-content .e-list-text {
  color: ${bgColor} !important; /* Optional: Change text color for better visibility */
}
       .sidebar-dark-success .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-success .nav-sidebar>.nav-item>.nav-link.active {
        background-color: ${bgColor} !important;
        color: #fff;
        font-weight: bold;
      }
       .sidebar-dark-success .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-success .nav-sidebar>.nav-item>.nav-link {
        background-color: ${bgColor} !important;
        color: #fff;
        font-weight: bold;
      }


.nav-sidebar .nav-treeview>.nav-item>.nav-link>.nav-icon {
  width: 1.6rem;
  color: ${txColor};
}

.navbar-light .navbar-nav .nav-link {
 color:${txColor};
}









[class*=sidebar-light-] .nav-treeview > .nav-item > .nav-link {
  color:  ${condition == "true"  ?  txColor : `#fff  !important` };
  
}
  
      .e-grid .e-groupdroparea {
        background-color: ${bgColor} !important;
        border-top-color: #eaeaea;
        text-transform: capitalize;
        font-family: Arial, Helvetica, sans-serif;
        color: #fff;
        font-weight: bold;
    }
    .e-grid .e-headercelldiv {
      border: 0 none;
      display: block;
      font-size: 15px;
      font-weight: bolder;
      height: 29px;
      line-height: 29px;
      margin: 0;
      padding: 0 1px 0;
      text-align: center;
      text-transform: capitalize;
      color: ${bgColor};
      font-family: Arial, Helvetica, sans-serif;
      /* word-spacing: 100vw; */
      -webkit-user-select: none;
      user-select: none;
  }
    
  .e-grid td.e-active {
    background: #d1ebff;
    text-transform: capitalize;
    font-family: Arial, Helvetica, sans-serif;
    color: ${bgColor};
}
  .e-grid td.e-active:hover {
    background: #d1ebff;
    text-transform: capitalize;
    font-family: Arial, Helvetica, sans-serif;
    color: ${bgColor};
}
  .e-altrow:hover {
  color: ${bgColor};
}
tr.e-row:hover {
  color: ${bgColor} ;
}
.e-grid.e-gridhover tr[role=row]:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-rtl .e-gridhover tr[role=row]:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
  background-color: #f4f4f4;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-grid .e-icons {
  color: ${bgColor};
}
.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
  color: ${bgColor};
}
.e-checkbox-wrapper .e-label, .e-css.e-checkbox-wrapper .e-label {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: ${bgColor};
  border-color: ${bgColor};
  color: #fff;
}
.e-input-group:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-input-group.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
  border-color: ${bgColor};
}
.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
  background-color: ${bgColor};
  border-color: ${bgColor};
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: bold;
}
.e-btn.e-flat, .e-css.e-btn.e-flat {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  box-shadow: none;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover {
  background-color: #fff;
  border-color: #fff;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
}
.e-input:focus:not(.e-success):not(.e-warning):not(.e-error), .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus, .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus, .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
  border-color: ${bgColor};
}
.e-input:focus:not(.e-success):not(.e-warning):not(.e-error), .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus, .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus, .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
  border-color: ${bgColor};
}
.e-input-focus{
  border-color: ${bgColor} !important;
}
.e-grid .e-filtered::before {
  color:  ${bgColor};
}
.e-pager {
  background-color: #fff;
  border-color: #eaeaea;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
  opacity: 1;
}
.e-pager .e-currentitem, .e-pager .e-currentitem:hover {
  background: #d3d3d3;
  text-transform: capitalize;
  /* font-family: Arial, Helvetica, sans-serif; */
  color: ${bgColor};
  opacity: 1;
}
.e-pager .e-numericitem {
  background: #fff;
  border-right-color: #eaeaea;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-pager div.e-icons {
  color: ${bgColor};
}
.e-btn.e-flat:focus, .e-css.e-btn.e-flat:focus {
  background-color: #eaeaea;
  border-color: #eaeaea;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
  box-shadow: none;
}
.e-btn.e-flat:focus, .e-css.e-btn.e-flat:hover {
  background-color: #eaeaea;
  border-color: #eaeaea;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor} ;
  box-shadow: none;
}
.e-dialog .e-btn.e-dlg-closeicon-btn:hover span {
  color: ${bgColor};
}
.e-pervent-select{
  color: ${bgColor} ;
}
.e-grid .e-gridcontent tr.e-row:first-child .e-rowcell.e-dragborder,
.e-grid .e-gridcontent .e-rowcell.e-dragborder,
.e-grid .e-gridcontent .e-rowdragdrop.e-dragborder,
.e-grid .e-gridcontent .e-detailrowcollapse.e-dragborder, .e-grid .e-gridheader tr.e-row:first-child .e-rowcell.e-dragborder,
.e-grid .e-gridheader .e-rowcell.e-dragborder,
.e-grid .e-gridheader .e-rowdragdrop.e-dragborder,
.e-grid .e-gridheader .e-detailrowcollapse.e-dragborder,
.e-grid.e-rtl .e-gridcontent tr.e-row:first-child .e-rowcell.e-dragborder,
.e-grid.e-rtl .e-gridcontent .e-rowcell.e-dragborder,
.e-grid.e-rtl .e-gridcontent .e-rowdragdrop.e-dragborder,
.e-grid.e-rtl .e-gridcontent .e-detailrowcollapse.e-dragborder,
.e-grid.e-rtl .e-gridheader tr.e-row:first-child .e-rowcell.e-dragborder,
.e-grid.e-rtl .e-gridheader .e-rowcell.e-dragborder,
.e-grid.e-rtl .e-gridheader .e-rowdragdrop.e-dragborder,
.e-grid.e-rtl .e-gridheader .e-detailrowcollapse.e-dragborder {
  // box-shadow: 0 2px 0 0 ${bgColor};
  position: relative;
  z-index: 5;
}
.e-grid .e-ccdlg .e-cc-searchdiv.e-input-focus {
  border-color: ${bgColor};
}

 {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
   color:${bgColor};
}
.e-dropitemscount {
  background-color: ${bgColor};
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
   color: #fff;
}
.e-excelfilter .e-filtered::before {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
}
.e-grid .e-groupdroparea.e-hover {
  background-color: ${bgColor};
}

.e-grid .e-groupdroparea.e-grouped {
  background-color: ${bgColor};
}
.e-grid .e-groupheadercell {
  background-color: #fff;
  border-color: #fff;
  border-radius: 0;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-grid .e-groupdroparea .e-icons {
  text-transform: capitalize;
  /* font-family: Arial, Helvetica, sans-serif; */
  color: ${bgColor};
}
.e-grid .e-groupcaption, .e-grid .e-indentcell, .e-grid .e-recordplusexpand, .e-grid .e-recordpluscollapse {
  background-color: #fff;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-bigger .e-responsive-dialog .e-dlg-header-content .e-dlg-header .e-res-custom-element .e-res-sort-clear-btn,
.e-bigger.e-responsive-dialog .e-dlg-header-content .e-dlg-header .e-res-custom-element .e-res-sort-clear-btn {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
  font-size: 19px;
  font-weight: 500;
  padding-top: 5px;
}
.e-bigger .e-responsive-dialog .e-dlg-header-content .e-btn:hover .e-btn-icon,
.e-bigger .e-responsive-dialog .e-dlg-header-content .e-btn:focus .e-btn-icon,
.e-bigger.e-responsive-dialog .e-dlg-header-content .e-btn:hover .e-btn-icon,
.e-bigger.e-responsive-dialog .e-dlg-header-content .e-btn:focus .e-btn-icon {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
}
.e-bigger .e-responsive-dialog .e-dlg-header-content .e-btn .e-btn-icon,
.e-bigger.e-responsive-dialog .e-dlg-header-content .e-btn .e-btn-icon {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
  font-size: 17px;
  margin-top: 0;
}
.e-grid .e-cloneproperties {
  background-color: #d6d7d8;
  border-color: #eaeaea;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
}
[className==sidebar-light-] .nav-treeview > .nav-item > .nav-link {
  color: ${txColor};
}

.fa-list-ul:before {
  content: "\f0ca";
  color: ${bgColor} !important;
}
.e-grid .e-rowcell:not(.e-editedbatchcell), .e-grid .e-detailrowcollapse:not(.e-editedbatchcell), .e-grid .e-detailrowexpand:not(.e-editedbatchcell), .e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell), .e-grid .e-gridheader .e-rowdragdrop:not(.e-editedbatchcell), .e-grid .e-emptyrow:not(.e-editedbatchcell) {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: ${txColor};
  font-weight: bold;
  /* font-size: 12px; */
}
h3{
  color:${txColor}
}
.e-btn.e-primary, .e-css.e-btn.e-primary {
  background-color: ${bgColor};
  border-color: ${bgColor};
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
}

.e-btn.e-primary:hover, .e-css.e-btn.e-primary:hover {
  background-color: ${txColor};
  border-color: #fff;
  box-shadow: none;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color: #FFF;
}
.e-dlg-content {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  color:${txColor};
}
.heading > h3 {
  color:${bgColor}
}

a {
  color: ${bgColor};
  text-decoration: none;
  background-color: transparent;
}
.small-box.bg-info  h3 {
  color:#fff;

}
.inner h3 {
  color:#fff;

}
.small-box .bg-warning .inner h3 {
  color:#000;

}
.e-ddl.e-input-group.e-control-wrapper .e-input {
  font-size: 20px;
  font-family: emoji;
  color: ${bgColor};
  background: white;
}
.makeStyles-avatar-4 {
  margin: 8px;
  
}

.makeStyles-submit-6 {
  margin: 24px 0px 16px;
  padding: 10px 50px;
  border-color: ${bgColor} !important;
  border-radius: 25px;
  background-color: ${bgColor} !important;
}

.common_colour {
  color: ${bgColor};
}
 table tr > th, td{
  color: ${bgColor};
}
.os-padding {
  background: ${mode};
}
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  background:  ${condition == "true"  ?`WHITE`: `#454647` };
}
footer.main-footer {
  background:  ${condition == "true"  ?`WHITE`: `#454647` };
}
    
.heading-list {
  color:  ${condition == "true"  ?`#000`: `#fff` };
}
tr.e-row {
  background: ${mode};
}
th.e-headercell.e-fltr-icon.e-centeralign.e-mousepointer{
  background: ${mode};

}
th.e-headercell.e-fltr-icon.e-mousepointer{
  background: ${mode};

}
th.e-headercell.e-centeralign.e-defaultcursor{
  background: ${mode};

}
.heading-list{
  background-color: ${mode} !important;

}
.e-toolbar-item button {
  background: ${mode} !important;
  /* color: red !important; */
  color: white !important
}
div#hscroll_1 {
  background: ${mode};
}
.e-gridpager.e-control.e-pager.e-lib {
  background: ${mode};
}
tr.e-summaryrow td {
  background: ${mode} !important;
}
// .content-wrapper div div {
//   background: ${mode} !important;
// }
// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   background: ${mode} !important;
// }
span.e-tbar-btn-text {
   color:  ${condition == "true"  ?`#000  !important`: `#fff  !important` };
  // color:  #fff !important;
}

  #get-data-btn{
    background-color: ${bgColor};
}
    #get-data-btn:hover{
        color: ${bgColor};

    }
        .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5{
            background-color: ${bgColor};

        }
            .e-radio:checked + label::before{
            border-color:${bgColor};
            }
            .e-radio + label .e-label{
            color:${bgColor};
            }
            .e-radio:checked + label::after{
  color:${bgColor};
  background:${bgColor};
  }
  button.toggle-templete{
    background:${bgColor};
    
    }
    button.toggle-templete.active{
      color:${bgColor};

    }
 .template-save > button{
    background:${bgColor};
    
    }
    .template-save > button.active{
      color:${bgColor};

    }
    .toolbar{
      color:${bgColor};

    }
      #send-email-div{
        background:${bgColor};
        }
      #send-email-btn{
      color:${txColor};
      } 
      #report-input-btn{
        color:${bgColor};
        }
        input[type="radio"][name="reports"] {
          color:${bgColor};

  }
          .toolbar{
                  background:${bgColor};
          }
                  .dropdown-menu.dropdown-menu-lg.dropdown-menu-right.show > a {
    color: red !important;
}
        `}</style>

  
   useEffect(() => {
    getByUserId()
    setTimeout(()=>{SetSetting(false)},900)
    // setMode('#fff')
    
    setBgColor(reduxData.Theme.bgColor)
    settxColor(reduxData.Theme.txColor)
    setMode(reduxData.Theme.modeColor)
    setCondition(reduxData.Theme.condition)
   }, [reduxData.Theme]);

useEffect(()=>{
  setTimeout(() => {
    
    console.log(mode)
    console.log(bgColor)
  }, 3000);
},[])
   const getByUserId = ()=>{
    if(securityService.getLoggedInUserId() != null || securityService.getLoggedInUserId() != undefined){

    userThemeService.getByID(securityService.getLoggedInUserId()).then((res)=>{
      console.log(res)
      setBgColor(res.data.recordset[0].ThemeBackGroundColor);
      // setBgColor(Cookies.get('bgColor'));
      settxColor(res.data.recordset[0].ThemeTextColor)  

    })
  }
      
   }     
   const updateTheme = (bg,tx)=>{
    
    const data = {
      "UserID": securityService.getLoggedInUserId(),
      "ThemeTextColor":tx,
      "ThemeBackGroundColor":bg
    }
    console.log(data)
    userThemeService.update(data).then((res)=>{
      console.log(res)
      SetSetting(false)
      

    })
      
   }     

   
  // const ColorChange = (e)=>{
  // let key= e.target.value
  // switch (key) {
  //   case 'green':
  //     setBgColor('#00cc67');
  //     settxColor('#000')  
  //      updateTheme('#00cc67','#000')
  //      Cookies.set('login-bg-color', '#00cc67', { expires: 887 }); // Expires in 7 days
  //      Cookies.set('login-tx-color', '#000', { expires: 887 }); // Expires in 7 days
  //      Cookies.set('login-av-color', '#000', { expires: 887 }); // Expires in 7 days
  //      Cookies.set('BgColor', '#00cc67', { expires: 887 }); // Expires in 7 days
  //      Cookies.set('txColor', '#000', { expires: 887 });
  //      break;
  //      case 'blue':
  //        setBgColor('#3328a7');
  //        settxColor('#343a40')  
  //        Cookies.set('BgColor', '#3328a7', { expires: 887 }); // Expires in 7 days
  //        Cookies.set('txColor', '#343a40', { expires: 887 }); // Expires in 7 days
  //      updateTheme('#3328a7','#343a40')
  //      Cookies.set('login-bg-color', '#3328a7', { expires: 887 }); // Expires in 7 days
  //      Cookies.set('login-tx-color', '#343a40', { expires: 887 }); // Expires in 7 days
  //      Cookies.set('login-av-color', '#a3ace1', { expires: 887 }); // Expires in 7 days

       
  //     break;
  //   case 'yellow':
  //     setBgColor('#fdc011');
  //     settxColor('#000')  
  //     Cookies.set('BgColor', '#fdc011', { expires: 887 }); // Expires in 7 days
  //     Cookies.set('txColor', '#000', { expires: 887 });
  //      updateTheme('#fdc011','#000')
  //             Cookies.set('login-bg-color', '#fdc011', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-tx-color', '#000', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-av-color', '#fdc0111', { expires: 887 }); // Expires in 7 days

  //     break;
  //   case 'lightBlue':
  //     setBgColor('#008eff');
  //     settxColor('#333')  
  //     Cookies.set('BgColor', '#008eff', { expires: 887 }); // Expires in 7 days
  //     Cookies.set('txColor', '#333', { expires: 887 });
  //      updateTheme('#008eff','#333')
  //             Cookies.set('login-bg-color', '#008eff', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-tx-color', '#333', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-av-color', '#1717173b', { expires: 887 }); // Expires in 7 days

  //     break;
  //   case 'orange':
  //     setBgColor('#ff8f01');
  //     settxColor('#333')  
  //     Cookies.set('BgColor', '#ff8f01', { expires: 887 }); // Expires in 7 days
  //     Cookies.set('txColor', '#333', { expires: 887 });
  //      updateTheme('#ff8f01','#333')
  //             Cookies.set('login-bg-color', '#ff8f01', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-tx-color', '#333', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-av-color', '#9c7f5a', { expires: 887 }); // Expires in 7 days

  //     break;
  //   case 'pink':
  //     setBgColor('#e83e8c');
  //     settxColor('#000')  
  //     Cookies.set('BgColor', '#e83e8c', { expires: 887 }); // Expires in 7 days
  //     Cookies.set('txColor', '#000', { expires: 887 });
  //      updateTheme('#e83e8c','#000')
  //             Cookies.set('login-bg-color', '#e83e8c', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-tx-color', '#000', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-av-color', '#876f7acf', { expires: 887 }); // Expires in 7 days

  //     break;
  //   case 'dark':
  //     setBgColor('#003f55');
  //     settxColor('#000')  
  //     Cookies.set('BgColor', '#003f55', { expires: 887 }); // Expires in 7 days
  //     Cookies.set('txColor', '#000', { expires: 887 });
  //      updateTheme('#003f55','#000')
  //             Cookies.set('login-bg-color', '#003f55', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-tx-color', '#000', { expires: 887 }); // Expires in 7 days
  //             Cookies.set('login-av-color', '#876f7acf', { expires: 887 }); // Expires in 7 days

  //     break;
  
  //   default:
  //     break;
  // }
  //   console.log(e.target.value)

  // }


  return (
    <>
      
      {/* <div className='page-wrapper' style={{ marginRight: setting ? '0px' : '-280px' }}> */}
        {/* <input type="radio" id="dark" name="colors" value="dark"  onClick={ColorChange}/>
        <input type="radio" id="green" name="colors" value="green"  onClick={ColorChange}/>
        <input type="radio" id="blue" name="colors" value="blue"  onClick={ColorChange}/>
        <input type="radio" id="yellow" name="colors" value="yellow"  onClick={ColorChange}/>
        <input type="radio" id="lightBlue" name="colors" value="lightBlue"  onClick={ColorChange}/>
        <input type="radio" id="orange" name="colors" value="orange"  onClick={ColorChange}/>
        <input type="radio" id="pink" name="colors" value="pink"  onClick={ColorChange}/> */}


 
{/*            
      <div onClick={()=>{SetSetting(!setting)}} style={{color: bgColor}} >
        <i className=" icons fas fa-cog" >
        </i>
        </div>
          <div className="color-palette"> */}
{/*       
            <label for="dark" style={{background:'#003f55'}}></label>
            <label for="green" style={{background:'#00cc67'}}></label>
            <label htmlFor="blue" style={{background:'#3028a6'}}></label>
            <label for="yellow" style={{background:'#fdc011'}}></label>
            <label for="lightBlue" style={{background:'#008eff'}}></label>
            <label for="orange" style={{background:'#ff8f01'}}></label>
            <label for="pink" style={{background:'#e83e8c'}}></label>
            </div> */}

      {textColor ? styled :null}
      {textColor && bgColor == '#009f60'  ? Theme106 :null}
      {textColor && bgColor == '#424242' && reduxData.Theme.ThemeNo == 103  ? Theme103 :null}
      {textColor && bgColor == '#424242' && reduxData.Theme.ThemeNo == 104  ? Theme104 :null}
      {textColor && bgColor == '#004157'  && reduxData.Theme.ThemeNo == 105 ? Theme105 :null}
      {textColor && bgColor == '#009f60' && reduxData.Theme.ThemeNo == 106 ? Theme106 :null}
      {textColor && bgColor == '#ff6600' && reduxData.Theme.ThemeNo == 107  ? Theme107 :null}
      {textColor && bgColor == '#ff6600'  && reduxData.Theme.ThemeNo == 108    ? Theme108 :null}
      {textColor && bgColor == '#009f60' && reduxData.Theme.ThemeNo == 109  ? Theme109 :null}
      {textColor && bgColor == '#004251' && reduxData.Theme.ThemeNo == 110  ? Theme110 :null}
      
      {/* </div>
      <div className='ui-switch'>
            
        */}
        {/* </div> */}
      </>
     //   <div className='control-section'>
    //     
    //     <div id='default-control-1'  >
    //       <ColorPickerComponent 
    //       className='color-picker-text' 
    //       change={pickColor}
    //       value={textColor}
          
    //              >
    //                 </ColorPickerComponent>         
    //     <span className='TEXT-COLOR'>Text Color</span>
    //     </div>
    //     <div id='default-control-2'  >
    //       <ColorPickerComponent 
    //       className='color-picker-menu' 
    //       change={pickColor2}
    //       value={textColor2}
    //              ></ColorPickerComponent>         
    //     <span className='TEXT-COLOR'>Menu Color</span>
    //     </div>
    //     <div id='default-control-3'  >
    //       <ColorPickerComponent 
    //       className='color-picker-skin' 
    //       change={pickColor3}
    //       value={textColor3}
    //              ></ColorPickerComponent>         
    //     <span className='TEXT-COLOR'>Skin Color</span>
    //     </div>
    //   </div>
  

  )
}