import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import React,{useState ,useEffect} from 'react';
import moment from 'moment';
import { renderToStream ,pdf} from '@react-pdf/renderer';
import ReportService from '../reports.service';
import http from '../../../services/http-common';
import CashBookUsdVoucher from '../cash-book-usd-voucher/cash-book-usd-voucher';
import StockReportWithValue from '../stock-report-with-value/stock-report-with-value';
import InventoryWithProfitLoss from '../Inventory-with-profit-loss/Inventory-with-profit-loss';
import invoiceService from '../../invoice/invoice.service';
import reportsService from '../reports.service';
import SaleReport from '../sale-report/sale-report';
import './send-email.scss'
import { PictureAsPdf } from '@material-ui/icons';


const SendEmail = () => {

    const [rotate, setRotate] = useState('');

    const [cashBookUsdResult, setCashBookUsdResult] = useState(null);

const generateCashBookUsdVoucher = (data) => {
    let balance = 0;
    let totalBal = 0;
    let totalDr = 0;
    let totalCr = 0;
  
    const updatedEntries = data.map((entry, index) => {
        if (index === 0) {
            balance = entry.OBal;
        } else {
            balance = balance + entry.Dr - entry.Cr;
        }
  
        totalBal += balance;
        totalDr += entry.Dr;
        totalCr += entry.Cr;
  
        return { ...entry, Bal: balance };
    });
  
    return { updatedEntries, totalBal, totalDr, totalCr };
  };
  // Example usage:
  
  
  const CashBookUsdVoucherDataSource = async () => {
    try {
      const data = {
        ReportType: 'CASHBOOKUSD',
        AccountID: 0,
        ShopID: 0,
        FromDate: `${moment().format('DD-MMM-YY')}`,
        ToDate: `${moment().format('DD-MMM-YY')}`,
      };
  
      const response = await ReportService.getCashBookUsdVoucher(data);
      
      if (!response || response.data.recordset.length === 0 || response.data.length === 0) {
        return null; // Return false if there's no data
      } else {
        const result = generateCashBookUsdVoucher(response.data.recordset);
        console.log(result); // Log final overall totals
  
        let blob = await reportsService.LedgerReport(result,'' ,data.FromDate,data.ToDate ,'save')
      console.log(blob.blobData)
        // const asPdf = pdf([]); // Create a new PDF instance
        // let component = (
        //   <CashBookUsdVoucher
        //     FromDate={`${data.FromDate}`}
        //     ToDate={`${data.ToDate}`}
        //     AccountTitle={''}
        //     DataSource={result}
        //   />
        // );
        
        // asPdf.updateContainer(component); // Update the container with the document
        // // const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  
        // // setReportCashBookUsd(blob); // Save the Blob to the state
  
        return blob.blobData; // Return true if the PDF was successfully generated
      }
    } catch (error) {
      console.error('Error in CashBookUsdVoucherDataSource', error);
      return null; // Return false in case of an error
    }
  };



  function groupAndCalculateTotalsInventoryWithProfitLoss(data) {
    const groupedData = {};
    const finalTotals = {
          OpQuantity:0,
          OpRate:0,
          OpAmount:0,
  
          PurQuantity:	0,
          PurAmount:0,
          PurRate:0,
  
          TransQuantity:0,
  
          AvailableQuantity:0,
          AvailableRate:	0,
          AvailableAmount	:0,
  
          ClosingQuantity:	0,
          ClosingRate:0,
          ClosingAmount	:0,
  
          SaleQuantity:0,
          SaleAmount:0,
          SaleAvgRate:	0,
  
          SaleRateDiff:	0,
          ProfitLossAmount:0
  
    };
  
    // Group by Shop and then by Brand
    data.forEach(item => {
      const { ShopName, BrandName } = item;
  
      if (!groupedData[ShopName]) {
        groupedData[ShopName] = { brands: {}, totals: { 
          OpQuantity:0,
          OpRate:0,
          OpAmount:0,
  
          PurQuantity:	0,
          PurAmount:0,
          PurRate:0,
  
          TransQuantity:0,
  
          AvailableQuantity:0,
          AvailableRate:	0,
          AvailableAmount	:0,
  
          ClosingQuantity:	0,
          ClosingRate:0,
          ClosingAmount	:0,
  
          SaleQuantity:0,
          SaleAmount:0,
          SaleAvgRate:	0,
  
          SaleRateDiff:	0,
          ProfitLossAmount:0
  
        } };
      }
  
      if (!groupedData[ShopName].brands[BrandName]) {
        groupedData[ShopName].brands[BrandName] = { items: [], totals: {
          OpQuantity:0,
          OpRate:0,
          OpAmount:0,
  
          PurQuantity:	0,
          PurAmount:0,
          PurRate:0,
  
          TransQuantity:0,
  
          AvailableQuantity:0,
          AvailableRate:	0,
          AvailableAmount	:0,
  
          ClosingQuantity:	0,
          ClosingRate:0,
          ClosingAmount	:0,
  
          SaleQuantity:0,
          SaleAmount:0,
          SaleAvgRate:	0,
  
          SaleRateDiff:	0,
          ProfitLossAmount:0
  
        } };
      }
  
      groupedData[ShopName].brands[BrandName].items.push(item);
  
      // Calculate Brand-wise totals
      Object.keys(finalTotals).forEach(key => {
        groupedData[ShopName].brands[BrandName].totals[key] += item[key];
        groupedData[ShopName].totals[key] += item[key];
        finalTotals[key] += item[key];
      });
    });
  
    return { groupedData, finalTotals };
  }
  
  // Example usage:
  
  
  const InventoryWithProfitLossDataSource = async () => {
    try {
        const data = {
            ReportType: 'INVENTORY_WITH_PL',
            // MainInvoiceID: props.match.params.MainInvoiceID,
            // FromDate:  `${props.match.params.FromDate}`,
            // ToDate:  `${props.match.params.ToDate}`,
            // BrandID: props.match.params.BrandID,
            // ProductID: props.match.params.ProductID,
            // // ProductID: 326,
            // ShopID: props.match.params.ShopID
            MainInvoiceID: 0,
            BrandID: 0,
            ProductID: 0,
            // ProductID: 326,
            ShopID:  0,
          FromDate: `${moment().format('DD-MMM-YY')}`,
          ToDate: `${moment().format('DD-MMM-YY')}`,
        };
    
        const response = await invoiceService.getMultiDropDownSource(
            `${data.ReportType},${data.MainInvoiceID},${data.FromDate},${data.ToDate},${data.BrandID},${data.ProductID},${data.ShopID}`
          );
        
        if (!response || response.data.recordset.length === 0 || response.data.length === 0) {
          return null; // Return false if there's no data
        } else {
                 let blob = await reportsService.InventoryWithProfitLoss(response.data.recordset ,data ,'save')
          
            // const result = groupAndCalculateTotalsInventoryWithProfitLoss(response.data.recordset);
  
            // console.log(result.groupedData);  // Grouped data by Shop and Brand
            // console.log(result.finalTotals);  // Final overall totals
            // const asPdf = pdf([]); // Creating a new PDF instance
            //     let component =<InventoryWithProfitLoss
            //     // Type={selectedReport == 'InventoryWithProfitLoss'? `With` : `WithOut` }
            //     FromDate={`${data.FromDate}`}
            //     ToDate={`${data.ToDate}`}
            //     DataSource={result} />;
            //     asPdf.updateContainer(component); // Update the container with the document
            
              // const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
    
          // setReportCashBookUsd(blob); // Save the Blob to the state
    
          return blob.blobData; // Return true if the PDF was successfully generated
        }
      } catch (error) {
        console.error('Error in CashBookUsdVoucherDataSource', error);
        // return null; // Return false in case of an error
      }
    }
  






    function groupAndCalculateTotalsStockReportWithValue(data) {
      const groupedData = {};
      const finalTotals = {
        OpQty: 0,
        RecQty: 0,
        IssQty: 0,
        TransferQty: 0,
        BalQty: 0,
        // RecAmt: 0,
        // TotalQty: 0,
        // ShowRec: 0,
        ValRate: 0,
        ValAmount: 0
      };
    
      // Group by Shop and then by Brand
      data.forEach(item => {
        const { ShopName, BrandName } = item;
    
        if (!groupedData[ShopName]) {
          groupedData[ShopName] = { brands: {}, totals: { OpQty: 0, RecQty: 0, IssQty: 0,  TransferQty: 0, BalQty: 0,  ValRate: 0, ValAmount: 0 } };
        }
    
        if (!groupedData[ShopName].brands[BrandName]) {
          groupedData[ShopName].brands[BrandName] = { items: [], totals: {OpQty: 0, RecQty: 0, IssQty: 0,  TransferQty: 0, BalQty: 0,  ValRate: 0, ValAmount: 0 } };
        }
    
        groupedData[ShopName].brands[BrandName].items.push(item);
    
        // Calculate Brand-wise totals
        Object.keys(finalTotals).forEach(key => {
          groupedData[ShopName].brands[BrandName].totals[key] += item[key];
          groupedData[ShopName].totals[key] += item[key];
          finalTotals[key] += item[key];
        });
      });
    
      return { groupedData, finalTotals };
    }
    
    // Example usage:
    
    
    const StockReportWithValuetDataSource = async () => {
      try {
        const data = {
          ReportType: 'Stock',
          MainInvoiceID: 0,
          FromDate: `${moment().format('DD-MMM-YY')}`,
          ToDate: `${moment().format('DD-MMM-YY')}`,
          BrandID: 0,
          ProductID: 0,
          ShopID: 0,
        };
    
        const response = await invoiceService.getMultiDropDownSource(
          `${data.ReportType},${data.MainInvoiceID},${data.FromDate},${data.ToDate},${data.BrandID},${data.ProductID},${data.ShopID}`
        );
    
        if (!response || !response.data || response.data.recordset.length === 0) {
          return null;
        }

        let blob = await reportsService.StockReportWithValue(response.data.recordset, data.FromDate, data.ToDate , 'save');
              console.log(blob)
    
        // const result = groupAndCalculateTotalsStockReportWithValue(response.data.recordset);
    
        // console.log(result.groupedData); // Grouped data by Shop and Brand
        // console.log(result.finalTotals); // Final overall totals
    
        // const asPdf = pdf([]); // Creating a new PDF instance
        // const component = (
        //   <StockReportWithValue Type="With" DataSource={result} />
        // );
        // asPdf.updateContainer(component); // Update the container with the document
    
        // If you want to generate the blob:
        // const blob = await asPdf.toBlob();
        // return blob;
    
        return blob.blobData; // Return true if the PDF was successfully generated
      } catch (error) {
        console.error("Error fetching StockShopWise data:", error);
        return null;
      }
    };
    
    // const StockReportWithValuetDataSource = async () => {
    //   try {
    //     const data = {
    //     ReportType: 'Stock',
    //     MainInvoiceID:  0,
    //     FromDate: `${moment().format('DD-MMM-YY')}`,
    //     ToDate: `${moment().format('DD-MMM-YY')}`,
    //     BrandID:  45,
    //     ProductID: 0,
    //     ShopID:  1
    //   };
    //  invoiceService.getMultiDropDownSource(
    //     `${data.ReportType},${data.MainInvoiceID},${data.FromDate},${data.ToDate},${data.BrandID},${data.ProductID},${data.ShopID}`
    //   ).then( async (response)=>{
    
    //     if(!response || response.data.recordset.length == 0 || response.data.length == 0){
    //      return null
    //     }
    //     else{
    // const result = groupAndCalculateTotalsStockReportWithValue(response.data.recordset);
    
    
    // console.log(result.groupedData);  // Grouped data by Shop and Brand
    // console.log(result.finalTotals);  // Final overall totals
    // const asPdf = pdf([]); // Creating a new PDF instance
    //     let component =<StockReportWithValue
    //     Type={`With`}
    //     DataSource={result} />;
    //     asPdf.updateContainer(component); // Update the container with the document
    
    //     // const blob = await new Promise((resolve) => {
    //     //   setTimeout(async () => {
    //     //     const generatedBlob = await asPdf.toBlob();
    //     //     resolve(generatedBlob);
    //     //   }, 0);
    //     // });
    
    //     return asPdf; // Generate the PDF as a Blob
     
    //   // saveAs(blob, `${selectedReport}.pdf`);
      
    //  }
    //   })
    //   } catch (error) {
    //   return null
    
    //     // console.error("Error fetching StockShopWise data:", error);
    //   }
    // }
    
    
    







    function saleReport(data) {
      const groupedData = {};
      const finalTotals = {
        InvoiceQuantity	:0,
        InvoiceRate	:0,
        InvoiceAmount	:0,
        InvoiceRateUSD	:0,
        InvoiceAmountUSD	:0,
        InvoiceRateLocalCurrency	:0,
        InvoiceAmountLocalCurrency:0
        };
        const sortedData = data.sort((a, b) => {
          if (a.ShopName < b.ShopName) return -1;
          if (a.ShopName > b.ShopName) return 1;
          return 0;
        });
      
      // Group by Shop and then by Brand
      sortedData.forEach(item => {
        const { ShopName } = item;
    
        if (!groupedData[ShopName]) {
          groupedData[ShopName] = { items: [], totals: { InvoiceQuantity	:0,
            InvoiceRate	:0,
            InvoiceAmount	:0,
            InvoiceRateUSD	:0,
            InvoiceAmountUSD	:0,
            InvoiceRateLocalCurrency	:0,
            InvoiceAmountLocalCurrency:0 } };
        }
    
    
    
        groupedData[ShopName].items.push(item);
    
        // Calculate Brand-wise totals
        Object.keys(finalTotals).forEach(key => {
          groupedData[ShopName].totals[key] += item[key];
          finalTotals[key] += item[key];
        });
      });
    
      return { groupedData, finalTotals };
    }
    
    // Example usage:
    
    
    const SaleReportDataSource = async () => {
      try {
        const data = {
        FromDate: `${moment().format('DD-MMM-YY')}`,
        // FromDate: '11-Jan-21',
        ToDate: `${moment().format('DD-MMM-YY')}`,
        MainInvoiceID:  0,
        InvoiceID:0,
        InvoiceType:'II',
        AccountID: 0,
        CurrencyID:0,
        BrandID: 0 ,
        ProductID:0 ,
        ShopID:  0,
        InvoiceDetailID: 0
        
      };
    const response = await reportsService.getSaleReport(data);
        
        if (response.status == 500){
          return;
        }
        if ( response.data?.recordset?.length == 0) {
          return;
        }
         
    const result = saleReport(response.data.recordset);
    
    console.log(result.groupedData);  // Grouped data by Shop and Brand
    console.log(result.finalTotals);  // Final overall totals
    const asPdf = pdf([]);
    const component = (
      <SaleReport
        FromDate={data.FromDate}
        ToDate={data.ToDate}
        DataSource={result}
      />
    );
    asPdf.updateContainer(component);
    
      // const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
      // saveAs(blob, `${selectedReport}.pdf`);
      return asPdf
     
        // setGenerateReport(false)
  
      } catch (error) {
        return null
      }
    }
    
    












    const onClick = async (e) => {
      try {
        e.preventDefault(); // Prevent default form submission behavior
        setRotate('rotateDiv'); // Set rotation for the div
    
        const cashBookReport = await CashBookUsdVoucherDataSource();
        const inventoryWithProfitLossReport = await InventoryWithProfitLossDataSource();
        const saleReportBlob = await SaleReportDataSource();
        const stockReportWithValueReport = await StockReportWithValuetDataSource()
        // Check for undefined blobs and convert valid ones to Blob format
        const formData = new FormData();
    
        if (cashBookReport) {
          console.log(cashBookReport)
          // const cashBookReport = await cashBookBlob.toBlob();
          // console.log(cashBookReport)
          formData.append('files', cashBookReport, 'CashBook.pdf');
        }
    
        if (inventoryWithProfitLossReport) {
          // const inventoryWithProfitLossReport = await inventoryWithProfitLossBlob.toBlob();
          formData.append('files', inventoryWithProfitLossReport, 'InventoryWithProfitLoss.pdf');
        }
    
        if (saleReportBlob) {
          const saleReportReport = await saleReportBlob.toBlob();
          formData.append('files', saleReportReport, 'SaleReport.pdf');
        } 
    console.log(stockReportWithValueReport)
        if (stockReportWithValueReport) {
          // const stockReportWithValueReport  = await stockReportWithValueBlob.toBlob()
          formData.append('files', stockReportWithValueReport, 'stockReportWithValue.pdf');
        }
    
        if (formData.has('files')) { // Check if there's any file to send
          console.log(formData); // Debugging purposes
    
          const response = await http.post('/SendEmail', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
    
          if (response.status === 200) {
            console.log('Files and data sent successfully');
             setRotate('')

          } else {
            console.log('Error in sending files and data');
          }
        } else {
          console.log('No valid files to send.');
        }
        // setRotate('');
      } catch (error) {
        setRotate('');
        console.error('Error generating or sending PDFs', error);
      }
    };
    
  // const onClick = async (e) => {
  //   try {
  //     e.preventDefault(); // Prevent default form submission behavior
  //     setRotate('rotateDiv'); // Set rotation for the div
  
  //     const cashBookBlob = await CashBookUsdVoucherDataSource();
      
  //     const inventoryWithProfitLossBlob = await InventoryWithProfitLossDataSource(); // Wait for the PDF generation result
  //     // const stockReportWithValueBlob = await StockReportWithValuetDataSource(); // Wait for the PDF generation result
  //     const saleReportBlob = await SaleReportDataSource(); // Wait for the PDF generation result
  //       console.log(cashBookBlob)
  //       console.log(inventoryWithProfitLossBlob)
  //       console.log(saleReportBlob)
  //       // console.log(inventoryWithProfitLossBlob)
  //       // console.log(inventoryWithProfitLossBlob)
  //       // console.log(stockReportWithValueBlob)

  //       // && inventoryWithProfitLossBlob && stockReportWithValueBlob  && saleReportBlob
  //     if (cashBookBlob && inventoryWithProfitLossBlob  && saleReportBlob) { // Check if the PDF was successfully generated
  //     // if (cashBookBlob  ) { // Check if the PDF was successfully generated
  //       let cashBookReport  = await cashBookBlob.toBlob()
  //       let inventoryWithProfitLossReport  = await inventoryWithProfitLossBlob.toBlob()
  //       // let stockReportWithValueReport  = await stockReportWithValueBlob.toBlob()
  //       let saleReportReport  = await saleReportBlob.toBlob()
  //       console.log(cashBookReport)
  //       const formData = new FormData();
  //       formData.append('files', cashBookReport, 'CashBook.pdf'); // Append the generated PDF to the form data
  //        formData.append('files', inventoryWithProfitLossReport, 'InventoryWithProfitLoss.pdf'); // Append the generated PDF to the form data
  //       // formData.append('files', stockReportWithValueReport, 'stockReportWithValue.pdf'); // Append the generated PDF to the form data
  //       formData.append('files', saleReportReport, 'saleReport.pdf'); // Append the generated PDF to the form data
  
  //       console.log(formData); // Debugging purposes
   
  //       // Send the form data via HTTP POST request
  //       const response = await http.post('/SendEmail', formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       });
  
  //       if (response.status === 200) {
  //         console.log('Files and data sent successfully');
  //         setRotate('')
  //       } else {
  //         console.log('Error in sending files and data');
  //         setRotate('')
  //       }
  //     }
  //   } catch (error) {
  //     setRotate('')
  //     console.error('Error generating or sending PDFs', error);
  //   }
  // };
  

    return (
        <div className='content-wrapper' style={{display:"flex"}}>
            <div  id='send-email-div' className={rotate} style={{
            height:"200px",
            width:"200px",
            // background:'#4CAF50',
            margin:'auto',
            borderColor:'white',
            display:'flex',
            justifyContent:'CENTER',
            alignItems:'center',
            borderRadius:'50%',
            borderStyle:'dotted',
            position:'relative'
            }}>
            <ButtonComponent id='send-email-btn' onClick={onClick}>
                Send Email
            </ButtonComponent>
            </div>
        </div>
    );
}

export default SendEmail;
