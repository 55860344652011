import React ,{useRef ,useEffect}from 'react';
import './voucher.scss'
import IrtizaDynamicInputList from '../dynamic-input/dynamic-input-list';
import moment from 'moment'
import SecurityService from '../security/security.service';
import VoucherService from '../voucher/voucher.service';
import VoucherAdd from './voucher-add';
import AuditTable from '../audit-table/audit-table'
import VoucherReport from '../reports/voucher-report/voucher-report';
import { renderToStream ,pdf} from '@react-pdf/renderer';
import DialogInput from '../dynamic-input/popup-dialog-input';

import { saveAs } from 'file-saver'
const VoucherInput = (props) => {
console.log(props)
useEffect(() => {
  
  console.log(SecurityService.getLoggedInShopID())
  return () => {
    
  };
}, []);

// --MainVoucherID (ALL)	
// --VoucherID (ALL)	
// --VoucherDate	(ALL) 
// --AccountTitleCashBank (ONLY FOR CR , CP)	
// --CurrencyName (ALL)	
// --InvoiceNo	(ALL)
// --ShopName	(ALL)
// --VoucherCategory (J)
// --AccountTitle	(ALL)
// --Debit	(CP , J)
// --Credit	(CR , J)
// --DetailRemarks	(ALL)	
    let columns =[
        {  field: "MainVoucherID",headerText:'ID',width:'120px',textAlign:'right' ,headerTextAlign:'center',other:[]},
        {  field: "VoucherID",headerText:'Voucher ID',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "VoucherDate",headerText:'Voucher Date',width:'130px',textAlign:'right' ,headerTextAlign:'center',other:[]},
        {  field: "AccountTitleCashBank",headerText:'Account Title Cash Bank',width:'160px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:props.location.pathname.split('/')[2] != 'J'}]},
        {  field: "CurrencyName",headerText:'Currency',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "InvoiceNo",headerText:'Invoice No',width:'350px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "ShopName",headerText:'Shop',width:'120px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "VoucherCategory",headerText:'Voucher Category',width:'150px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:props.location.pathname.split('/')[2] == 'J'}]},
        {  field: "AccountTitle",headerText:'Account Title',width:'220px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "Debit",headerText:'Debit',width:'160px',textAlign:'right' ,headerTextAlign:'center',other:[{visible:props.location.pathname.split('/')[2] != 'CR','format':'n0'}]},
        {  field: "Credit",headerText:'Credit',width:'160px',textAlign:'right' ,headerTextAlign:'center',other:[{visible:props.location.pathname.split('/')[2] != 'CP','format':'n0'}]},
        {  field: "DetailRemarks",headerText:'Detail Remarks',width:'160px',textAlign:'left' ,headerTextAlign:'center',other:[]},
       ]

       const listActionComplete = async (grid ,args ,getListService ,selectedRow )=>{
        console.log(args )
        console.log(getListService )
        console.log(selectedRow )
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          }
        if (args.requestType == 'save' || args.requestType == 'cancel' ) {
          getListService()

        }

        if (args.requestType == 'save' ) {
          console.log(args)
          function convertToMozambiqueTime(dateString) {
            // Parse the date string with the GMT offset
            // const gmtDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
            
            // // Convert the parsed date to Mozambique time zone (Africa/Maputo)
            // const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
            
            // return dateInMozambique;
            const gmtDate = moment(dateString, "DD-MMM-YY");

      // Convert the parsed date to Mozambique time zone (Africa/Maputo)
      const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
  
      return dateInMozambique;
        }
          const response = await VoucherService.editVoucher({...args.data,  VoucherDate	:convertToMozambiqueTime(args.data.VoucherDate)});
          console.log(response)
          await getListService()
            // VoucherService.insert({...args.data,ShopID:SecurityService.getLoggedInShopID(), CreatedByUserID:SecurityService.getLoggedInUserId()}).then((res)=>{
            //   console.log(res)
              
            //   getListService()
            //   let AddData = {
            //     VoucherID:args.data.VoucherID,
            //     VoucherDate:args.data.VoucherDate,
            //     ShopName:args.data.ShopName,
            //     ShopNameFrom:args.data.ShopNameFrom,  
            //     ShopNameTo:args.data.ShopNameTo,
            //     AmountFrom:args.data.AmountFrom,
            //     AmountTo:args.data.AmountTo,
            //     ExchangeRate:args.data.ExchangeRate 
            //   }
            //   AuditTableInput.auditAdd(`Voucher`,`Voucher`,`MainVoucherID`,AddData)
            //   })
          
        }
        if (args.requestType == 'save' && args.action == 'edit') {
          console.log(args)
            // VoucherService.update({...args.data, UpdatedByUserID:SecurityService.getLoggedInUserId()}).then((res)=>{
            //   console.log(res)
              
            //   getListService()
            //   let EditData = {
            //     VoucherID:args.data.VoucherID,
            //     VoucherDate:args.data.VoucherDate,
            //     ShopName:args.data.ShopName,
            //     ShopNameFrom:args.data.ShopNameFrom,  
            //     ShopNameTo:args.data.ShopNameTo,
            //     AmountFrom:args.data.AmountFrom,
            //     AmountTo:args.data.AmountTo,
            //     ExchangeRate:args.data.ExchangeRate
            //   }
            //             let totalColumns = Object.keys(EditData);
            //             console.log(totalColumns)
            //             AuditTableInput.auditEdit(totalColumns,selectedRow,EditData,`Voucher`,args.data.MainVoucherID)
            //   })
          
        }
       }
       //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************

        // Defines the  boolean
        // @default true
    let  allowTemplate = true
    let  allowGrouping = true
    let  allowExcelExport =true
    let  allowPdfExport = true
    let  showColumnChooser = true
    let  allowPaging = false
    let  allowDataBound = false
    let  allowGridSum = true

       
        // Defines the  string
        // @default Voucher${typeMode} 
        let ListSecurity= props.location.pathname.split('/')[2]  == 'J' ? 'JournalVoucherList': props.location.pathname.split('/')[2]  == 'CR' ? 'ReceiptVoucherList': 'PaymentVoucherList'
        let AddSecurity= props.location.pathname.split('/')[2]  == 'J' ? 'JournalVoucherAdd': props.location.pathname.split('/')[2]  == 'CR' ? 'ReceiptVoucherAdd': 'PaymentVoucherAdd'
        let EditSecurity= props.location.pathname.split('/')[2]  == 'J' ? 'JournalVoucherEdit': props.location.pathname.split('/')[2]  == 'CR' ? 'ReceiptVoucherEdit': 'PaymentVoucherEdit'
        let DeleteSecurity= props.location.pathname.split('/')[2]  == 'J' ? 'JournalVoucherDelete': props.location.pathname.split('/')[2]  == 'CR' ? 'ReceiptVoucherDelete': 'PaymentVoucherDelete'
        
        // Defines the  ['Add','Edit','Delete','Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser'] | 'All'
        //  @default 'All'
        let ReportSecurity= props.location.pathname.split('/')[2]  == 'J' ? 'JournalVoucherPreview': props.location.pathname.split('/')[2]  == 'CR' ? 'CashReceiptPreview': 'CashPaymentPreview'
      let toolbarOptions =['Add','Edit',
        {  text: `Delete`, tooltipText: "Delete", id: "DeleteWithDetail", disable:!SecurityService.canUserAccess(DeleteSecurity) }
        ,'Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser',{
         text: "Preview Voucher", tooltipText: "Preview Voucher", id: "VoucherReport", 
         disabled: !SecurityService.canUserAccess(ReportSecurity)
         ,mustSelectRow:true
        }];
      
      // Defines the toolbarClicking  function
      
      const dialogRef = useRef(null);
      const showDialog = (header,title,actions =['OK']) => {
        return new Promise((resolve) => {
        // Destroy existing dialog if any
        if (dialogRef.current) {
          dialogRef.current.destroy();
        }
        const okButtons = actions.map((action) => ({
          buttonModel: {
            content: action,
            cssClass: 'e-flat',
            isPrimary: action === 'OK',
          },
          click: (e) => {
            hideDialog();
            resolve(action);
          },
        }));
        // Create a new dialog instance
        dialogRef.current = new DialogInput({
          content: ()=>(<>{title}</>),
          header:header,
          width:'340px',
          showButtons: true,
          buttons:okButtons
        });
        // Show the dialog programmatically
        dialogRef.current.show();

      });

      };
    const hideDialog = () =>{
      if (dialogRef.current) {
      }
      dialogRef.current.hide();
      dialogRef.current.destroy();
      dialogRef.current = null; // Clean up reference after destruction
    }
    const ToolbarClicking =  async (grid , args , selectedRow , getListService,setDataSourceState)=>{
      console.log(grid)
        console.log(args)
        if(args?.item?.properties?.id == 'DeleteWithDetail'){
                  if(selectedRow == undefined){
                await showDialog('WARNING' ,<span class="error-tooltip">Please select a row first!</span>)
              }else{
                let dialogResult  = await showDialog('Confirmation' ,<span class="error-success">Are you sure you want to delete?</span> , ['OK','CANCEL'])
                console.log(dialogResult)
              if(dialogResult == 'OK'){
                VoucherService.delete(selectedRow.MainVoucherID).then(async (res)=>{
                await  showDialog('ALERT' ,<span class="error-success">RECORD(S) DELETED SUCESSFULLY</span>)
                  await getListService()
                    AuditTable.auditDelete('Vouhcer',selectedRow.MainVoucherID,{
                      VoucherID:selectedRow.VoucherID,
                      VoucherType:selectedRow.VoucherType,
                      VoucherDate:moment(selectedRow.VoucherDate).format('DD-MMM-YY'),
                      AccountTitleCashBank:selectedRow.AccountTitleCashBank,
                      CurrencyName:selectedRow.CurrencyName,
                      ShopName:selectedRow.ShopName
        
                    })
                  })
                }
                }
                }
        if(args.item.properties.id == 'VoucherReport' && grid.selectedRowIndex != -1){
          console.log(args.item.properties.id)
          console.log(grid.getSelectedRecords()[0])
          handleRedirectVoucher(grid.getSelectedRecords()[0])
        
        }
      }
      const handleRedirectVoucher = async (args) => {
        // Destructure parameters from the args object
        const {
          VoucherType,
          MainVoucherID,
          VoucherID,
          AccountIDCashBank,
          CurrencyID,
          MainInvoiceID,
          ShopID,
          VoucherCategory,
          AccountID,
          VoucherDetailID,
          VoucherDate
        } = args;
        
    let data = {
      FromDate: '01-JAN-01',
      ToDate: moment(VoucherDate).format('DD-MMM-YY'),
      VoucherType: VoucherType,
      MainVoucherID:MainVoucherID,
      VoucherID: 0,
      AccountIDCashBank: 0,
      CurrencyID: 0,
      MainVoiceID: 0,
      ShopID: ShopID,
      VoucherCategory: '',
      AccountID: 0,
      VoucherDetailID: 0,
    };
    let response = await VoucherService.getGridDataSource(data);
console.log(response.data.recordset)
exportPdfFile(response.data.recordset ,VoucherType)


    // setDataSource(response.data.recordset);
  //   setTimeout(() => {
  //     exportToExcel(response.data.recordset,'CP')
  //   }, 1000);
  // };

        // console.log(args)
        // console.log(VoucherType)
        // Construct the URL with the parameters
        // const url = `/ReportVoucher${VoucherType}/${VoucherDate}/${VoucherType}/${MainVoucherID}/${VoucherID}/${AccountIDCashBank}/${CurrencyID}/${MainInvoiceID}/${ShopID}/${VoucherCategory}/${AccountID}/${VoucherDetailID}`;
      
        // Open the URL in a new tab and focus on it
        // const newTab = window.open(url, '_blank');
        // newTab.focus();
      };
      
const  exportPdfFile = async (data , type) =>{
  
    const asPdf = pdf([]); // Creating a new PDF instance
    let component =<VoucherReport
    DataSource={data} 
    VoucherType={type} 
    />;
    asPdf.updateContainer(component); // Upda te the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `Voucher${type}.pdf`);


}
       // Defines the 'Descending' | 'Ascending'
       //  @default 'Descending'
      let DefaultSortedColumnSort ='Descending';

       /* Defines the [{
                    TableName:'Voucher' ,
                    TableID:'VoucherID',
                   // Optional if Delete is Soft // TableSecurity:'VoucherDelete',
                }]*/
    //  @default [] 
    //   let listDelete =  [{ TableName:'Voucher' ,TableID:'VoucherID', Optional // TableSecurity:'VoucherDelete'}]
    let listDelete = [];
       
    // Defines 'Hard' | 'Soft' 
    // @Default 'Hard'
    let deleteType = 'Hard'


/* Defines the [   {field:'IsDeleted',
OPTIONAL HAI operator:'equal' or more check syncfusion documentation
        value:'No'},...more
        ] | []
      @default [] */
    //   let filterColumns = [{ field: 'IsDeleted', value: 'No' }];
      let filterColumns = [];

      /* Defines the [ColumnNames] | []
      @default [] */
    //   let groupColumns = ['IsDeleted','AccountTitleHawalaDr'];
      let groupColumns = ['MainVoucherID'];

/* Defines the 'Dialog' | 'Normal'
      @default 'Dialog' */
      let gridEditSettingMode = 'Dialog';

/* Defines the '<p></p>' | Can give HTML | AND REACT FUNCTION LIKE HeaderTemplate
      @default '<p></p>' */
      let gridEditSettingHeaderTemplate = '<p></p>';

      /* Defines the  [ColumnNames] | []
      @default [] */
           //   let gridSumColumns = ['HawalaCommissionPercent'];
      let gridSumColumns = [];


    return (
        <IrtizaDynamicInputList
        Id='Voucher'
        ListTitle={`${props.location.pathname.split('/')[2] == 'J' ? `Journal Voucher` : props.location.pathname.split('/')[2] == 'CR' ? `CASH RECEIPT` : `CASH PAYMENT` }   ${props.location.pathname.split('/')[3]} - List`}
        ColumnsList={columns}
        GridDataSource= {[
                    {
                        DataSourceType: 'Function',
                        // DataSource:  ()=> VoucherService.getVoucherForList(SecurityService.getLoggedInShopID())
                        DataSource:  ()=> VoucherService.getVoucherForList(SecurityService.getLoggedInUserId() == 0 ? SecurityService.getLoggedInUserId() : SecurityService.getLoggedInShopID(),props.location.pathname.split('/')[2] , props.location.pathname.split('/')[3])
                        }]}
        // DefaultSortedColumnName={'MainVoucherID'}
        DefaultSortedColumnName={''}
        GridDialog={[
            {
                DialogType:'Self',
                // Dialog:<VoucherAdd ShopID={SecurityService.getLoggedInShopID()} />
                Dialog:<VoucherAdd ShopID={SecurityService.getLoggedInShopID()} UserID={SecurityService.getLoggedInUserId()} Type={props.location.pathname.split('/')[2]} />
                }
        ]}
        ListActionComplete={listActionComplete}
        ToolbarClicking={ToolbarClicking}

        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
            // AllowTemplate={allowTemplate}
            ToolbarOption={toolbarOptions}
            // AllowGrouping={allowGrouping}
            // AllowExcelExport={allowExcelExport}
            // AllowPdfExport={allowPdfExport} 
            // ShowColumnChooser={showColumnChooser}
            // AllowPaging={allowPaging}
            // AllowDataBound={allowDataBound}
            ListSecurity={ListSecurity}
            AddSecurity={AddSecurity}
            EditSecurity={EditSecurity}
            DeleteSecurity={DeleteSecurity}
            // DefaultSortedColumnSort={DefaultSortedColumnSort}
            // FilterColumns={filterColumns} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
            GroupColumns={groupColumns} 
            // ListDelete={listDelete}
            // DeleteType={deleteType} 
            // GridEditSettingMode={gridEditSettingMode}
            // GridEditSettingHeaderTemplate={gridEditSettingHeaderTemplate}
            // GridSumColumns={gridSumColumns}
            AllowGridSum={allowGridSum}
            {...props}
            ></IrtizaDynamicInputList>
            
            );
}



export default VoucherInput;
