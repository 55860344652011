import React ,{useRef ,useEffect}from 'react';
import './product.scss'
import DynamicInputList from '../dynamic-input/dynamic-input-list';
import moment from 'moment'
import SecurityService from '../security/security.service';
import ProductService from '../product/product.service';
import ProductAdd from './product-add';
import AuditTableInput from '../audit-table/audit-table'


const ProductInput = (props) => {

  // ProductID	
  // ProductName	
  // UnitOfMeasurementID
    let columns =[
        {  field: "ProductID",headerText:'ID',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[]},
        {  field: "ProductName",headerText:'Product',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "UnitOfMeasurement",headerText:'Unit Of Measurement',width:'160px',textAlign:'left' ,headerTextAlign:'center',other:[]},
      ]

       const listActionComplete = (grid,args ,getListService ,selectedRow )=>{
        console.log(args )
        console.log(getListService )
        console.log(selectedRow )
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          }
          if (args.requestType == 'save' || args.requestType == 'cancel' ) {
            getListService()
  
          }
          if(args.requestType == 'save'){
            let data = {
              ProductID:args.data.ProductID,
              ProductName:args.data.ProductName,
              UnitOfMeasurementID:args.data.UnitOfMeasurementID,
              UnitOfMeasurement:args.data.UnitOfMeasurement
            }
            console.log(data)
          if(args.action == 'add'){
            ProductService.insert(data).then(async (res)=>{
              await getListService()
              // let AddData = {
              //   UnitOfMeasurementID:args.data.UnitOfMeasurementID,
              //   UnitOfMeasurement:args.data.UnitOfMeasurement,
              // }
              // AuditTableInput.auditAdd(`UnitOfMeasurement`,`UnitOfMeasurement`,`UnitOfMeasurementID`,AddData)
            })
          }
          if(args.action == 'edit'){
            ProductService.update(data).then((res)=>{
              getListService()
  
              // let EditData = {
              //     UnitOfMeasurementID:args.data.UnitOfMeasurementID,
              //     UnitOfMeasurement:args.data.UnitOfMeasurement
              // }
              // let totalColumns = Object.keys(EditData);
              //             console.log(totalColumns)
              //             AuditTableInput.auditEdit(totalColumns,selectedRow,EditData,`UnitOfMeasurement`,args.data.MainUnitOfMeasurementID)
            })
          }
          }
          
       }
       //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************

        // Defines the  boolean
        // @default true
    let  allowTemplate = true
    let  allowGrouping = true
    let  allowExcelExport =true
    let  allowPdfExport = true
    let  showColumnChooser = true
    let  allowPaging = false
    let  allowDataBound = true
    let  allowGridSum = false

       
        // Defines the  string
        // @default Product${typeMode} 
        let ListSecurity='CurrencyTransferList'
        let AddSecurity='CurrencyTransferAdd'
        let EditSecurity='CurrencyTransferEdit'
        let DeleteSecurity='CurrencyTransferDelete'
       
       // Defines the  ['Add','Edit','Delete','Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser'] | 'All'
       //  @default 'All'
      let toolbarOptions ='All';

      const toolbarClicking =  async (args)=>{
        console.log(args)
      }
      
       // Defines the 'Descending' | 'Ascending'
       //  @default 'Descending'
      let DefaultSortedColumnSort ='Descending';

       /* Defines the [{
                    TableName:'Currency' ,
                    TableID:'ProductID',
                   // Optional if Delete is Soft // TableSecurity:'ProductDelete',
                }]*/
    //  @default [] 
    //   let listDelete =  [{ TableName:'Product' ,TableID:'ProductID', Optional // TableSecurity:'ProductDelete'}]
    let listDelete = [];
       
    // Defines 'Hard' | 'Soft' 
    // @Default 'Hard'
    let deleteType = 'Hard'


/* Defines the [   {field:'IsDeleted',
OPTIONAL HAI operator:'equal' or more check syncfusion documentation
        value:'No'},...more
        ] | []
      @default [] */
    //   let filterColumns = [{ field: 'IsDeleted', value: 'No' }];
      let filterColumns = [];

      /* Defines the [ColumnNames] | []
      @default [] */
    //   let groupColumns = ['IsDeleted','UnitOfMeasurementHawalaDr'];
      let groupColumns = [];

/* Defines the 'Dialog' | 'Normal'
      @default 'Dialog' */
      let gridEditSettingMode = 'Dialog';

/* Defines the '<p></p>' | Can give HTML | AND REACT FUNCTION LIKE HeaderTemplate
      @default '<p></p>' */
      let gridEditSettingHeaderTemplate = '<p></p>';

      /* Defines the  [ColumnNames] | []
      @default [] */
           //   let gridSumColumns = ['HawalaCommissionPercent'];
      let gridSumColumns = [];


    return (
        <DynamicInputList
        Id='Product'
        ListTitle='Product - List'
        ColumnsList={columns}
        GridDataSource= {[
                    {
                        DataSourceType: 'Function',
                        DataSource:  ()=> ProductService.getProductForList()
                        }]}
        DefaultSortedColumnName={'ProductID'}
        GridDialog={[
            {
                DialogType:'Self',
                Dialog:<ProductAdd  />
                // Dialog:[
                //   {
                //     Title:'Invoice Evaluation',
                //     Inputs:[
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'ProductID',value:'ProductName'},type:'dropdown',dataSource:{type:'Route',Route:'ProductName'},defaultValues:null},
                //       {field:'ExchangeRate'	,type:'float',defaultValues:null},
                //       {field:'HawalaCommissionPercent'	,type:'float',defaultValues:null},
                //       {field:{id:'ProductIDHawala',value:'ProductNameHawala	'},type:'dropdown',dataSource:{type:'Route',Route:'ProductName'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null}
                //     ]
                //   }
                // ]

            }
        ]}
        ListActionComplete={listActionComplete}
        ToolbarClicking ={toolbarClicking}

        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
            // AllowTemplate={allowTemplate}
            // ToolbarOption={toolbarOptions}
            // AllowGrouping={allowGrouping}
            // AllowExcelExport={allowExcelExport}
            // AllowPdfExport={allowPdfExport} 
            // ShowColumnChooser={showColumnChooser}
            // AllowPaging={allowPaging}
            AllowDataBound={allowDataBound}
            // ListSecurity={ListSecurity}
            // AddSecurity={AddSecurity}
            // EditSecurity={EditSecurity}
            // DeleteSecurity={DeleteSecurity}
            // DefaultSortedColumnSort={DefaultSortedColumnSort}
            // FilterColumns={filterColumns} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
            // GroupColumns={groupColumns} 
            // ListDelete={listDelete}
            // DeleteType={deleteType} 
            // GridEditSettingMode={gridEditSettingMode}
            // GridEditSettingHeaderTemplate={gridEditSettingHeaderTemplate}
            // GridSumColumns={gridSumColumns}
            // AllowGridSum={allowGridSum}
            {...props}
            ></DynamicInputList>
            
            );
}



export default ProductInput;
