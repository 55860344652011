import React, { useRef, useState , useEffect,useMemo} from 'react';
import moment from 'moment';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../dynamic-input/dynamic-main-inputs';

const BrandAdd = (props) => {
  
  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};
// MainTransferID	
// BrandID	
// TransferDate	
// FromShopID	
// ToShopID
  
  const [inputProps, setInputProps] = useState({
  
    BrandID:{name: 'BrandID',   value: props.BrandID || '' ,enabled:false},
    BrandName:{name: 'BrandName',   value: props.BrandName || '' },
    BrandVendorName:{
      name:{id:'BrandVendorID',value:'BrandVendorName'},
      caption: 'Select Brand Vendor',
      value: {id: props.BrandVendorID || undefined ,value:props.BrandVendorName || ''},
      dataSource: {type:'Route',route:'BrandVendor'},
      

        },
    });
// EXTRA CODE START

// EXTRA CODE END

const handleInputCreated = async (e, name) => {
  console.log(e);
    console.log(`Input ${name} changed to ${e}`);
  };
const handleInputFocus = async (e, name) => {
  
    console.log(e);
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {
  
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {Input: HiddenComponent ,Props: {...inputProps.BrandID, setInputProps },ColumnSize:0},

    {Input: TextComponent,Props: {...inputProps.BrandName,setInputProps },ColumnSize:12},

    {Input: DropdownComponent,Props: {...inputProps.BrandVendorName, setInputProps },ColumnSize:12},
    ];
  return (
    <div onKeyDown={(e)=>e.stopPropagation()}>
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   mainProps={props} title={`Brand ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
      </div>
  );
}; 

export default BrandAdd;
