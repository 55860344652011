import React, { useRef, useState , useEffect,useMemo} from 'react';
import moment from 'moment';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../dynamic-input/dynamic-main-inputs';
import IrtizaDynamicInputGrid from '../dynamic-input/dynamic-input-grid';

import shopVoucherService from './voucher.service';

import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import DropdownService from '../dynamic-input/dropdown-api.service';
import voucherService from './voucher.service';

const VoucherAdd = (props) => {
  
  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};
  // MainVoucherID	
  // VoucherID	
  // VoucherType	
  // VoucherDate	
  // AccountIDCashBank	
  // CurrencyID	
  // MainInvoiceID	
  // ShopID	
  // VoucherCategory
  
  const [inputProps, setInputProps] = useState({
  
    MainVoucherID:{name: 'MainVoucherID',   value: props.MainVoucherID || undefined },

    VoucherID:{name: 'VoucherID',   value: props.VoucherID || '' ,enabled:false},

    VoucherType:{name: 'VoucherType',   value: props.Type  ,enabled:false},
    
    VoucherDate:{name: 'VoucherDate',   value: props.VoucherDate || 'currentDate' },

    AccountTitleCashBank:{
      name:props.Type == 'J' ? `AccountIDCashBank` : {id:'AccountIDCashBank',value:'AccountTitleCashBank'},
      caption: 'Select Account Cash Bank',
      value: props.Type == 'J' ? null : {id: props.AccountIDCashBank || undefined ,value:props.AccountTitleCashBank || ''},
      dataSource: {type:'Route',route:'ChartOfAccountVoucher'},
      isControlBlur:true
        },
    CurrencyName:{
      name: {id:'CurrencyID',value:'CurrencyName'},
      caption: 'Select Currency Name',
      value: {id: props.CurrencyID || undefined ,value:props.CurrencyName || ''},
      dataSource: {type:'Route',route:'Currency'},
      enabled : props.Type == 'J' ? true : false
        },

        // CurrencyID:{name: 'CurrencyID', caption: 'Currency ID' , value: props.CurrencyID },
        // CurrencyName:{name: 'CurrencyName', caption: 'Currency Name' , value: props.CurrencyName ,  enabled:false },

    InvoiceNo:{
      name:{id:'MainInvoiceID',value:'InvoiceNo'},
      caption: 'Select Invoice No',
      value: {id: props.MainInvoiceID || null ,value:props.InvoiceNo || ''},
      dataSource: {type:'Route',route:'Invoice'},
      isControlBlur:false
    },

    ShopName:{
      name:{id:'ShopID',value:'ShopName'},
      caption: 'Select Shop Name',
      value: {id: props.ShopID ,value:props.ShopName || ''},
      dataSource: {type:'Route',route:'Shop'},
      enabled:false
    },
    VoucherCategory:{
      name:props.Type  == 'J' ? {id:'VoucherCategoryID',value:'VoucherCategory'} : 'VoucherCategory',
      caption: 'Select Voucher Category',
      value: props.Type  == 'J' ?{id: props.VoucherCategory == 'ADJUSTMENT' ? 2:1 ,value:props.VoucherCategory || 'NORMAL'} :'NORMAL',
      dataSource: {type:'Data',data:[
        {id:1,value:'NORMAL'},
        {id:2,value:'ADJUSTMENT'}
      ]}
      
    },

      }
    );

    
// EXTRA CODE START
const getCurrencyValue = async (id)=>{
  try {
    const response = await DropdownService.getAllForList('Currency');
    if (response.data && response.data.recordset) {
            
              let filterData = response.data.recordset.filter(res=>res.id == id)
              console.log(filterData)
            setInputProps((prevValues) => ({
        ...prevValues,
        ['CurrencyName']: {...prevValues['CurrencyName'] ,value :{value:filterData[0].value,id:filterData[0].id}},
        // ['CurrencyID']: {...prevValues['CurrencyID'] ,value :filterData[0].id}
  
      }));
    }
      else {
      console.error("Invalid response structure:", response);
    }
   } catch (error) {
    }
}
const getVoucherIDValue = async ()=>{
    try {
      const response =   await voucherService.getVoucherIDValue(props.ShopID , props.Type == 'J' ?  'JournalVoucher' : props.Type == 'CP' ? 'PaymentVoucher' : 'ReceiptVoucher') ;
      if (response.data || response.data.recordset) {
        console.log(response)
        setInputProps((prevValues) => ({
          ...prevValues,
          ['VoucherID']: {...prevValues['VoucherID'] ,value :response.data.recordset[0]?.VoucherID }
    
        }));
      }
        else {
        console.error("Invalid response structure:", response);
      }
     } catch (error) {
      }
  }
// EXTRA CODE END

const handleInputCreated = async (e, name) => {
  if(name == 'VoucherID'  && props.isAdd  == true){
    return await getVoucherIDValue()
  }else  if(name ==  'CurrencyName' && props.Type == 'J'){
    
    return await getCurrencyValue(1)
  }
  console.log(e);
    console.log(`Input ${name} changed to ${e}`);
  };
const handleInputFocus = async (e, name) => {
  
    console.log(e);
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {
  if(name ==  'AccountTitleCashBank' && props.Type != 'J' &&  value.id != undefined){
    console.log(value)
    let accountID = inputProps.AccountTitleCashBank.value.id;
    console.log(accountID)
    return await  accountID == 953 ?  getCurrencyValue(1) : accountID == 954  ?  getCurrencyValue(2) : getCurrencyValue(value.CurrencyID)
  }
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
  if(name == 'VoucherCategory' && props.Type == 'J'){
    setTimeout(()=>document.getElementById('VoucherDetailGrid_add').click(),700)
  }else  if(name == 'InvoiceNo' && props.Type != 'J'){
    setTimeout(()=>document.getElementById('VoucherDetailGrid_add').click(),700)
  }
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {Input: HiddenComponent ,Props: {...inputProps.MainVoucherID, setInputProps },ColumnSize:0},

    {Input: props.isAdd == true ? HiddenComponent :  TextComponent,Props: {...inputProps.VoucherID, onCreated:handleInputCreated,setInputProps },ColumnSize:props.isAdd == true ? 0 : 4},

    {Input: DateComponent,Props: {...inputProps.VoucherDate, setInputProps }},

    {Input:props.Type == 'J' ? HiddenComponent : DropdownComponent,Props: {...inputProps.AccountTitleCashBank, onChange:handleInputChange,setInputProps },ColumnSize:props.Type == 'J' ? 0:4},
    
    { Input: HiddenComponent,Props: {...inputProps.VoucherType, setInputProps },ColumnSize:0},

    // { Input: HiddenComponent,Props: {...inputProps.CurrencyID, setInputProps }},
    
    { Input: DropdownComponent,Props: {...inputProps.CurrencyName, onCreated:handleInputCreated,setInputProps }},
    
    {Input: DropdownComponent,Props: {...inputProps.InvoiceNo,onBlur:handleInputBlur, setInputProps },},
    
    {Input: DropdownComponent,Props: {...inputProps.ShopName, setInputProps },},
    
    {Input:props.Type == 'J' ? DropdownComponent :HiddenComponent ,Props: {...inputProps.VoucherCategory,onBlur:handleInputBlur,setInputProps },ColumnSize:props.Type == 'J' ? 4 : 0}
    
    
  ];
// Detail Section Start

// MainVoucherID	
// AccountID	
// Debit	
// Credit	
// DetailRemarks	
// VoucherDetailID	
// MainTransferID

let MainTranferIDRef = useRef(null)
let addColumns =[
  { field:"MainTransferID",headerText:'Main Tranfer ID',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:false}]},
  

  { field:"AccountTitle",headerText:'Account Title',width:'120px',textAlign:'left' ,headerTextAlign:'center',other:[{'editType':'dropdownedit',edit:{
    caption:'Account Title' ,source:{type:"Route",dataSource:'ChartOfAccountVoucher'} , field:{id:'AccountID',value:'AccountTitle'}, filter:{},
    // select:{source:{type:'Function',dataSource:{function:shopVoucherService.getMultiDropDownSource , params :[{type:'Value',source:'Stock'},{type:'Value',source:'0'},{type:'Value',source:'01-JUN-24'},{type:'Value',source:moment().format('DD-MMM-YY')},{type:'Column',source:'BrandID'}] , dropDownKey:'ProductID'}}},
  }
  }]},
  { field:"AccountID",headerText:'Account ID',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[{visible:false}]},
  
  { field:"Debit",headerText:'Debit',width:'130px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n2','editType':'numericedit','visible':props.Type != 'CR'}]},
  
  { field:"Credit",headerText:'Credit',width:'130px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n2','editType':'numericedit','visible':props.Type != 'CP'}]},
  
  { field:"DetailRemarks",headerText:'Detail Remarks',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
  
  { field:"ShopTransferID",headerText:'Transfer',width:'150px' ,textAlign:'left' ,headerTextAlign:'center',other: [
    {   visible:props.Type == 'CP',
       editTemplate:(props)=>{
          // console.log(saleMainTranferIDDataSource)
          const ddlFields: object = { value: 'value' };
          return(
            <div>
            
              <AutoCompleteComponent 
                // dataSource={multiDropDownSource}
                fields={ddlFields}
                name='MainTranfer'
                id='MainTranfer'
                ref={MainTranferIDRef}
                // created={()=>setTimeout(() => {
                //   MainTranferIDRef.current.element.focus()
                // }, 200)}
                focus={()=>{if (MainTranferIDRef ) {setTimeout(() => {MainTranferIDRef.current.showPopup()}, 200)}
              }}
                floatLabelType='Auto' 
                highlight={true}
                itemTemplate={(data) => {
                  console.log(data);
                  return (
                    <table className='template-container'>
                      <tbody>
                        <tr className='template-row' >
                      <td className='template-cell' >{data.ShopTransferID}</td>
                      <td className='template-cell' style={{textAlign:'right'}}>{moment(data.ShopTransferDate).format('DD-MMM-YY')}</td>
                      <td className='template-cell'>{data.FromShopName}</td>
                      <td className='template-cell'>{data.ToShopName}</td>
                        </tr>
                      </tbody>
                    </table>
               
                  );
                }}
                headerTemplate={() => {
                  return (
                    <table className='template-container'>
        <thead>
          <tr className='header-row template-row' style={{paddingRight:'38px'}}>
            <th className='template-cell' style={{textAlign:'center',color:'#5CC95E'}}>Transfer ID</th>
            <th className='template-cell' style={{textAlign:'center',color:'#5CC95E'}}>Transfer Date</th>
            <th className='template-cell' style={{textAlign:'center',color:'#5CC95E'}}>From Shop</th>
            <th className='template-cell' style={{textAlign:'center',color:'#5CC95E'}}>To Shop</th>
          </tr>
        </thead>
      </table>
                  );
                }}
              />
            </div>
          )
        }
    }
  ] },
  

]
const callGridDataSource = (id)=>{
  let data ={
        FromDate:'01-JAN-01' ,
        ToDate:moment().format('DD-MMM-YY'),
        VoucherType:props.Type,
        MainVoucherID:id,
        VoucherID:0,
        AccountIDCashBank:0,
        CurrencyID:0,
        MainVoiceID:0,
        ShopID:0,
        VoucherCategory:'',
        AccountID:0,
        VoucherDetailID:0,
    }
    console.log(data)
  return shopVoucherService.getGridDataSource(data)
}
// EDIT TEMPLATE CODE HERE START
//8*********************************
//8*********************************
//8*********************************
//8*********************************
//8*********************************
//8*********************************


const getMainTranferIDDataSource =  async (mainTranferID)=>{
  try {
    console.log(mainTranferID)
    let response =  await shopVoucherService.getMultiDropDownSource()
      if(response.data  && response.data.recordset){
        console.log(response.data.recordset)
      MainTranferIDRef.current.dataSource = response.data.recordset
      if(mainTranferID){
        let value = response.data.recordset.filter(res => res.MainTransferID == mainTranferID)
        console.log(value)
      MainTranferIDRef.current.itemData = {value : value[0].value , id :value[0].id}
      document.getElementById('MainTranfer').value = value[0].value
      }
    }
    
  } catch (error) {
    console.log(error)
  }  
}

useEffect(()=>{
  setTimeout(()=>{
if(props.Type == 'J' && props.isAdd == true){

  document.querySelector('#VoucherGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
}
},500)

},[])
const addActionComplete = async (grid, args ,setDataSourceState ,getListService  , selectedRow   )=>{
    
  console.log(args)
  if(args.requestType == 'add' || args.requestType == 'beginEdit'){

  if(args.requestType == 'add' ){
    document.getElementById('VoucherDetailGridDetailRemarks').value = '-'
    if(props.Type == 'CP'){
      document.getElementById('VoucherDetailGridDebit').value = 0
    }else  if(props.Type == 'CR'){
      document.getElementById('VoucherDetailGridCredit').value = 0
    }else {
      document.getElementById('VoucherDetailGridCredit').value = 0
      // document.getElementById('VoucherDetailGrid_update').style.visibility ='hidden';
      document.getElementById('VoucherDetailGridDebit').value = 0

    }
    }
    if(props.Type == 'CP'){
    await getMainTranferIDDataSource(args.rowData?.MainTransferID)

    }
   
    document.getElementById('VoucherDetailGridCredit')?.addEventListener('focus', async (e)=>{
      console.log(MainTranferIDRef)
      if(MainTranferIDRef.current?.itemData?.id && props.Type == 'CP'){
        args.rowData.MainTransferID = MainTranferIDRef.current?.itemData?.id
        
      }else{
        args.rowData.MainTransferID = null
  
      }
     })
   
      document.getElementById('VoucherDetailGridDebit')?.addEventListener('focus', async (e)=>{
        console.log(MainTranferIDRef)
        if(MainTranferIDRef.current?.itemData?.id && props.Type == 'CP'){
          args.rowData.MainTransferID = MainTranferIDRef.current?.itemData?.id
          
        }else{
          args.rowData.MainTransferID = null
    
        }
       })
       if(props.Type == 'J'){
        // document.getElementById('VoucherDetailGrid_update').style.display ='none';
        const sumChecker =()=>{

          console.log(args.form[4].ej2_instances[0].enabled)
          console.log(args)
          if(document.getElementById('VoucherDetailGridDebit').value > 0){
            console.log(args)
              args.form[4].ej2_instances[0].enabled = false;
              args.form[2].ej2_instances[0].enabled = true;
            }else if (document.getElementById('VoucherDetailGridCredit').value > 0){
              args.form[4].ej2_instances[0].enabled = true;
              args.form[2].ej2_instances[0].enabled = false;
            }else{
              args.form[4].ej2_instances[0].enabled = true;
              args.form[2].ej2_instances[0].enabled = true
            }
          return true
        }
        document.getElementById('VoucherDetailGridDebit').addEventListener('keydown',sumChecker)      
        document.getElementById('VoucherDetailGridCredit').addEventListener('keydown',sumChecker)      
        document.getElementById('VoucherDetailGridDetailRemarks').addEventListener('blur', async (e)=>{
          console.log(sumChecker())
          setTimeout(() => document.getElementById('VoucherDetailGridDetailRemarks') && sumChecker() && grid?.endEdit(), 200);
        })      

       }
       else if(props.Type == 'CR'){
       
  document.getElementById('VoucherDetailGridDetailRemarks').addEventListener('blur', async (e)=>{
    setTimeout(() => document.getElementById('VoucherDetailGridDetailRemarks') && grid?.endEdit(), 200);

  })
}else if(props.Type == 'CP'){
  document.getElementById('MainTranfer').addEventListener('blur',  (e)=>{
    console.log(MainTranferIDRef.current?.itemData)
  if(MainTranferIDRef.current?.itemData?.id && props.Type == 'CP'){
        args.rowData.MainTransferID = MainTranferIDRef.current?.itemData?.id
          setTimeout(() => document.getElementById('MainTranfer') && grid?.endEdit(), 200);
          
        }else{
          args.rowData.MainTransferID = null
          setTimeout(() => document.getElementById('MainTranfer') && grid?.endEdit(), 200);
          
        }
      })
  
  // document.getElementById('MainTranfer').addEventListener('blur', async (e)=>{
  //   setTimeout(() => document.getElementById('MainTranfer') && grid?.endEdit(), 200);

  // })
}
  }
  
  console.log(inputProps)
  
  
  console.log(args)
  console.log(selectedRow)
  if(args.requestType == 'save' || args.requestType == 'cancel'){
    document.getElementById('VoucherGrid_dialogEdit_wrapper_dialog-content').scrollBottom = 0;
}
  if(args.requestType == 'save'){
    let inputProp = {}
        setInputProps((prevValues) => {
          console.log(prevValues);
          inputProp = prevValues
          return {...prevValues
          // ['MainInvoiceID']: {...prevValues['MainInvoiceID'] ,value :response.data.MainInvoiceID }    
        }});
    console.log(inputProp)
    function convertToMozambiqueTime(dateString) {
      // Parse the date string with the GMT offset
      // const gmtDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
      
      // // Convert the parsed date to Mozambique time zone (Africa/Maputo)
      // const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
      
      // return dateInMozambique;

      const gmtDate = moment(dateString, "DD-MMM-YY");

      // Convert the parsed date to Mozambique time zone (Africa/Maputo)
      const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
  
      return dateInMozambique;
  }
    let  data = {
      MainVoucherID:inputProp.MainVoucherID.value,
      VoucherID	:inputProp.VoucherID.value,
      VoucherType	:inputProp.VoucherType.value,
      VoucherDate	:convertToMozambiqueTime(inputProp.VoucherDate.value),
      AccountIDCashBank	:props.Type  == 'J' ? inputProp.AccountTitleCashBank.value : inputProp.AccountTitleCashBank.value.id,
      CurrencyID	:inputProp.CurrencyName.value.id,
      MainInvoiceID	:inputProp.InvoiceNo.value.id || null,
      ShopID	:inputProp.ShopName.value.id,
      VoucherCategory:props.Type  == 'J' ? inputProp.VoucherCategory.value.value : inputProp.VoucherCategory.value,
      
      //DETAIL
      AccountID	:args.data.AccountID,
      Debit	:args.data.Debit || 0,
      Credit	:args.data.Credit || 0,
      DetailRemarks	:args.data.DetailRemarks,
      MainTransferID:args.data.MainTransferID,
      VoucherDetailID	:args.data?.VoucherDetailID
      
}
console.log(data)
if(args.action == 'add'){
  const response = data.MainVoucherID == undefined ? await voucherService.addVoucher(data) : await voucherService.addGridVoucher(data);
          console.log(response)
          if(data.MainVoucherID == undefined){
          setInputProps((prevValues) => ({
            ...prevValues,
            ['MainVoucherID']: {...prevValues['MainVoucherID'] ,value:response.data.MainVoucherID }    
          }))}
          await getListService()
          document.getElementById('VoucherGrid_dialogEdit_wrapper_dialog-content').scrollBottom = 0;

          if(props.Type == 'J'){
          let dataSourceGrid =grid.dataSource
          const totalDebit = dataSourceGrid.reduce((sum, dataSourceGrid) => sum + dataSourceGrid.Debit, 0);
          const totalCredit = dataSourceGrid.reduce((sum, dataSourceGrid) => sum + dataSourceGrid.Credit, 0);
          if(totalCredit == totalDebit){
            document.querySelector('#VoucherGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
            
          }else{
            document.querySelector('#VoucherGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
            }
            }
          setTimeout(()=>document.getElementById('VoucherDetailGrid_add').click(),700)

}
if(args.action == 'edit'){
  const response = await voucherService.editGridVoucher(data);
          console.log(response)
          await getListService()
          document.getElementById('VoucherGrid_dialogEdit_wrapper_dialog-content').scrollBottom = 0;

          if(props.Type == 'J'){
          let dataSourceGrid =grid.dataSource
          const totalDebit = dataSourceGrid.reduce((sum, dataSourceGrid) => sum + dataSourceGrid.Debit, 0);
          const totalCredit = dataSourceGrid.reduce((sum, dataSourceGrid) => sum + dataSourceGrid.Credit, 0);
          if(totalCredit == totalDebit){
            document.querySelector('#VoucherGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
            
          }else{
            document.querySelector('#VoucherGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
            }
            }
}
  console.log(data)

    console.log(inputProp)
  }
}



// Usage in your main component



  return (
    <div onKeyDown={(e)=>e.stopPropagation()}>
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   mainProps={props} title={`${props.Type == 'J' ? `Journal Voucher` : props.Type == 'CR' ? `CASH RECEIPT` : `CASH PAYMENT`} ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
       {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging  */}
      <IrtizaDynamicInputGrid
        Id='VoucherDetail'
        
        ColumnsList={addColumns}
        GridDataSource= {[
          {
        DataSourceType:inputProps.MainVoucherID.value ? 'Function' : 'REST',
        DataSource:  ()=>  callGridDataSource(inputProps.MainVoucherID.value) ,
        // CreateColumns: [{columnName:'IsEdit',columnValue:false}]
         }]
  }
        DefaultSortedColumnName={'VoucherDetailID'}
      
        ListActionComplete={addActionComplete}

        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
        AllowTemplate={false}
        ToolbarOption={['Add','Edit','Delete','Update','Cancel']}
        AllowGrouping={false}
        AllowExcelExport={false}
        AllowPdfExport={false} 
        ShowColumnChooser={false}
        AllowPaging={true}
        AllowDataBound={false}
        ListSecurity={'ListSecurity'}
        AddSecurity={'VoucherList'}
        EditSecurity={'VoucherList'}
        DeleteSecurity={'VoucherList'}
        DefaultSortedColumnSort={'Descending'}
        FilterColumns={[]} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
        GroupColumns={[]} 
        ListDelete={[]}
        DeleteType={'hard'} 
        GridEditSettingMode={'Normal'}
        GridEditSettingHeaderTemplate={'<p></p>'}
        GridSumColumns={[]}
        AllowGridSum={true}
            {...props}
            ></IrtizaDynamicInputGrid>
    </div>
  );
}; 

export default VoucherAdd;
