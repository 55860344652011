import React ,{useRef ,useEffect}from 'react';
import './system-lock.scss'
import DynamicInputList from '../dynamic-input/dynamic-input-list';
import moment from 'moment'
import SystemLockService from './system-lock.service';
import AuditTableInput from '../audit-table/audit-table'


const SystemLockInput = (props) => {
  // SystemLockID	
  // SystemLockDate
    let columns =[
        {  field: "SystemLockID",headerText:'ID',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[{allowEditing:false,visible:false}]},
        {  field: "SystemLockDate",headerText:'System Lock Date',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[{editType:'datepickeredit'}]},
      ]

       const listActionComplete = (grid,args ,getListService ,selectedRow )=>{
        console.log(args )
        console.log(getListService )
        console.log(selectedRow )
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          }
          if (args.requestType == 'save' || args.requestType == 'cancel' ) {
            getListService()
  
          }
          if(args.requestType == 'save' && args.data.SystemLockDate != 'undefined'){
            let data = {
              SystemLockID:args.data.SystemLockID,
              SystemLockDate:moment(args.data.SystemLockDate).format('DD-MMM-YY'),
              }
            console.log(data)
          if(args.action == 'edit' ){
            SystemLockService.update(data).then((res)=>{
              getListService()
  
              // let EditData = {
              //     UnitOfMeasurementID:args.data.UnitOfMeasurementID,
              //     UnitOfMeasurement:args.data.UnitOfMeasurement
              // }
              // let totalColumns = Object.keys(EditData);
              //             console.log(totalColumns)
              //             AuditTableInput.auditEdit(totalColumns,selectedRow,EditData,`UnitOfMeasurement`,args.data.MainUnitOfMeasurementID)
            })
          }
          }
          
       }
       //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************

        // Defines the  boolean
        // @default true
    let  allowTemplate = false
    let  allowGrouping = false
    let  allowExcelExport =true
    let  allowPdfExport = true
    let  showColumnChooser = true
    let  allowPaging = false
    let  allowDataBound = true
    let  allowGridSum = true

       
        // Defines the  string
        // @default SystemLock${typeMode} 
        let ListSecurity='ShopTransferList'
        let AddSecurity=''
        let EditSecurity='ShopTransferList'
        let DeleteSecurity=''
       
       // Defines the  ['Add','Edit','Delete','Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser'] | 'All'
       //  @default 'All'
      let toolbarOptions =['Edit','Update','Cancel','PDFExport','ExcelExport'];

      const toolbarClicking =  async (args)=>{
        console.log(args)
      }
      
       // Defines the 'Descending' | 'Ascending'
       //  @default 'Descending'
      let DefaultSortedColumnSort ='Descending';

       /* Defines the [{
                    TableName:'SystemLock' ,
                    TableID:'SystemLockID',
                   // Optional if Delete is Soft // TableSecurity:'SystemLockDelete',
                }]*/
    //  @default [] 
    //   let listDelete =  [{ TableName:'SystemLock' ,TableID:'SystemLockID', Optional // TableSecurity:'SystemLockDelete'}]
    let listDelete = [];
       
    // Defines 'Hard' | 'Soft' 
    // @Default 'Hard'
    let deleteType = 'Hard'


/* Defines the [   {field:'IsDeleted',
OPTIONAL HAI operator:'equal' or more check syncfusion documentation
        value:'No'},...more
        ] | []
      @default [] */
    //   let filterColumns = [{ field: 'IsDeleted', value: 'No' }];
      let filterColumns = [];

      /* Defines the [ColumnNames] | []
      @default [] */
    //   let groupColumns = ['IsDeleted','AccountTitleHawalaDr'];
      let groupColumns = [];

/* Defines the 'Dialog' | 'Normal'
      @default 'Dialog' */
      let gridEditSettingMode = 'Normal';

/* Defines the '<p></p>' | Can give HTML | AND REACT FUNCTION LIKE HeaderTemplate
      @default '<p></p>' */
      let gridEditSettingHeaderTemplate = '<p></p>';

      /* Defines the  [ColumnNames] | []
      @default [] */
           //   let gridSumColumns = ['HawalaCommissionPercent'];
      let gridSumColumns = [];


    return (
        <DynamicInputList
        Id='SystemLock'
        ListTitle='System Lock - List'
        ColumnsList={columns}
        GridDataSource= {[
                    {
                        DataSourceType: 'Function',
                        DataSource:  ()=> SystemLockService.getSystemLockForList()
                        }]}
        DefaultSortedColumnName={'SystemLockID'}
        ListActionComplete={listActionComplete}
        ToolbarClicking ={toolbarClicking}

        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
            AllowTemplate={allowTemplate}
            ToolbarOption={toolbarOptions}
            AllowGrouping={allowGrouping}
            // AllowExcelExport={allowExcelExport}
            // AllowPdfExport={allowPdfExport} 
            // ShowColumnChooser={showColumnChooser}
            AllowPaging={allowPaging}
            AllowDataBound={allowDataBound}
            // ListSecurity={ListSecurity}
            // AddSecurity={AddSecurity}
            // EditSecurity={EditSecurity}
            // DeleteSecurity={DeleteSecurity}
            // DefaultSortedColumnSort={DefaultSortedColumnSort}
            // FilterColumns={filterColumns} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
            // GroupColumns={groupColumns} 
            // ListDelete={listDelete}
            // DeleteType={deleteType} 
            GridEditSettingMode={gridEditSettingMode}
            // GridEditSettingHeaderTemplate={gridEditSettingHeaderTemplate}
            // GridSumColumns={gridSumColumns}
            // AllowGridSum={allowGridSum}
            {...props}
            ></DynamicInputList>
            
            );
}



export default SystemLockInput;
