import React ,{useState} from 'react';
import SecurityService from  '../security/security.service'
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import './dynamic-input.scss'
import Shop from '../shop/shop-list';
import Brand from '../brand/brand-list';
// import BrandVendor from '../brand-vendor/brand-vendor';
import UnitOfMeasurement from '../unit-of-measurement/unit-of-measurement-list';
const IrtizaDynamicAddnew = (props) => {
    console.log(props)
    const [showAddNewDialog, setShowAddNewDialog] = useState(false);
    const [dialogType, setDialogType] = useState('');
    const [addNewClicked, setAddNewClicked] = useState(false); 

    const onAddNewClick = (type) => {
        console.log('type: ', type);
        if(type !== ''){
          setAddNewClicked(true)
          setShowAddNewDialog(false);
          setDialogType(type);
          setTimeout(() => {
            setShowAddNewDialog(true);
          },100)
        }
      };
      const header = () => {
        if(showAddNewDialog) {
          return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
        }
        else {
          return (<></>);
        }
      };
    
      const onClose = () => {
        setAddNewClicked(false)
        setShowAddNewDialog(false);
        switch(dialogType){
          case 'ProducdtCategory':
            props.onClosed(props.RefName)
            // getProductCategory();
          break;
          case 'ProductDesc':
            // getProductDesc();
          break;
          case 'd':
            // getUOMs();
          break;
          default:
            console.log(props.RefName)
            props.onClosed(props.RefName)

            break;
        } 
      }
    
      const footerTemplate = () => {
        if(showAddNewDialog){
          return (<div>
            <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
          </div>);
        }
        else{<h3 style={{ textAlign:'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
           return (<></>);
        }
      };
    
      const contentTemplate = (prop) => {
        console.log('props: ', prop);
        if(showAddNewDialog){
          if(props.Route == 'Brand') {

            return (
              <div>
                <Brand IsDialog={true}/> 
                {/* <SetupAdd onChange={value => setWord(value)}*/}
              </div>
            );  
          }
          else if(props.Route == 'BrandVendor'){
    // return(<BrandVendor IsDialog={true}/> )
    
          }
          else if(props.Route == 'UnitOfMeasurement'){
    return(<UnitOfMeasurement IsDialog={true}/> )
          }
          else{
            
        
          console.log('dialog template');

          return (
            <div>
              <Shop IsDialog={true}/> 
              {/* <SetupAdd onChange={value => setWord(value)}*/}
            </div>
          );  
        }}
        else {
          return (
            <></>
          );
        }
      };
    
    const AddNewSecurity = (addNewType) => {
        var res =""
          switch(SecurityService.canUserAccess(`${addNewType}List`)){
          case true:
            //('true')
           return res = 'ADD NEW';
          default:
            break;
        } 
       return res
      }
    return (
        <>
         <div className='App' id='targetElement'>
          
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>
            <span className='add-new' onClick={(e)=>{
              e.preventDefault()
              onAddNewClick(props.Route)
        //       let newTab = window.open('http://localhost:3000/Setup/Shop', '_slef');
        // newTab.focus()
              }}>
                            {AddNewSecurity(props.Security)}
                            </span>
                           {/* Route:{props.Route} , Security:{props.Security} irtiza add new */}
        </>
    );
}

export default IrtizaDynamicAddnew;
