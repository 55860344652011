import React ,{useRef ,useEffect}from 'react';
import './currency.scss'
import DynamicInputList from '../dynamic-input/dynamic-input-list';
import moment from 'moment'
import SecurityService from '../security/security.service';
import CurrencyService from '../currency/currency.service';
import CurrencyAdd from './currency-add';
import AuditTableInput from '../audit-table/audit-table'


const CurrencyInput = (props) => {

  // CurrencyID	
  // CurrencyName	
  // AccountID
    let columns =[
        {  field: "CurrencyID",headerText:'ID',width:'140px',textAlign:'right' ,headerTextAlign:'center',other:[]},
        {  field: "CurrencyName",headerText:'Currency',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "AccountTitle",headerText:'Account Title',width:'160px',textAlign:'left' ,headerTextAlign:'center',other:[]},
      ]

       const listActionComplete = (grid,args ,getListService ,selectedRow )=>{
        console.log(args )
        console.log(getListService )
        console.log(selectedRow )
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          }
          if (args.requestType == 'save' || args.requestType == 'cancel' ) {
            getListService()
  
          }
          if(args.requestType == 'save'){
            let data = {
              CurrencyID:args.data.CurrencyID,
              CurrencyName:args.data.CurrencyName,
              AccountID:args.data.AccountID,
              AccountTitle:args.data.AccountTitle
            }
            console.log(data)
          if(args.action == 'add'){
            CurrencyService.insert(data).then(async (res)=>{
              await getListService()
              // let AddData = {
              //   UnitOfMeasurementID:args.data.UnitOfMeasurementID,
              //   UnitOfMeasurement:args.data.UnitOfMeasurement,
              // }
              // AuditTableInput.auditAdd(`UnitOfMeasurement`,`UnitOfMeasurement`,`UnitOfMeasurementID`,AddData)
            })
          }
          if(args.action == 'edit'){
            CurrencyService.update(data).then((res)=>{
              getListService()
  
              // let EditData = {
              //     UnitOfMeasurementID:args.data.UnitOfMeasurementID,
              //     UnitOfMeasurement:args.data.UnitOfMeasurement
              // }
              // let totalColumns = Object.keys(EditData);
              //             console.log(totalColumns)
              //             AuditTableInput.auditEdit(totalColumns,selectedRow,EditData,`UnitOfMeasurement`,args.data.MainUnitOfMeasurementID)
            })
          }
          }
          
       }
       //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************

        // Defines the  boolean
        // @default true
    let  allowTemplate = true
    let  allowGrouping = true
    let  allowExcelExport =true
    let  allowPdfExport = true
    let  showColumnChooser = true
    let  allowPaging = false
    let  allowDataBound = true
    let  allowGridSum = false

       
        // Defines the  string
        // @default Currency${typeMode} 
        let ListSecurity='CurrencyTransferList'
        let AddSecurity='CurrencyTransferAdd'
        let EditSecurity='CurrencyTransferEdit'
        let DeleteSecurity='CurrencyTransferDelete'
       
       // Defines the  ['Add','Edit','Delete','Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser'] | 'All'
       //  @default 'All'
      let toolbarOptions ='All';

      const toolbarClicking =  async (args)=>{
        console.log(args)
      }
      
       // Defines the 'Descending' | 'Ascending'
       //  @default 'Descending'
      let DefaultSortedColumnSort ='Descending';

       /* Defines the [{
                    TableName:'Currency' ,
                    TableID:'CurrencyID',
                   // Optional if Delete is Soft // TableSecurity:'CurrencyDelete',
                }]*/
    //  @default [] 
    //   let listDelete =  [{ TableName:'Currency' ,TableID:'CurrencyID', Optional // TableSecurity:'CurrencyDelete'}]
    let listDelete = [];
       
    // Defines 'Hard' | 'Soft' 
    // @Default 'Hard'
    let deleteType = 'Hard'


/* Defines the [   {field:'IsDeleted',
OPTIONAL HAI operator:'equal' or more check syncfusion documentation
        value:'No'},...more
        ] | []
      @default [] */
    //   let filterColumns = [{ field: 'IsDeleted', value: 'No' }];
      let filterColumns = [];

      /* Defines the [ColumnNames] | []
      @default [] */
    //   let groupColumns = ['IsDeleted','AccountTitleHawalaDr'];
      let groupColumns = [];

/* Defines the 'Dialog' | 'Normal'
      @default 'Dialog' */
      let gridEditSettingMode = 'Dialog';

/* Defines the '<p></p>' | Can give HTML | AND REACT FUNCTION LIKE HeaderTemplate
      @default '<p></p>' */
      let gridEditSettingHeaderTemplate = '<p></p>';

      /* Defines the  [ColumnNames] | []
      @default [] */
           //   let gridSumColumns = ['HawalaCommissionPercent'];
      let gridSumColumns = [];


    return (
        <DynamicInputList
        Id='Currency'
        ListTitle='Currency - List'
        ColumnsList={columns}
        GridDataSource= {[
                    {
                        DataSourceType: 'Function',
                        DataSource:  ()=> CurrencyService.getCurrencyForList()
                        }]}
        DefaultSortedColumnName={'CurrencyID'}
        GridDialog={[
            {
                DialogType:'Self',
                Dialog:<CurrencyAdd  />
                // Dialog:[
                //   {
                //     Title:'Invoice Evaluation',
                //     Inputs:[
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'CurrencyID',value:'CurrencyName'},type:'dropdown',dataSource:{type:'Route',Route:'CurrencyName'},defaultValues:null},
                //       {field:'ExchangeRate'	,type:'float',defaultValues:null},
                //       {field:'HawalaCommissionPercent'	,type:'float',defaultValues:null},
                //       {field:{id:'CurrencyIDHawala',value:'CurrencyNameHawala	'},type:'dropdown',dataSource:{type:'Route',Route:'CurrencyName'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null}
                //     ]
                //   }
                // ]

            }
        ]}
        ListActionComplete={listActionComplete}
        ToolbarClicking ={toolbarClicking}

        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
            // AllowTemplate={allowTemplate}
            // ToolbarOption={toolbarOptions}
            // AllowGrouping={allowGrouping}
            // AllowExcelExport={allowExcelExport}
            // AllowPdfExport={allowPdfExport} 
            // ShowColumnChooser={showColumnChooser}
            // AllowPaging={allowPaging}
            AllowDataBound={allowDataBound}
            // ListSecurity={ListSecurity}
            // AddSecurity={AddSecurity}
            // EditSecurity={EditSecurity}
            // DeleteSecurity={DeleteSecurity}
            // DefaultSortedColumnSort={DefaultSortedColumnSort}
            // FilterColumns={filterColumns} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
            // GroupColumns={groupColumns} 
            // ListDelete={listDelete}
            // DeleteType={deleteType} 
            // GridEditSettingMode={gridEditSettingMode}
            // GridEditSettingHeaderTemplate={gridEditSettingHeaderTemplate}
            // GridSumColumns={gridSumColumns}
            // AllowGridSum={allowGridSum}
            {...props}
            ></DynamicInputList>
            
            );
}



export default CurrencyInput;
