import { configureStore } from '@reduxjs/toolkit'
import listTemplate from './slices/list-tempate'
import userInput from './slices/user-input'
import fabricProcessingContract from './slices/fabric-processing-contract'
import Head from './slices/head';
import ThemeUpdate from './slices/theme';

let store = configureStore({
    reducer:{
        listTemplate,
        userInput,
        fabricProcessingContract,
        Head,
        ThemeUpdate
    }
})

export default store