import React, { useRef, useState , useEffect,useMemo} from 'react';
import moment from 'moment';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../dynamic-input/dynamic-main-inputs';

const UsersAdd = (props) => {
  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};

  const [inputProps, setInputProps] = useState({
    UserID:{name: 'UserID',   value: props.UserID || '' ,enabled:false},
    UserName:{name: 'UserName',   value: props.UserName || '' },
    UserPassword:{name: 'UserPassword',   value: props.UserPassword || '' ,type:'password'},
    ShopName:{
      name: {id:'ShopID',value:'ShopName'},
      caption: 'Select Shop Name',
      value: {id: props.ShopID || undefined ,value:props.ShopName || ''},
      dataSource: {type:'Route',route:'Shop'},
      isControlBlur:true,
      addNew:{Route:'Shop',Security:'Shop'}
        },   
    });
// EXTRA CODE START
// EXTRA CODE END
const handleInputCreated = async (e, name) => {
    console.log(`Input ${name} changed to ${e}`);
  };

const handleInputFocus = async (e, name) => {
    console.log(`Input ${name} focus to ${e}`);
  };

const handleInputChange = async (value, name) => {
   console.log(`Input ${name} focus to ${value}`);
  };

const handleInputBlur = async  (value, name) => {
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {Input: HiddenComponent ,Props: {...inputProps.UserID, setInputProps },ColumnSize:0},
    {Input: TextComponent,Props: {...inputProps.UserName,setInputProps },ColumnSize:6},
    {Input: TextComponent,Props: {...inputProps.UserPassword,setInputProps },ColumnSize:6},
    {Input: DropdownComponent,Props: {...inputProps.ShopName,setInputProps } ,ColumnSize:12},
    ];
  return (
    <div onKeyDown={(e)=>e.stopPropagation()}>
      <StructuredGenerate   mainProps={props} title={`Users ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
      </div>
  );
};  
export default UsersAdd;