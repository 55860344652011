import React, { useEffect, useState , useRef} from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Grid from '@material-ui/core/Grid';
import chartOfAccountService from '../../services/chart-of-account.service';
import ChartOfAccountAdd from './chart-of-account-add';
import './chart-of-account.scss';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import  imgSrc from './spinner.gif';

export default function ChartOfAccount() {
  const [accountCodes, setAccountCodes] = useState([]);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [outerText, setOuterText] = useState(undefined);
  const [id, setId] = useState('');
  const [searchBarValue, setSearchBarValue] = useState('');

  const [expanded, setExpanded] = useState([]);


  const treeviewRef = useRef(null);

  // Fetch data with cleanup to avoid memory leaks
  let isMounted = true;
  useEffect(() => {

  

    getAccessoryTransfer();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, []);
  const getAccessoryTransfer = async () => {
    try {
      setLoadingAccount(true)
      const response = await chartOfAccountService.getList();
      if (isMounted) {
        setAccountCodes(response.data.recordset);

        setLoadingAccount(false)
       
          
        setTimeout(() => {
          if(id == ''){
            
          }else{
            console.log(id)
            expandByIdPattern(id)

            const sidebar = document.getElementById('tree-view-chartOfAccount');

            sidebar.style.opacity = '0';
            
                // After 2 seconds, change the opacity to 1
                setTimeout(() => {
                    sidebar.style.opacity = '1';
                    sidebar.style.transition = 'opacity 0.3s linear';
                }, 800);
            
          }

        }, 1000);
      }
    } catch (error) {
      if (isMounted) {
        console.log(error);
        setLoadingAccount(false)
      }
    }
  };
 

  function createTreeData(accountCodes) {
    const treeData = [];
  
    function findChildren(parentCode, parentLevel) {
      const children = [];
  
      accountCodes.forEach((account) => {
        if (
          account.AccountCode.startsWith(parentCode) &&
          account.AccountCode.length === parentLevel + 2
        ) {
          const child = {
            id: account.AccountCode,
            label: account.AccountTitleShort,
          };
  
          const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
          if (childChildren.length > 0) {
            child.children = childChildren;
          }
  
          children.push(child);
        }
        if (
          account.AccountCode.startsWith(parentCode) &&
          account.AccountCode.length == 8 &&
          account.AccountCode.length === parentLevel + 3
        ) {
          const child = {
            id: account.AccountCode,
            label: account.AccountTitleShort,
          };
  
          const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
          if (childChildren.length > 0) {
            child.children = childChildren;
          }
  
          children.push(child);
        }
        if (
          account.AccountCode.startsWith(parentCode) &&
          account.AccountCode.length == 12 &&
          account.AccountCode.length === parentLevel + 4
        ) {
          const child = {
            id: account.AccountCode,
            label: account.AccountTitleShort,
          };
  
          const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
          if (childChildren.length > 0) {
            child.children = childChildren;
          }
  
          children.push(child);
        }
      });
  
      return children;
    }
  
    accountCodes.forEach((account) => {
      if (account.AccountCode.length < 2) {
        const parent = {
          id: account.AccountCode,
          label: account.AccountTitleShort,
        };
        console.log(account.AccountCode, account.AccountCode.length)
        const parentChildren = findChildren(account.AccountCode, account.AccountCode.length);
        if (parentChildren.length > 0) {
          parent.children = parentChildren;
        }
  
        treeData.push(parent);
      }
    });
  
    return treeData;
  }
  
  const treeData = createTreeData(accountCodes);

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.label}
      onClick={(e) => {
        const clickedText = e.target.outerText;
        if (clickedText) {
          setOuterText(clickedText);
        }
      }}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const handleToggle = (event, nodeIds) => {
    if (event.target.tagName === 'svg' || event.target.tagName === 'path') {
      setExpanded(nodeIds);
    }
  };

  console.log(treeData)
  const fields = { dataSource: treeData, id: 'id', text: 'label', child: 'children' };

    const [selectedNode, setSelectedNode] = useState(null); // Store reference to the selected node
    const nodeClicked = (args) => {
      const clickedNode = args.node;
  
      // If there is a previously selected node, remove the 'red-node' class
      if (selectedNode) {
        selectedNode.classList.remove('dark-node');
      }
  
      // Add 'dark-node' class to the currently clicked node
      clickedNode.classList.add('dark-node');
  
      // Update the state to store the currently clicked node
      setSelectedNode(clickedNode);
    };


  const onNodeClicked = (args) => {
    const clickedNode = args.nodeData;
    console.log(args)
    console.log(clickedNode)
    console.log('ID:', clickedNode.id);
    console.log('Name:', clickedNode.text);
    setId(clickedNode.id)
    setOuterText(clickedNode.text)
  };
  
  const expandByIdPattern = async (id) => {
    const treeObj = treeviewRef.current;
    treeObj.collapseAll()

    // Split the ID progressively: '2', '20', '201', '2010', '20101', '201010', etc.
    let part = '';
    for (let i = 0; i < id.length; i++) {
        part += id[i];  // Add one character at a time to 'part'

        // Delay between each expansion to ensure it's processed step-by-step
        await expandNode(treeObj, part);
    }
};

// Function to expand a node given its ID
const expandNode = (treeObj, nodeId) => {
  console.log(treeObj)
  console.log(treeviewRef)
    return new Promise((resolve) => {
        // Find the node in the DOM by its data-uid
        const nodeElement = treeObj.element.querySelector(`[data-uid="${nodeId}"]`);
        // Highlight the node
        if (nodeElement) {
            nodeElement.classList.add('dark-node');
            
            setTimeout(() => nodeElement.classList.remove('dark-node'), 1000);
        }

        // If the node exists and is collapsed, expand it
        if (nodeElement && !nodeElement.classList.contains('e-expandable')) {
            treeObj.expandAll([nodeElement]);  // Expand the node
            
        }
        // Wait a bit before resolving to ensure node expansion is handled
        setTimeout(resolve, 300);
    });
};

const  findLabel = (tree, label) => {
  for (const node of tree) {
    if (node.label.toLowerCase() === label.toLowerCase()) {
      expandByIdPattern(node.id)      
              }
    
    if (node.children) {
      const result = findLabel(node.children, label);
      if (result) {
        console.log(result);
        expandByIdPattern(result.id)           

      }
    }
  }
  // return null;
}
  return (
    <div id='chart-of-account'>
       <Grid item xs={12}>
          <div className='heading'>
            <h3 style={{ textAlign:'center', width: '100%' , padding: '0px'}}>Chart Of Account</h3>
          </div>
          
        </Grid>
        <div className='search-bar-chOfAcc col-sm-3'>
                {/* <pre>{JSON.stringify(treeData, null, 2)}</pre> This is to display current state for debugging */}

        <TextBoxComponent
          name='search-bar'
          value={searchBarValue}
          placeholder='Search Account'
          floatLabelType='Auto'
          onChange={(e)=>{
            console.log(treeData)
            findLabel(treeData ,e.value)
            setSearchBarValue(e.value)
          }}
        />
        </div>
    <div className='chart-of-account-wrapper'>
      <div className='tree-view-chart-of-accoutn' id='tree-view-chartOfAccount'>
  { loadingAccount == false ?
      <TreeViewComponent 
                        fields={fields}
                        nodeClicked={nodeClicked} 
                        nodeSelecting={onNodeClicked}
                        ref={treeviewRef}
                       />
:
<div className='loader-div'> <img src={imgSrc} id='' /> LOADING...</div>
}
        </div>
        
        <ChartOfAccountAdd expandByIdPattern={expandByIdPattern} id={id} TreeViewValue={outerText} callTreeView={() => getAccessoryTransfer()} dataList={accountCodes} />    
    </div>
    </div>

    
      
  );
}























































// import React, { useEffect, useRef,  useState } from 'react';
// import PropTypes from 'prop-types';
// import SvgIcon from '@mui/material/SvgIcon';
// import { alpha, styled } from '@mui/material/styles';
// import TreeView from '@mui/lab/TreeView';
// import TreeItem from '@mui/lab/TreeItem';
// import Collapse from '@mui/material/Collapse';
// import { useSpring, animated } from '@react-spring/web';
// import './chart-of-account.scss'
// import chartOfAccountService from '../../services/chart-of-account.service';
// import ChartOfAccountAdd from './chart-of-account-add';
// import Grid from '@material-ui/core/Grid';

// function MinusSquare(props) {
//   return (
//     <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
//       {/* tslint:disable-next-line: max-line-length */}
//       <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
//     </SvgIcon>
//   );
// }

// function PlusSquare(props) {
//   return (
//     <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
//       {/* tslint:disable-next-line: max-line-length */}
//       <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
//     </SvgIcon>
//   );
// }

// function CloseSquare(props) {
//   return (
//     <SvgIcon
//       className="close"
//       fontSize="inherit"
//       style={{ width: 14, height: 14 }}
//       {...props}
//     >
//       {/* tslint:disable-next-line: max-line-length */}
//       <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
//     </SvgIcon>
//   );
// }

// export default function ChartOfAccount(props) {
// console.log(props)
//   const [accountCodes, setAccountCodes] = useState([]);
//   const [outerText, setOuterText] = useState(undefined);
//   useEffect(() => {
//     getAccessoryTransfer();


// }, []);


// const getAccessoryTransfer = async (type: string) => {
//     chartOfAccountService.getList()
//         .then((response) => {
//           console.log( response.data.recordset);
          
//           setAccountCodes(response.data.recordset)
//         })
//         .catch((e) => {
//             console.log(e);
//         });
// };

// useEffect(() => {

//     console.log(outerText)


  
 
// }, [outerText]);

// function createTreeData(accountCodes) {
//   const treeData = [];

//   function findChildren(parentCode, parentLevel) {
//     const children = [];

//     accountCodes.forEach((account) => {
//       if (
//         account.AccountCode.startsWith(parentCode) &&
//         account.AccountCode.length === parentLevel + 2
//       ) {
//         const child = {
//           id: account.AccountCode,
//           label: account.AccountTitleShort,
//         };

//         const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
//         if (childChildren.length > 0) {
//           child.children = childChildren;
//         }

//         children.push(child);
//       }
//       if (
//         account.AccountCode.startsWith(parentCode) &&
//         account.AccountCode.length == 8 &&
//         account.AccountCode.length === parentLevel + 3
//       ) {
//         const child = {
//           id: account.AccountCode,
//           label: account.AccountTitleShort,
//         };

//         const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
//         if (childChildren.length > 0) {
//           child.children = childChildren;
//         }

//         children.push(child);
//       }
//       if (
//         account.AccountCode.startsWith(parentCode) &&
//         account.AccountCode.length == 12 &&
//         account.AccountCode.length === parentLevel + 4
//       ) {
//         const child = {
//           id: account.AccountCode,
//           label: account.AccountTitleShort,
//         };

//         const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
//         if (childChildren.length > 0) {
//           child.children = childChildren;
//         }

//         children.push(child);
//       }
//     });

//     return children;
//   }

//   accountCodes.forEach((account) => {
//     if (account.AccountCode.length < 2) {
//       const parent = {
//         id: account.AccountCode,
//         label: account.AccountTitleShort,
//       };
//       console.log(account.AccountCode, account.AccountCode.length)
//       const parentChildren = findChildren(account.AccountCode, account.AccountCode.length);
//       if (parentChildren.length > 0) {
//         parent.children = parentChildren;
//       }

//       treeData.push(parent);
//     }
//   });

//   return treeData;
// }


// const treeData = createTreeData(accountCodes);
// console.log(...treeData);
// const renderTree = (nodes) => (
//   <TreeItem key={nodes.id} nodeId={nodes.id} title={nodes.label} label={nodes.label}  onClick={(e)=>{
//     console.log(e.target.outerText)
//     // setTimeout(()=>{
//       console.log(e.target.outerText)
//       // setOuterText(e.target.outerText)
//     // },1500)
//   }}>
//     {Array.isArray(nodes.children)
//       ? nodes.children.map((node) => renderTree(node))
//       : null}
//   </TreeItem>
// );
// const [expanded, setExpanded] = useState([]);
// const handleToggle = (event, nodeIds) => {
//   console.log(event.target.tagName)
//   if (event.target.tagName === 'svg' || event.target.tagName === 'path') {
//     // If the user clicked on the text, prevent expansion or collapse
//     // return event.preventDefault();
//     setExpanded(nodeIds);
//   }
// };
//   return (
//     <div id='chart-of-account'>
//        <Grid item xs={12}>
//           <div className='heading'>
//             <h3 style={{ textAlign:'center', width: '100%' , padding: '0px'}}>Chart Of Account</h3>
//           </div>
          
//         </Grid>
//     <div className='chart-of-account-wrapper'>
//       <div className='tree-view-chart-of-accoutn'>
//     <TreeView
//       aria-label="customized"
//       // defaultExpanded={['1']}
//       expanded={expanded}
//       defaultCollapseIcon={<MinusSquare />}
//       defaultExpandIcon={<PlusSquare />}
//       defaultEndIcon={<CloseSquare />}
//       sx={{ height: 360, flexGrow: 1, minWidth: '30%', overflowY: 'auto', }}
//       onNodeToggle={handleToggle}
//       onNodeFocus={
//         (e)=>{
//         console.log(e)
//         let me  = e.target.title;
//         console.log(me)
//         if(me != undefined || me != ''){

//           setOuterText(me)
//         }

//       }
//     }     
//       // onClick={(e)=>{
//       //   console.log(e)
//       // }}                      
//     >

// {                 
//   treeData.map((res,key)=>{

//   return  renderTree(res)                        
//   })                                                                                                                                                                                                                                                                                                          

// }
//     </TreeView>
//     </div>
//         <ChartOfAccountAdd TreeViewValue={outerText}  callTreeView={getAccessoryTransfer} dataList={accountCodes}  />
    
    
//     </div>
//     </div>
//   );
// }