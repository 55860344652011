import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie';

const ThemeInput =  createSlice({
    name: 'theme',
    initialState:{
        Theme: {
            bgColor:Cookies.get('bgColor')  || '#009f60',
            txColor:Cookies.get('txColor') || '#000',
            ThemeNo:Cookies.get('ThemeNo'),
            modeColor:Cookies.get('modeColor'),
            condition:Cookies.get('condition') || true
        }
        },
    reducers:{ 
        ThemeUpdate(state , action){
            state.Theme = action.payload

            console.log('action' ,action)
            console.log('state' ,state)
        },
    }
 
})
export const { ThemeUpdate } = ThemeInput.actions
export default ThemeInput.reducer